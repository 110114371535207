import { useEffect, useState } from 'react';

export const useProgressInfo = (progressValue: number, lastProgressValue: number) => {
  const [progressDifference, setProgressDifference] = useState<number>(0);
  const [historyStatusValue, setHistoryStatusValue] = useState<string | null>(null);

  useEffect(() => {
    const differenceResult = progressValue - lastProgressValue;

    setProgressDifference(Math.abs(differenceResult));
    if (differenceResult > 0) {
      setHistoryStatusValue('increase');
    } else if (differenceResult < 0) {
      setHistoryStatusValue('decrease');
    } else {
      setHistoryStatusValue('neutral');
    }
  }, [progressValue, lastProgressValue]);

  return { progressDifference, historyStatusValue };
};
