import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    formControlClass: {
      width: '100%',
    },
    optionalClass: {
      color: theme.palette.text.secondary,
    },
    labelClass: {
      display: 'flex',
      minHeight: '20px',
      alignItems: 'center',
    },
    errorClass: {
      display: 'flex',
      animation: '$slideError .5s ease-out forwards',
      overflowY: 'auto',
      '& :first-child': {
        marginRight: '4px',
        marginTop: '3px',
      },
    },
    calendarIcon: {
      position: 'absolute',
      right: 8,
      top: 8,
      pointerEvents: 'none',
    },
    '@keyframes slideError': {
      from: {
        maxHeight: 0,
      },
      to: {
        maxHeight: '80px',
      },
    },
  })
);
