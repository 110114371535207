import { createStyles, makeStyles } from '@material-ui/core';
import { styled } from 'themes';

export const Container = styled('div', {
  zIndex: 40,
});

export const InputContainer = styled('div', {
  position: 'relative',
});

export default makeStyles(() =>
  createStyles({
    calendarInput: {
      height: 40,
      fontWeight: 500,
    },
    calendar: {
      background: '#fff',
      zIndex: 100,
      border: '1px solid #ccc',
      borderRadius: 4,

      '& .rdp-month > div': {
        padding: '10px 10px 0 10px',
      },
    },
    calendarIcon: {
      position: 'absolute',
      right: 8,
      top: 8,
      cursor: 'pointer',
      color: '#000',
    },
  })
);
