import { PageName, SessionEndReason, SpecificApp } from 'analytics';
import { SegmentEvents } from 'consts/segment';
import { useEffect, useRef } from 'react';
import { useSegmentEvent } from './useSegmentAnalytics';

const useHandleNavigationEvents = () => {
  const sessionStartTimeRef = useRef<number>(Date.now());
  const event = useSegmentEvent();

  type EventReason = {
    type: 'internal_navigation' | 'outside_app_navigation' | 'internal_navigation_same_page' | null;
    name: string | null;
    conditionMatch: boolean;
    pageName: PageName;
  };

  const sessionEndedReasons = {
    bees_one_usage_ended: 'bees_one_usage_ended',
    bees_one_home_access: 'bees_one_home_access',
    user_profile_access: 'user_profile_access',
    sync_app_changing: 'sync_app_changing',
    sync_home_page_access: 'sync_home_page_access',
    user_logoff: 'user_logoff',
  };

  const getPageName = (oldUrl: URL) => {
    if (oldUrl.pathname.includes('/monitoring') && oldUrl.pathname !== '/ingestion/monitoring') {
      return PageName.BeesSyncMonitoringDataUpdateDetails;
    }

    return PageName.BeesSyncMonitoringMainList;
  };

  const getEventPageProps = (url: string) => {
    const params = sessionStorage.getItem(`eventPageProps:${url}`);

    return params ? JSON.parse(params) : {};
  };

  const getEventReason = (customEvent: CustomEvent): EventReason => {
    const {
      newUrl: newUrlString,
      oldUrl: oldUrlString,
      newAppStatuses,
      appsByNewStatus,
    } = customEvent.detail;

    const newUrl = new URL(newUrlString.split('?')[0]);
    const oldUrl = new URL(oldUrlString.split('?')[0]);

    const pageName = getPageName(oldUrl);

    const isInternalNavigation = newUrl.toString().includes('/ingestion');
    const isNavigationDiffPage = !newUrl.pathname.includes(oldUrl.pathname);
    const isNavigationInsideMonitoring =
      newUrl.pathname.includes('/monitoring') && oldUrl.pathname.includes('/monitoring');
    const isNavigationFromSamePage = newUrl.pathname === oldUrl.pathname;
    const isMonitoringPage = oldUrl.pathname.includes('/monitoring');
    const outsideAppNavigation =
      newAppStatuses['@admin-portal/data-ingestion-beta-mfe'] === 'NOT_MOUNTED';
    const isCoreSpaNavigation = appsByNewStatus.MOUNTED?.length === 0;
    const isNavigationToProfileMfe = appsByNewStatus.MOUNTED?.includes(
      '@admin-portal/user-profile-beta-mfe'
    );

    if (outsideAppNavigation && isCoreSpaNavigation) {
      return {
        type: 'outside_app_navigation',
        name: sessionEndedReasons.bees_one_home_access,
        conditionMatch: true,
        pageName,
      };
    }

    if (isMonitoringPage) {
      if (isInternalNavigation && newUrl.pathname === '/ingestion') {
        return {
          type: 'internal_navigation',
          name: sessionEndedReasons.sync_home_page_access,
          conditionMatch: true,
          pageName,
        };
      }

      if (isNavigationToProfileMfe) {
        return {
          type: 'outside_app_navigation',
          name: sessionEndedReasons.user_profile_access,
          conditionMatch: true,
          pageName,
        };
      }

      if (isNavigationInsideMonitoring && !isNavigationFromSamePage) {
        return {
          type: 'internal_navigation_same_page',
          name: null,
          conditionMatch: true,
          pageName,
        };
      }

      if (isNavigationDiffPage) {
        return {
          type: 'internal_navigation',
          name: sessionEndedReasons.sync_app_changing,
          conditionMatch: true,
          pageName,
        };
      }
    }

    return {
      type: null,
      name: null,
      conditionMatch: false,
      pageName,
    };
  };

  const handleSingleSpaNavigate = (e: Event) => {
    const customEvent = e as CustomEvent;
    const eventReason = getEventReason(customEvent);
    const pageParams = getEventPageProps(customEvent.detail.oldUrl);

    if (eventReason.conditionMatch) {
      const timeSpentOnPage = Math.floor((Date.now() - sessionStartTimeRef.current) / 1000);

      if (eventReason.type === 'outside_app_navigation') {
        window.removeEventListener('single-spa:routing-event', handleSingleSpaNavigate);
      }

      if (eventReason.type === 'internal_navigation_same_page') {
        event(SegmentEvents.PAGE_VIEWED, {
          time_spent_on_page: timeSpentOnPage.toString(),
          page_name: eventReason.pageName,
          page_url: customEvent.detail.oldUrl,
          specific_app: SpecificApp.Monitoring,
          ...pageParams,
        });
      }

      if (
        eventReason.type === 'outside_app_navigation' ||
        eventReason.type === 'internal_navigation'
      ) {
        event(SegmentEvents.SESSION_ENDED, {
          session_end_reason: eventReason.name as SessionEndReason,
          specific_app: SpecificApp.Monitoring,
          page_url: customEvent.detail.oldUrl,
          page_name: eventReason.pageName,
          ...pageParams,
        });
      }
    }

    sessionStartTimeRef.current = Date.now();
  };

  const handleBeforeUnload = (e: any) => {
    const isLogout = localStorage.getItem('authHeader') === null;
    const eventReason = isLogout
      ? sessionEndedReasons.user_logoff
      : sessionEndedReasons.bees_one_usage_ended;
    const timeSpentOnPage = Math.floor((Date.now() - sessionStartTimeRef.current) / 1000);
    const pageProps = getEventPageProps(e.target.location.href);

    event(SegmentEvents.SESSION_ENDED, {
      session_end_reason: eventReason as SessionEndReason,
      specific_app: SpecificApp.Monitoring,
      ...pageProps,
    });

    event(SegmentEvents.PAGE_VIEWED, {
      time_spent_on_page: timeSpentOnPage.toString(),
      specific_app: SpecificApp.Monitoring,
      ...pageProps,
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('single-spa:routing-event', handleSingleSpaNavigate);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return null;
};

export default useHandleNavigationEvents;
