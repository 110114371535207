/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface DataManagementAccessErrorPageFinished {
	/**
	 * Action that was selected
	 */
	action: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Entity that was selected
	 */
	entity: string | null
	/**
	 * Parent Trace ID of the request
	 */
	parent_trace_id: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementAccessErrorPageStarted {
	/**
	 * Action that was selected
	 */
	action: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Entity that was selected
	 */
	entity: string | null
	/**
	 * Reason of the error
	 */
	error_reason: string | null
	/**
	 * Size of the file that are being ingested
	 */
	file_size: number | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementAccessSuccessPageFinished {
	/**
	 * Action that was selected
	 */
	action: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Entity that was selected
	 */
	entity: string | null
	/**
	 * Parent Trace ID of the request
	 */
	parent_trace_id: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementAccessSuccessPageStarted {
	/**
	 * Action that was selected
	 */
	action: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Entity that was selected
	 */
	entity: string | null
	/**
	 * Size of the file that are being ingested
	 */
	file_size: number | null
	/**
	 * Parent Trace ID of the request
	 */
	parent_trace_id: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementAccessToStoplightDocumentation {
	/**
	 * Action that was selected
	 */
	action: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Entity that was selected
	 */
	entity: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementAccessToTemplateFile {
	/**
	 * Action that was selected
	 */
	action: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Entity that was selected
	 */
	entity: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementDownloadDataTabFinished {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementDownloadDataTabStarted {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementFinished {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementHistoryTabFinished {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementHistoryTabStarted {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementSelectedAction {
	/**
	 * Action that was selected
	 */
	action: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Entity that was selected
	 */
	entity: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementSelectedEntity {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Entity that was selected
	 */
	entity: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementSendFileStarted {
	/**
	 * Action that was selected
	 */
	action: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Entity that was selected
	 */
	entity: string | null
	/**
	 * Size of the file that are being ingested
	 */
	file_size: number | null
	/**
	 * Mode that was selected
	 */
	mode: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementStarted {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementToTemplateFile {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementUploadDataTabCopiedTraceId {
	/**
	 * Action that was selected
	 */
	action: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Entity that was selected
	 */
	entity: string | null
	/**
	 * Mode that was selected
	 */
	mode: string | null
	/**
	 * If the upload was successful.
	 */
	success: boolean | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementUploadDataTabFinished {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementUploadDataTabSelectedUploadMode {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Mode that was selected
	 */
	mode: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface DataManagementUploadDataTabStarted {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface ElementClicked {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name: AppDisplayName
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id: AppId
	/**
	 * The number of results clicked on the card status
	 */
	clicked_card_number_of_results?: number | null
	/**
	 * The array of row clicked in monitoring. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	clicked_line_details?: any[] | null
	/**
	 * The click over tooltip on the card status
	 */
	clicked_over_tooltip?: boolean | null
	/**
	 * The name of the clicked step. Must be informed when the user clicked over enabled OR disabled (completed) step cards.
	 */
	clicked_step: string | null
	/**
	 * The situation of the clicked step. Must be informed when the user clicked over enabled OR disabled (completed) step cards
	 */
	clicked_step_situation: string | null
	/**
	 * Content generic
	 */
	content?: string | null
	/**
	 * The element text on the front end with localization
	 */
	element_label: string | null
	/**
	 * The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name.
	 */
	element_location_name?: string | null
	/**
	 * The category of the object that anchors the button
	 */
	element_location_type: ElementLocationType
	/**
	 * The element name on the back end
	 */
	element_name: string | null
	/**
	 * The element type
	 */
	element_type: ElementType
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event: boolean | null
	/**
	 * The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The Page title on the front end with localization
	 */
	page_label: string | null
	/**
	 * The name of the page where the event was triggered
	 */
	page_name: PageName
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed
	 */
	user_permission_group: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * The view level the action is being executed
	 */
	view_level: string | null
	/**
	 * It is the situtation of the step that the user was seeing when the event was triggered (related to the left side steps menu).
	 */
	viewed_step_situation_when_triggered: string | null
	/**
	 * It is the situtation of the step that the user was seeing when the event was triggered (related to the left side steps menu).
	 */
	viewed_step_when_triggered: string | null
}
export interface FilterApplied {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name: AppDisplayName
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id: AppId
	/**
	 * The number of results from the filter.
	 */
	completed_number_of_results?: number | null
	/**
	 * The number of filter results with the status completed with failures.
	 */
	completed_with_failures_number_of_results?: number | null
	/**
	 * The number of results from the filter with the status failed.
	 */
	failed_number_of_results?: number | null
	/**
	 * Is filtering action delete
	 */
	filter_action_delete?: boolean
	/**
	 * Is filtering action get
	 */
	filter_action_get?: boolean
	/**
	 * Is filtering action patch
	 */
	filter_action_patch?: boolean
	/**
	 * Is filtering action post
	 */
	filter_action_post?: boolean
	/**
	 * Is filtering action put
	 */
	filter_action_put?: boolean
	/**
	 * Filter by end date
	 */
	filter_end_date?: string | null
	/**
	 * Filter by end time
	 */
	filter_end_time?: string | null
	/**
	 * List of entities that are being filtered. Attention Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	filter_entities?: any[] | null
	/**
	 * List of parent trace IDs that are being filtered
	 */
	filter_parent_trace_ids?: any[] | null
	/**
	 * Similar ids filter is applied
	 */
	filter_similar_ids_matches?: boolean | null
	/**
	 * Filter by start date
	 */
	filter_start_date?: string | null
	/**
	 * Filter by start time
	 */
	filter_start_time?: string | null
	/**
	 * Status completed filter is applied.
	 */
	filter_status_completed?: boolean | null
	/**
	 * Status completed with failures filter is applied.
	 */
	filter_status_completed_with_failures?: boolean | null
	/**
	 * Status failed filter is applied.
	 */
	filter_status_failed?: boolean | null
	/**
	 * Status in progress filter is applied.
	 */
	filter_status_in_progress?: boolean | null
	/**
	 * Versions being filtered
	 */
	filter_versions?: any[] | null
	/**
	 * The period that was filtered.
	 */
	filtered_period?: string | null
	/**
	 * The number of results from the filter with the status in progress.
	 */
	in_progress_number_of_results?: number | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event: boolean | null
	/**
	 * The time it took to load the filter results
	 */
	list_loading_time?: number | null
	/**
	 * The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * Count of total filtered registers
	 */
	number_of_results?: number | null
	/**
	 * The Page title on the front end with localization
	 */
	page_label: string | null
	/**
	 * The name of the page where the event was triggered
	 */
	page_name: PageName
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url: string | null
	/**
	 * The name of the feature trigging the event
	 */
	specific_app?: SpecificApp
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed
	 */
	user_permission_group: string | null
	/**
	 * The previous filter view
	 */
	user_previous_filter_view?: UserPreviousFilterView
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * The view level the action is being executed
	 */
	view_level: string | null
	/**
	 * It is the situtation of the step that the user was seeing when the event was triggered (related to the left side steps menu).
	 */
	viewed_step_situation_when_triggered: string | null
	/**
	 * It is the situtation of the step that the user was seeing when the event was triggered (related to the left side steps menu).
	 */
	viewed_step_when_triggered: string | null
}
export interface MonitoringDetailedInfo {
	/**
	 * List of parentTraceId that user consulted
	 */
	clicked_parent_trace_id: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * List status
	 */
	status?: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface MonitoringFilterOptions {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * A list with filter and value selected by the user
	 */
	filter_options: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface MonitoringFinished {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface MonitoringHeaderStatusFilter {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * List status
	 */
	status?: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface MonitoringPayload {
	/**
	 * List of parentTraceId that user consulted
	 */
	clicked_parent_trace_id: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * List status
	 */
	status?: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface MonitoringPayloadFailed {
	/**
	 * List of parentTraceId that user consulted
	 */
	clicked_parent_trace_id: string | null
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * List status
	 */
	status?: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface MonitoringSearchFilterByParentTraceId {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Parent Trace ID of the request
	 */
	parent_trace_id: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface MonitoringStarted {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface PageViewed {
	/**
	 * An array identifying all available steps for that specific ingestion
	 */
	all_available_ingestion_steps?: any[] | null
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name: AppDisplayName
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id: AppId
	/**
	 * Error message chunk
	 */
	data_chunk_error_message?: string | null
	/**
	 * Payload do chunk
	 */
	data_chunk_payload_present?: boolean | null
	/**
	 * Chunk step was selected
	 */
	data_chunk_source_system?: string | null
	/**
	 * Chunk step where is it located
	 */
	data_chunk_step?: string | null
	/**
	 * Chunk trace Id
	 */
	data_chunk_trace_id?: string | null
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event: boolean | null
	/**
	 * The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The Page title on the front end with localization
	 */
	page_label: string | null
	/**
	 * The name of the page where the event was triggered
	 */
	page_name: PageName
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url: string | null
	/**
	 * Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * The name of the feature trigging the event
	 */
	specific_app?: SpecificApp
	/**
	 * List status
	 */
	status?: string | null
	/**
	 * The time spent on the page
	 */
	time_spent_on_page?: string | null
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed
	 */
	user_permission_group: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * The viewed Ingestion and/or chunk's version.
	 */
	version?: string | null
	/**
	 * The view level the action is being executed
	 */
	view_level: string | null
}
export interface PayloadValidatorFinished {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface PayloadValidatorStarted {
	/**
	 * Country code that was selected (or that is reloaded)
	 */
	country: string | null
	/**
	 * Vendor that was selected (or that is reloaded)
	 */
	vendor: string | null
	/**
	 * Description of vendor that was selected (or that is reloaded)
	 */
	vendor_name: string | null
}
export interface SessionEnded {
	/**
	 * The current name of the app where the event was triggered
	 */
	app_display_name: AppDisplayName
	/**
	 * The unique id of the app where the event was triggered
	 */
	app_id: AppId
	/**
	 * A boolean that identifies rather an occurrence of this event configures as a core action
	 */
	is_core_event: boolean | null
	/**
	 * The country of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_country: string | null
	/**
	 * The name of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_display_name: string | null
	/**
	 * The id of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_id: string | null
	/**
	 * The Service model of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_service_model: string | null
	/**
	 * The tier of the manufacturer that is executing the action or having it executed for them.
	 */
	manufacturer_tier_name: string | null
	/**
	 * The Page title on the front end with localization
	 */
	page_label: string | null
	/**
	 * The name of the page where the event was triggered
	 */
	page_name: PageName
	/**
	 * The URL of the page where the event was triggered
	 */
	page_url: string | null
	/**
	 * The reason the session ended.
	 */
	session_end_reason?: SessionEndReason
	/**
	 * The name of the feature trigging the event
	 */
	specific_app?: SpecificApp
	/**
	 * The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
	 */
	user_country: string | null
	/**
	 * The email address of the user that performed or caused this action, if there is one.
	 */
	user_email: string | null
	/**
	 * The user permission group that relates to the application and the feature where the action is being executed
	 */
	user_permission_group: string | null
	/**
	 * The country of the vendor that is executing the action or having it executed for them.
	 */
	vendor_country: string | null
	/**
	 * The name of the vendor that is executing the action or having it executed for them.
	 */
	vendor_display_name: string | null
	/**
	 * The id of the vendor that is executing the action or having it executed for them.
	 */
	vendor_id: string | null
	/**
	 * The Service model of the vendor that is executing the action or having it executed for them.
	 */
	vendor_service_model: string | null
	/**
	 * The tier of the vendor that is executing the action or having it executed for them.
	 */
	vendor_tier_name: string | null
	/**
	 * The view level the action is being executed
	 */
	view_level: string | null
}

export enum AppDisplayName {
	SyncIngestion = 'SYNC INGESTION',
	Null = 'null',
}
export enum AppId {
	Ingestion = 'INGESTION',
	Null = 'null',
}
export enum ElementLocationType {
	Page = 'PAGE',
	Modal = 'MODAL',
	Menu = 'MENU',
	Chip = 'CHIP',
	Null = 'null',
}
export enum ElementType {
	Button = 'BUTTON',
	Tab = 'TAB',
	Card = 'CARD',
	MenuOption = 'MENU_OPTION',
	Breadcrumbs = 'BREADCRUMBS',
	Link = 'LINK',
	Toggle = 'TOGGLE',
	Hotspot = 'HOTSPOT',
	ListItem = 'LIST_ITEM',
	Other = 'OTHER',
}
export enum PageName {
	BeesSyncMonitoringMainList = 'BEES_SYNC_MONITORING_MAIN_LIST',
	BeesSyncMonitoringDataUpdateDetails = 'BEES_SYNC_MONITORING_DATA_UPDATE_DETAILS',
	BeesSyncMonitoringDataChunkDetails = 'BEES_SYNC_MONITORING_DATA_CHUNK_DETAILS',
	Null = 'null',
}
export enum SpecificApp {
	DataManagement = 'DATA_MANAGEMENT',
	Monitoring = 'MONITORING',
	PayloadValidator = 'PAYLOAD_VALIDATOR',
	Null = 'null',
}
export enum UserPreviousFilterView {
	FilterNotUsed = 'FILTER_NOT_USED',
	EmptyListAfterFiltering = 'EMPTY_LIST_AFTER_FILTERING',
	RecordsFoundAfterFiltering = 'RECORDS_FOUND_AFTER_FILTERING',
}
export enum SessionEndReason {
	BeesOneHomeAccess = 'bees_one_home_access',
	BeesOneAppChanging = 'bees_one_app_changing',
	SyncHomePageAccess = 'sync_home_page_access',
	SyncAppChanging = 'sync_app_changing',
	BeesOneUsageEnded = 'bees_one_usage_ended',
	UserProfileAccess = 'user_profile_access',
	UserLogoff = 'user_logoff',
	Null = 'null',
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * @typedef DataManagementAccessErrorPageFinished
 * @property {string | null} action - Action that was selected
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} entity - Entity that was selected
 * @property {string | null} parent_trace_id - Parent Trace ID of the request
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementAccessErrorPageStarted
 * @property {string | null} action - Action that was selected
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} entity - Entity that was selected
 * @property {string | null} error_reason - Reason of the error
 * @property {number | null} file_size - Size of the file that are being ingested
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementAccessSuccessPageFinished
 * @property {string | null} action - Action that was selected
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} entity - Entity that was selected
 * @property {string | null} parent_trace_id - Parent Trace ID of the request
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementAccessSuccessPageStarted
 * @property {string | null} action - Action that was selected
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} entity - Entity that was selected
 * @property {number | null} file_size - Size of the file that are being ingested
 * @property {string | null} parent_trace_id - Parent Trace ID of the request
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementAccessToStoplightDocumentation
 * @property {string | null} action - Action that was selected
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} entity - Entity that was selected
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementAccessToTemplateFile
 * @property {string | null} action - Action that was selected
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} entity - Entity that was selected
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementDownloadDataTabFinished
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementDownloadDataTabStarted
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementFinished
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementHistoryTabFinished
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementHistoryTabStarted
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementSelectedAction
 * @property {string | null} action - Action that was selected
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} entity - Entity that was selected
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementSelectedEntity
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} entity - Entity that was selected
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementSendFileStarted
 * @property {string | null} action - Action that was selected
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} entity - Entity that was selected
 * @property {number | null} file_size - Size of the file that are being ingested
 * @property {string | null} mode - Mode that was selected
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementStarted
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementToTemplateFile
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementUploadDataTabCopiedTraceId
 * @property {string | null} action - Action that was selected
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} entity - Entity that was selected
 * @property {string | null} mode - Mode that was selected
 * @property {boolean | null} success - If the upload was successful.
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementUploadDataTabFinished
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementUploadDataTabSelectedUploadMode
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} mode - Mode that was selected
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef DataManagementUploadDataTabStarted
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef ElementClicked
 * @property {AppDisplayName} app_display_name - The current name of the app where the event was triggered
 * @property {AppId} app_id - The unique id of the app where the event was triggered
 * @property {number | null} [clicked_card_number_of_results] - The number of results clicked on the card status
 * @property {any[] | null} [clicked_line_details] - The array of row clicked in monitoring. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {boolean | null} [clicked_over_tooltip] - The click over tooltip on the card status
 * @property {string | null} clicked_step - The name of the clicked step. Must be informed when the user clicked over enabled OR disabled (completed) step cards.
 * @property {string | null} clicked_step_situation - The situation of the clicked step. Must be informed when the user clicked over enabled OR disabled (completed) step cards
 * @property {string | null} [content] - Content generic
 * @property {string | null} element_label - The element text on the front end with localization
 * @property {string | null} [element_location_name] - The name of the object that anchors the element on the back end. If it is a Page it should be the same as page_name.
 * @property {ElementLocationType} element_location_type - The category of the object that anchors the button
 * @property {string | null} element_name - The element name on the back end
 * @property {ElementType} element_type - The element type
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} manufacturer_country - The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} page_label - The Page title on the front end with localization
 * @property {PageName} page_name - The name of the page where the event was triggered
 * @property {string | null} page_url - The URL of the page where the event was triggered
 * @property {string | null} user_country - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} user_email - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed
 * @property {string | null} vendor_country - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_id - The id of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_service_model - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - The view level the action is being executed
 * @property {string | null} viewed_step_situation_when_triggered - It is the situtation of the step that the user was seeing when the event was triggered (related to the left side steps menu).
 * @property {string | null} viewed_step_when_triggered - It is the situtation of the step that the user was seeing when the event was triggered (related to the left side steps menu).
 */
/**
 * @typedef FilterApplied
 * @property {AppDisplayName} app_display_name - The current name of the app where the event was triggered
 * @property {AppId} app_id - The unique id of the app where the event was triggered
 * @property {number | null} [completed_number_of_results] - The number of results from the filter.
 * @property {number | null} [completed_with_failures_number_of_results] - The number of filter results with the status completed with failures.
 * @property {number | null} [failed_number_of_results] - The number of results from the filter with the status failed.
 * @property {boolean} [filter_action_delete] - Is filtering action delete
 * @property {boolean} [filter_action_get] - Is filtering action get
 * @property {boolean} [filter_action_patch] - Is filtering action patch
 * @property {boolean} [filter_action_post] - Is filtering action post
 * @property {boolean} [filter_action_put] - Is filtering action put
 * @property {string | null} [filter_end_date] - Filter by end date
 * @property {string | null} [filter_end_time] - Filter by end time
 * @property {any[] | null} [filter_entities] - List of entities that are being filtered. Attention Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {any[] | null} [filter_parent_trace_ids] - List of parent trace IDs that are being filtered
 * @property {boolean | null} [filter_similar_ids_matches] - Similar ids filter is applied
 * @property {string | null} [filter_start_date] - Filter by start date
 * @property {string | null} [filter_start_time] - Filter by start time
 * @property {boolean | null} [filter_status_completed] - Status completed filter is applied.
 * @property {boolean | null} [filter_status_completed_with_failures] - Status completed with failures filter is applied.
 * @property {boolean | null} [filter_status_failed] - Status failed filter is applied.
 * @property {boolean | null} [filter_status_in_progress] - Status in progress filter is applied.
 * @property {any[] | null} [filter_versions] - Versions being filtered
 * @property {string | null} [filtered_period] - The period that was filtered.
 * @property {number | null} [in_progress_number_of_results] - The number of results from the filter with the status in progress.
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {number | null} [list_loading_time] - The time it took to load the filter results
 * @property {string | null} manufacturer_country - The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {number | null} [number_of_results] - Count of total filtered registers
 * @property {string | null} page_label - The Page title on the front end with localization
 * @property {PageName} page_name - The name of the page where the event was triggered
 * @property {string | null} page_url - The URL of the page where the event was triggered
 * @property {SpecificApp} [specific_app] - The name of the feature trigging the event
 * @property {string | null} user_country - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} user_email - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed
 * @property {UserPreviousFilterView} [user_previous_filter_view] - The previous filter view
 * @property {string | null} vendor_country - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_id - The id of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_service_model - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - The view level the action is being executed
 * @property {string | null} viewed_step_situation_when_triggered - It is the situtation of the step that the user was seeing when the event was triggered (related to the left side steps menu).
 * @property {string | null} viewed_step_when_triggered - It is the situtation of the step that the user was seeing when the event was triggered (related to the left side steps menu).
 */
/**
 * @typedef MonitoringDetailedInfo
 * @property {string | null} clicked_parent_trace_id - List of parentTraceId that user consulted
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} [status] - List status
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef MonitoringFilterOptions
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} filter_options - A list with filter and value selected by the user
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef MonitoringFinished
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef MonitoringHeaderStatusFilter
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} [status] - List status
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef MonitoringPayload
 * @property {string | null} clicked_parent_trace_id - List of parentTraceId that user consulted
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} [status] - List status
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef MonitoringPayloadFailed
 * @property {string | null} clicked_parent_trace_id - List of parentTraceId that user consulted
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} [status] - List status
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef MonitoringSearchFilterByParentTraceId
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} parent_trace_id - Parent Trace ID of the request
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef MonitoringStarted
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef PageViewed
 * @property {any[] | null} [all_available_ingestion_steps] - An array identifying all available steps for that specific ingestion
 * @property {AppDisplayName} app_display_name - The current name of the app where the event was triggered
 * @property {AppId} app_id - The unique id of the app where the event was triggered
 * @property {string | null} [data_chunk_error_message] - Error message chunk
 * @property {boolean | null} [data_chunk_payload_present] - Payload do chunk
 * @property {string | null} [data_chunk_source_system] - Chunk step was selected
 * @property {string | null} [data_chunk_step] - Chunk step where is it located
 * @property {string | null} [data_chunk_trace_id] - Chunk trace Id
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} manufacturer_country - The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} page_label - The Page title on the front end with localization
 * @property {PageName} page_name - The name of the page where the event was triggered
 * @property {string | null} page_url - The URL of the page where the event was triggered
 * @property {string | null} referrer - Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {SpecificApp} [specific_app] - The name of the feature trigging the event
 * @property {string | null} [status] - List status
 * @property {string | null} [time_spent_on_page] - The time spent on the page
 * @property {string | null} user_country - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} user_email - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed
 * @property {string | null} vendor_country - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_id - The id of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_service_model - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} [version] - The viewed Ingestion and/or chunk's version.
 * @property {string | null} view_level - The view level the action is being executed
 */
/**
 * @typedef PayloadValidatorFinished
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef PayloadValidatorStarted
 * @property {string | null} country - Country code that was selected (or that is reloaded)
 * @property {string | null} vendor - Vendor that was selected (or that is reloaded)
 * @property {string | null} vendor_name - Description of vendor that was selected (or that is reloaded)
 */
/**
 * @typedef SessionEnded
 * @property {AppDisplayName} app_display_name - The current name of the app where the event was triggered
 * @property {AppId} app_id - The unique id of the app where the event was triggered
 * @property {boolean | null} is_core_event - A boolean that identifies rather an occurrence of this event configures as a core action
 * @property {string | null} manufacturer_country - The country of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_display_name - The name of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_id - The id of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_service_model - The Service model of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} manufacturer_tier_name - The tier of the manufacturer that is executing the action or having it executed for them.
 * @property {string | null} page_label - The Page title on the front end with localization
 * @property {PageName} page_name - The name of the page where the event was triggered
 * @property {string | null} page_url - The URL of the page where the event was triggered
 * @property {SessionEndReason} [session_end_reason] - The reason the session ended.
 * @property {SpecificApp} [specific_app] - The name of the feature trigging the event
 * @property {string | null} user_country - The country associated with the user that performed or caused this action (!= Supported Countries  & = Country)
 * @property {string | null} user_email - The email address of the user that performed or caused this action, if there is one.
 * @property {string | null} user_permission_group - The user permission group that relates to the application and the feature where the action is being executed
 * @property {string | null} vendor_country - The country of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_display_name - The name of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_id - The id of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_service_model - The Service model of the vendor that is executing the action or having it executed for them.
 * @property {string | null} vendor_tier_name - The tier of the vendor that is executing the action or having it executed for them.
 * @property {string | null} view_level - The view level the action is being executed
 */

/**
 * When the user stops the utilization of the Upload Data of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL or click on button "Start Over"
 *
 * @param {DataManagementAccessErrorPageFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementAccessErrorPageFinished(
	props: DataManagementAccessErrorPageFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Access Error Page Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the upload has completed with failure
 *
 * @param {DataManagementAccessErrorPageStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementAccessErrorPageStarted(
	props: DataManagementAccessErrorPageStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Access Error Page Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user stops the utilization of the Upload Data of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL or click on button "Upload a New File"
 *
 * @param {DataManagementAccessSuccessPageFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementAccessSuccessPageFinished(
	props: DataManagementAccessSuccessPageFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Access Success Page Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the upload has completed with success
 *
 * @param {DataManagementAccessSuccessPageStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementAccessSuccessPageStarted(
	props: DataManagementAccessSuccessPageStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Access Success Page Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user click on "Click here to see the instructions", to access the documentation in Stoplight
 *
 * @param {DataManagementAccessToStoplightDocumentation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementAccessToStoplightDocumentation(
	props: DataManagementAccessToStoplightDocumentation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Access To Stoplight Documentation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user click on "Click here to download the template
 *
 * @param {DataManagementAccessToTemplateFile} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementAccessToTemplateFile(
	props: DataManagementAccessToTemplateFile,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Access To Template File',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user stops the utilization of the Download Data of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
 *
 * @param {DataManagementDownloadDataTabFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementDownloadDataTabFinished(
	props: DataManagementDownloadDataTabFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Download Data Tab Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Data Management option or in the Data Management button in the Side Menu and select the Download Data Tab.
 *
 * @param {DataManagementDownloadDataTabStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementDownloadDataTabStarted(
	props: DataManagementDownloadDataTabStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Download Data Tab Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user stops the utilization of the Upload Data of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
 *
 * @param {DataManagementFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementFinished(
	props: DataManagementFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user stops the utilization of the History of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
 *
 * @param {DataManagementHistoryTabFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementHistoryTabFinished(
	props: DataManagementHistoryTabFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management History Tab Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Data Management option or in the Data Management button in the Side Menu and select the History Tab.
 *
 * @param {DataManagementHistoryTabStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementHistoryTabStarted(
	props: DataManagementHistoryTabStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management History Tab Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user select an action to be ingested
 *
 * @param {DataManagementSelectedAction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementSelectedAction(
	props: DataManagementSelectedAction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Selected Action',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user select an entity to be ingested
 *
 * @param {DataManagementSelectedEntity} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementSelectedEntity(
	props: DataManagementSelectedEntity,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Selected Entity',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user click on "Send File"
 *
 * @param {DataManagementSendFileStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementSendFileStarted(
	props: DataManagementSendFileStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Send File Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Data Management option or in the Data Management button in the Side Menu and select the Upload Data Tab.
 *
 * @param {DataManagementStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementStarted(
	props: DataManagementStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user click on "Click here to download the template
 *
 * @param {DataManagementToTemplateFile} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementToTemplateFile(
	props: DataManagementToTemplateFile,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management To Template File',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on "copy" button right next to the alert with the request trace id.
 *
 * @param {DataManagementUploadDataTabCopiedTraceId} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementUploadDataTabCopiedTraceId(
	props: DataManagementUploadDataTabCopiedTraceId,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Upload Data Tab Copied Trace Id',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user stops the utilization of the Upload Data of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
 *
 * @param {DataManagementUploadDataTabFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementUploadDataTabFinished(
	props: DataManagementUploadDataTabFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Upload Data Tab Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user select between single or multiple vendor upload.
 *
 * @param {DataManagementUploadDataTabSelectedUploadMode} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementUploadDataTabSelectedUploadMode(
	props: DataManagementUploadDataTabSelectedUploadMode,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Upload Data Tab Selected Upload Mode',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Data Management option or in the Data Management button in the Side Menu and select the Upload Data Tab.
 *
 * @param {DataManagementUploadDataTabStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dataManagementUploadDataTabStarted(
	props: DataManagementUploadDataTabStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Data Management Upload Data Tab Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event triggered when a user clicks in an element to view details or perform actions.
 *
 * @param {ElementClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function elementClicked(
	props: ElementClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Element Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event triggered when the filter is applied to the monitoring page
 *
 * @param {FilterApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filterApplied(
	props: FilterApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Filter Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on any line to validate a detailed info
 *
 * @param {MonitoringDetailedInfo} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function monitoringDetailedInfo(
	props: MonitoringDetailedInfo,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Monitoring Detailed Info',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user select any type of filter (inside of filter menu) to be consulted, on any sequence
 *
 * @param {MonitoringFilterOptions} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function monitoringFilterOptions(
	props: MonitoringFilterOptions,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Monitoring Filter Options',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user stops the utilization of the Monitoring of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
 *
 * @param {MonitoringFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function monitoringFinished(
	props: MonitoringFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Monitoring Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on any of the filters available on header status filter
 *
 * @param {MonitoringHeaderStatusFilter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function monitoringHeaderStatusFilter(
	props: MonitoringHeaderStatusFilter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Monitoring Header Status Filter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on any line to validate an payload that was concluded with failure clicking on "View Payload Details on JSON"




 *
 * @param {MonitoringPayload} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function monitoringPayload(
	props: MonitoringPayload,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Monitoring Payload',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on any line to validate a payload that has a status as failed
 *
 * @param {MonitoringPayloadFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function monitoringPayloadFailed(
	props: MonitoringPayloadFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Monitoring Payload Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user insert a parent trace id to be consulted
 *
 * @param {MonitoringSearchFilterByParentTraceId} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function monitoringSearchFilterByParentTraceId(
	props: MonitoringSearchFilterByParentTraceId,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Monitoring Search Filter By Parent Trace Id',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Monitoring option or in the Monitoring button in the Side Menu
 *
 * @param {MonitoringStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function monitoringStarted(
	props: MonitoringStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Monitoring Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event triggered when the user access the page
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user stops the utilization of the Payload Validator of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
 *
 * @param {PayloadValidatorFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function payloadValidatorFinished(
	props: PayloadValidatorFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payload Validator Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Payload Validator option or in the Payload Validator button in the Side Menu
 *
 * @param {PayloadValidatorStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function payloadValidatorStarted(
	props: PayloadValidatorStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payload Validator Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event triggered when a user ends a session.
 *
 * @param {SessionEnded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sessionEnded(
	props: SessionEnded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Session Ended',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * When the user stops the utilization of the Upload Data of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL or click on button "Start Over"
	 *
	 * @param {DataManagementAccessErrorPageFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementAccessErrorPageFinished,
	/**
	 * When the upload has completed with failure
	 *
	 * @param {DataManagementAccessErrorPageStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementAccessErrorPageStarted,
	/**
	 * When the user stops the utilization of the Upload Data of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL or click on button "Upload a New File"
	 *
	 * @param {DataManagementAccessSuccessPageFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementAccessSuccessPageFinished,
	/**
	 * When the upload has completed with success
	 *
	 * @param {DataManagementAccessSuccessPageStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementAccessSuccessPageStarted,
	/**
	 * When the user click on "Click here to see the instructions", to access the documentation in Stoplight
	 *
	 * @param {DataManagementAccessToStoplightDocumentation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementAccessToStoplightDocumentation,
	/**
	 * When the user click on "Click here to download the template
	 *
	 * @param {DataManagementAccessToTemplateFile} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementAccessToTemplateFile,
	/**
	 * When the user stops the utilization of the Download Data of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
	 *
	 * @param {DataManagementDownloadDataTabFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementDownloadDataTabFinished,
	/**
	 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Data Management option or in the Data Management button in the Side Menu and select the Download Data Tab.
	 *
	 * @param {DataManagementDownloadDataTabStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementDownloadDataTabStarted,
	/**
	 * When the user stops the utilization of the Upload Data of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
	 *
	 * @param {DataManagementFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementFinished,
	/**
	 * When the user stops the utilization of the History of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
	 *
	 * @param {DataManagementHistoryTabFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementHistoryTabFinished,
	/**
	 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Data Management option or in the Data Management button in the Side Menu and select the History Tab.
	 *
	 * @param {DataManagementHistoryTabStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementHistoryTabStarted,
	/**
	 * When the user select an action to be ingested
	 *
	 * @param {DataManagementSelectedAction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementSelectedAction,
	/**
	 * When the user select an entity to be ingested
	 *
	 * @param {DataManagementSelectedEntity} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementSelectedEntity,
	/**
	 * When the user click on "Send File"
	 *
	 * @param {DataManagementSendFileStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementSendFileStarted,
	/**
	 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Data Management option or in the Data Management button in the Side Menu and select the Upload Data Tab.
	 *
	 * @param {DataManagementStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementStarted,
	/**
	 * When the user click on "Click here to download the template
	 *
	 * @param {DataManagementToTemplateFile} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementToTemplateFile,
	/**
	 * When the user clicks on "copy" button right next to the alert with the request trace id.
	 *
	 * @param {DataManagementUploadDataTabCopiedTraceId} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementUploadDataTabCopiedTraceId,
	/**
	 * When the user stops the utilization of the Upload Data of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or by moving to another Tab (Download Data / History)  clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
	 *
	 * @param {DataManagementUploadDataTabFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementUploadDataTabFinished,
	/**
	 * When the user select between single or multiple vendor upload.
	 *
	 * @param {DataManagementUploadDataTabSelectedUploadMode} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementUploadDataTabSelectedUploadMode,
	/**
	 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Data Management option or in the Data Management button in the Side Menu and select the Upload Data Tab.
	 *
	 * @param {DataManagementUploadDataTabStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dataManagementUploadDataTabStarted,
	/**
	 * Event triggered when a user clicks in an element to view details or perform actions.
	 *
	 * @param {ElementClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	elementClicked,
	/**
	 * Event triggered when the filter is applied to the monitoring page
	 *
	 * @param {FilterApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	filterApplied,
	/**
	 * When the user clicks on any line to validate a detailed info
	 *
	 * @param {MonitoringDetailedInfo} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	monitoringDetailedInfo,
	/**
	 * When the user select any type of filter (inside of filter menu) to be consulted, on any sequence
	 *
	 * @param {MonitoringFilterOptions} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	monitoringFilterOptions,
	/**
	 * When the user stops the utilization of the Monitoring of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
	 *
	 * @param {MonitoringFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	monitoringFinished,
	/**
	 * When the user clicks on any of the filters available on header status filter
	 *
	 * @param {MonitoringHeaderStatusFilter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	monitoringHeaderStatusFilter,
	/**
	 * When the user clicks on any line to validate an payload that was concluded with failure clicking on "View Payload Details on JSON"
	
	
	
	
	 *
	 * @param {MonitoringPayload} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	monitoringPayload,
	/**
	 * When the user clicks on any line to validate a payload that has a status as failed
	 *
	 * @param {MonitoringPayloadFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	monitoringPayloadFailed,
	/**
	 * When the user insert a parent trace id to be consulted
	 *
	 * @param {MonitoringSearchFilterByParentTraceId} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	monitoringSearchFilterByParentTraceId,
	/**
	 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Monitoring option or in the Monitoring button in the Side Menu
	 *
	 * @param {MonitoringStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	monitoringStarted,
	/**
	 * Event triggered when the user access the page
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * When the user stops the utilization of the Payload Validator of BEES Sync Tool. It could be by clicking in another BEES Sync Product in the Side Menu or clicking in the HOME page button or by selecting another BEES HUB Product or closing the browser or making the Profile Logout or by changing the browser URL
	 *
	 * @param {PayloadValidatorFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	payloadValidatorFinished,
	/**
	 * When the user starts using the BEES Sync Tool inside of BEES HUB and  in the wecome page click on Payload Validator option or in the Payload Validator button in the Side Menu
	 *
	 * @param {PayloadValidatorStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	payloadValidatorStarted,
	/**
	 * Event triggered when a user ends a session.
	 *
	 * @param {SessionEnded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sessionEnded,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2LkFimvBv8Z6olVW33LjU6V2yFJ`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
