import { DataItem } from './formatReturnData.types';

export function filterObjectsByCountry(data, countryAcronym) {
  if (!data || !countryAcronym) {
    return [];
  } else {
    return data.filter(
      (obj) => obj.contract.driverWithProgressAmount[0].country.acronym === countryAcronym
    );
  }
}

export const getKpiList = (data: DataItem[], type: string) => {
  const list = [];
  if (data) {
    data.forEach((item) =>
      item?.component.map((kpiItem) => {
        if (kpiItem.content.componentType === type) {
          const brands = item.contract.driverWithProgressAmount
            .filter((driver) => driver.id === kpiItem.content.kpiId)
            .map((driver) => driver.brands)
            .join(', ');
          list.push({ ...kpiItem, brands });
        }
      })
    );
  }
  return list;
};

export function hasAcceptedReward(data) {
  if (data.length !== 0) {
    const rewardsAccepted = data.flatMap((item) =>
      Object.values(item.rewardComponent.toggle.accepted)
    );
    const hasUnAccepted = rewardsAccepted.some((reward) => reward === true);
    if (hasUnAccepted) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

const processKpiData = (data, feature) => {
  const someKpiStarted = data
    .filter((d) => d.feature === feature)
    .map((d) => d.rewardComponent.toggle.isStarted);

  const allKpisAchieved = data
    .filter((d) => d.feature === feature)
    .map((d) => d.summaryComponent.content.kpiNotAchieved);

  const showToEarnValue = data
    .filter((d) => d.feature === feature)
    .map((d) => d.rewardComponent.toggle.showToEarnValue);

  const isAnyKpiStarted = someKpiStarted.some((obj) =>
    obj ? Object.values(obj).includes(true) : false
  );

  const allKpisAchievedResult = allKpisAchieved.every((number) => number === 0);

  return {
    isAnyKpiStarted,
    allKpisAchieved: allKpisAchievedResult,
    showToEarnValue: showToEarnValue[0],
  };
};

export const formatUseGetContractByChainIdData = (contentArray, country) => {
  const consolidatedData = {
    rewardCardDataResponse: {},
    kpiCardDataResponse: {},
    growthCardDataResponse: [],
    numericCardDataResponse: [],
    percentageCardDataResponse: [],
    irsCardDataResponse: [],
    arCardDataResponse: [],
    contractInfoDataResponse: {
      contracts: [],
      startDate: null,
      endDate: null,
      contractId: null,
      contractName: null,
      contractType: null,
      driverInfoData: [],
      chainId: null,
      accountId: null,
    },
    dateRangeListResponse: contentArray?.dateRangeList,
  };

  if (contentArray && Object.keys(contentArray).length > 0) {
    const countryName = country;

    const contentAccordingToCountry = filterObjectsByCountry(
      contentArray.ContractsAndComponents,
      countryName
    );

    const summaryAndRewardAccordingToCountry = contentArray.SummaryAndRewardComponents.filter(
      (obj) => obj.country === countryName
    );

    const contracts = summaryAndRewardAccordingToCountry.flatMap((item) => [
      {
        contractId: item.physicalNumber,
        feature: item.feature,
        startDate: item.startDate,
        endDate: item.endDate,
      },
    ]);

    const summaryAcceptance = summaryAndRewardAccordingToCountry?.some(
      (data) => data.summaryComponent.toggle.kpiAcceptance
    );

    const types = ['growth', 'numeric', 'percentage', 'irs', 'ar'];
    types.forEach((type) => {
      consolidatedData[type + 'CardDataResponse'] = getKpiList(contentAccordingToCountry, type);
    });

    summaryAndRewardAccordingToCountry.forEach((data) => {
      const { feature } = data;

      if (!consolidatedData.rewardCardDataResponse[feature]) {
        consolidatedData.rewardCardDataResponse[feature] = {
          accepted: false,
          locale: countryName,
          progress: {
            progressValue: 0,
            lastProgressValue: 0,
            historyStatus: false,
          },
          revenue: {
            totalRewards: 0,
            rewardsToEarn: 0,
          },
          contractType: feature,
          isAnyKpiStarted: false,
          allKpisAchieved: false,
          toggles: {
            showToEarnValue: false,
          },
        };
      }
      if (!consolidatedData.kpiCardDataResponse[feature]) {
        consolidatedData.kpiCardDataResponse[feature] = {
          accepted: false,
          amountToAchieve: 0,
          amountAchievedLastMonth: 0,
          amountAchieved: 0,
          kpiNotAchieved: 0,
          contractType: feature,
          totalKpis: 0,
          isAnyKpiStarted: false,
          allKpisAchieved: false,
        };
      }

      const rewardContent = data.rewardComponent.content;
      const summaryContent = data.summaryComponent.content;

      const featureRewardData = consolidatedData.rewardCardDataResponse[feature];
      const featureKpiData = consolidatedData.kpiCardDataResponse[feature];

      featureRewardData.progress.lastProgressValue +=
        rewardContent.rewardProgress.lastTotalRewardsAchieved;
      if (data.rewardComponent.toggle.hasHistoryStatus) {
        featureRewardData.progress.historyStatus = data.rewardComponent.toggle.hasHistoryStatus;
      }
      featureRewardData.revenue.totalRewards += rewardContent.totalRewards;
      featureRewardData.revenue.rewardsToEarn += rewardContent.rewardsToEarn;

      featureKpiData.amountToAchieve += summaryContent.totalOfKpis - summaryContent.kpiAchieved;
      featureKpiData.amountAchievedLastMonth += summaryContent.kpiAchievedLastMonth;
      featureKpiData.amountAchieved += summaryContent.kpiAchieved;
      featureKpiData.kpiNotAchieved += summaryContent.kpiNotAchieved;
      featureKpiData.totalKpis += summaryContent.totalOfKpis;

      const endDate = new Date(data.endDate);
      if (
        !consolidatedData.contractInfoDataResponse.endDate ||
        endDate < new Date(consolidatedData.contractInfoDataResponse.endDate)
      ) {
        consolidatedData.contractInfoDataResponse.contracts = contracts;
        consolidatedData.contractInfoDataResponse.startDate = data.startDate;
        consolidatedData.contractInfoDataResponse.endDate = data.endDate;
        consolidatedData.contractInfoDataResponse.contractId = data.physicalNumber;
        consolidatedData.contractInfoDataResponse.contractName = data.chainName;
        consolidatedData.contractInfoDataResponse.accountId = data.accountId;
        consolidatedData.contractInfoDataResponse.contractType = data.feature;
        consolidatedData.contractInfoDataResponse.chainId = data.chainId;
      }

      if (rewardContent.feature === 'ESCALAS') {
        featureRewardData.progress.progressValue += summaryContent.totalRewardEarned;
      } else {
        featureRewardData.progress.progressValue +=
          rewardContent.rewardProgress.rewardsValueAchieved;
      }
    });

    contentAccordingToCountry.forEach((data) => {
      consolidatedData.contractInfoDataResponse.driverInfoData.push(
        ...data.contract.driverWithProgressAmount
      );
    });

    Object.keys(consolidatedData.rewardCardDataResponse).forEach((feature) => {
      const featureData = consolidatedData.rewardCardDataResponse[feature];
      featureData.accepted = hasAcceptedReward(
        summaryAndRewardAccordingToCountry.filter((d) => d.feature === feature)
      );

      const { isAnyKpiStarted, allKpisAchieved, showToEarnValue } = processKpiData(
        summaryAndRewardAccordingToCountry,
        feature
      );

      featureData.isAnyKpiStarted = isAnyKpiStarted;
      featureData.allKpisAchieved = allKpisAchieved;

      featureData.toggles.showToEarnValue = showToEarnValue;
    });

    Object.keys(consolidatedData.kpiCardDataResponse).forEach((feature) => {
      const featureData = consolidatedData.kpiCardDataResponse[feature];
      featureData.accepted = summaryAcceptance;

      const { isAnyKpiStarted, allKpisAchieved } = processKpiData(
        summaryAndRewardAccordingToCountry,
        feature
      );

      featureData.isAnyKpiStarted = isAnyKpiStarted;
      featureData.allKpisAchieved = allKpisAchieved;
    });
  }
  return consolidatedData;
};
