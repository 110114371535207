import { ISegmentProps } from 'interfaces/ISegment';

interface EventPageProps {
  page_name?: string;
  page_label?: string;
  page_url?: string;
}

const useEventPageProps = (pageProps?: EventPageProps, props?: Partial<ISegmentProps>) => {
  const pagePropsToSave = {
    page_name: pageProps?.page_name || '',
    page_label: pageProps?.page_label || document.title,
    page_url: pageProps?.page_url || window.location.href,
  };
  const propsToSave = {
    ...pagePropsToSave,
    ...props,
  };

  sessionStorage.setItem(`eventPageProps:${window.location.href}`, JSON.stringify(propsToSave));
};

export default useEventPageProps;
