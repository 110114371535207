/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from '@tanstack/react-query';
import { formatUseGetContractByChainIdData } from '@utils';
import { useAppHeader, useUserMetadata } from 'admin-portal-shared-services';
import { ApiError } from 'pages/CommercialAgreementsDetails/sub-components/ContractSummary/ContractSummary.types';
import { useState } from 'react';
import { get as getApi, post as postApi, put } from '../../api';
import {
  IGetStoresByChain,
  TAddContract,
  TContractByIdDataRequest,
  TContractByIdDataResponse,
  TContractByNameDataRequest,
  TContractInfoData,
  TContractResponse,
  TErrorObj,
  TKpiFromChainRequest,
  TKpiFromChainResponse,
  TPINFromChainRequest,
  TPINFromChainResponse,
  UseContractServiceProps,
} from './ContractService.types';

export const CONTRACT_BASE_URL = 'api/link-commercial-kpis-service/contract';
export const CONTRACT_QUERY_KEY = 'contract';
export const CONTRACT_ID_QUERY_KEY = 'contract-id';
export const CONTRACT_KPI_FROM_CHAIN = 'contract-kpi-from-chain';
export const CONTRACT_PIN_BASE_URL = 'api/link-insights/pin';

export const useContractService = () => {
  const [appHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;
  const [errorStatus, setErrorStatus] = useState<number>();
  const { data: userData } = useUserMetadata();

  const useGet = ({ page, pageSize, sortName, sortValue, enabled }: UseContractServiceProps) => {
    const {
      data = {
        content: [],
        pagination: {
          totalElements: 0,
        },
      },
      isLoading,
    } = useQuery<TContractResponse>(
      [CONTRACT_QUERY_KEY, page, pageSize, sortName, sortValue],
      () => {
        const url =
          CONTRACT_BASE_URL +
          `?user_id=${userData.userId}` +
          `&page=${page}` +
          `&size=${pageSize}` +
          `&sort=${sortName},${sortValue}`;

        return getApi({
          url,
          config: {
            headers: {
              country: selectedCountry,
            },
          },
        });
      },
      {
        enabled: !!userData && enabled,
      }
    );

    return { data: data.content, isLoading, pagination: data.pagination };
  };

  const useDeleteById = (id, enabled) => {
    const {
      data = {
        content: [],
      },
      refetch,
    } = useQuery(
      [`contract/${id}`],
      () => {
        const url = `${CONTRACT_BASE_URL}/cancel/${id}`;

        return put({
          url,
          config: {
            headers: {
              requestTraceId: 'delete-contract',
              country: selectedCountry,
            },
          },
        });
      },
      {
        enabled,
        staleTime: 0,
        keepPreviousData: false,
      }
    );
    return { data: data.content, refetch };
  };

  const useDeleteByIds = (ids, enabled) => {
    const {
      data = {
        content: [],
      },
      refetch,
    } = useQuery(
      [`delete-contracts`],
      () => {
        const url = `${CONTRACT_BASE_URL}/batchCancel`;

        return put({
          url,
          config: {
            headers: {
              requestTraceId: 'delete-contracts',
              country: selectedCountry,
            },
          },
          body: {
            contractIds: ids,
          },
        });
      },
      {
        enabled,
        staleTime: 0,
        keepPreviousData: false,
      }
    );
    return { data: data.content, refetch };
  };

  const useGetById = ({ id }: TContractByIdDataRequest) => {
    const {
      data = {
        content: [],
      },
      isLoading,
      isSuccess,
      refetch,
    } = useQuery<TContractByIdDataResponse>([CONTRACT_ID_QUERY_KEY, id, selectedCountry], () => {
      const url = `${CONTRACT_BASE_URL}/byId/${id}`;

      return getApi({
        url,
        config: {
          headers: {
            country: selectedCountry,
          },
        },
      });
    });
    return { data: data.content, isLoading, isSuccess, refetch };
  };

  const useGetByContractName = ({ contractName, pageable }: TContractByNameDataRequest) => {
    const {
      data = {
        content: [],
        pagination: {
          totalElements: 0,
        },
      },
      refetch,
      isLoading,
      isSuccess,
      isError,
    } = useQuery<TContractResponse>(
      ['contract-id', contractName, pageable],
      () => {
        const url = `${CONTRACT_BASE_URL}/contractName?contractName=${contractName}&userId=${userData.userId}&size=${pageable.size}&page=${pageable.page}`;

        return getApi({ url });
      },
      {
        enabled: !!contractName,
      }
    );
    return {
      dataContractName: data.content,
      isLoading,
      isSuccess,
      isError,
      pagination: data.pagination,
      getBySearchTotalElements: data.pagination.totalElements,
      refetch,
    };
  };

  const useGetKpiFromChain = ({ chainId, date }: TKpiFromChainRequest) => {
    const { data, isLoading, isSuccess, isError, error }: TKpiFromChainResponse = useQuery(
      [CONTRACT_KPI_FROM_CHAIN, chainId, date, selectedCountry],
      () => {
        const url = `${CONTRACT_BASE_URL}/kpiFromChain?chainId=${chainId}&date=${date}`;

        return getApi({
          url,
          config: {
            headers: {
              country: selectedCountry,
            },
          },
        });
      },
      {
        onError: (err) => {
          setErrorStatus(err?.response?.status);
        },
      }
    );
    return { data, isLoading, isSuccess, isError, error, status: errorStatus };
  };

  const useGetContractId = (physicalNumber: string) => {
    const { data, isLoading, isSuccess, isError, error } = useQuery([physicalNumber], () => {
      const url = `${CONTRACT_BASE_URL}/available?physicalNumber=${physicalNumber}`;

      return getApi({
        url,
        config: {
          headers: {
            country: selectedCountry,
            requestTraceId: physicalNumber,
          },
        },
      });
    });
    return { data, isLoading, isSuccess, isError, error, status: errorStatus };
  };

  const usePost = () =>
    useMutation({
      mutationFn: (body: TAddContract) =>
        postApi({
          url: CONTRACT_BASE_URL,
          body,
          config: {
            headers: {
              country: selectedCountry,
            },
          },
        }),
    });

  const useGetPINsFromChain = ({ chainId }: TPINFromChainRequest) => {
    const { data, isLoading, isSuccess, isError, error }: TPINFromChainResponse = useQuery(
      [chainId, selectedCountry],
      () => {
        const url = `${CONTRACT_PIN_BASE_URL}/byZoneChain`;
        return getApi({
          url,
          config: {
            headers: {
              country: selectedCountry,
              zone_chain_id: chainId,
              requestTraceId: userData.userId,
            },
          },
        });
      },
      {
        onError: (err) => {
          setErrorStatus(err?.response?.status);
        },
      }
    );
    return { data, isLoading, isSuccess, isError, error, status: errorStatus };
  };

  const useGetContractByClientId = ({
    country,
    clientId,
    date,
    vendorId = '',
    hasfilter = false,
  }) => {
    let rewardCardData: any;
    let kpiCardData: any;
    let growthCardData: any;
    let numericCardData: any;
    let percentageCardData: any;
    let irsCardData: any;
    let arCardData: any;
    let contractInfoData: TContractInfoData;
    let dateRangeList: [];
    let hasError: boolean;
    let errorObj: TErrorObj;

    const {
      data = { content: {} },
      isLoading,
      isError,
      error,
      refetch,
    } = useQuery(
      [clientId, date, country, vendorId, hasfilter],
      () => {
        const url = vendorId
          ? `${CONTRACT_BASE_URL}/kpiFromAccount?accountId=${clientId}&date=${date}&vendorId=${vendorId}&hasFilter=${hasfilter}`
          : `${CONTRACT_BASE_URL}/kpiFromChainWithComponent?chainId=${clientId}&date=${date}`;
        return getApi({
          url,
          config: {
            headers: {
              country,
              requestTraceId: 'kpiFromAccount',
            },
            params: {
              country,
            },
          },
        });
      },
      {
        refetchOnWindowFocus: false,
        retry: false,
      }
    );

    if (!isLoading && !isError && data) {
      const {
        rewardCardDataResponse,
        kpiCardDataResponse,
        growthCardDataResponse,
        numericCardDataResponse,
        percentageCardDataResponse,
        irsCardDataResponse,
        arCardDataResponse,
        contractInfoDataResponse,
        dateRangeListResponse,
      } = formatUseGetContractByChainIdData(data.content, country);
      rewardCardData = rewardCardDataResponse;
      kpiCardData = kpiCardDataResponse;
      growthCardData = growthCardDataResponse;
      numericCardData = numericCardDataResponse;
      percentageCardData = percentageCardDataResponse;
      irsCardData = irsCardDataResponse;
      arCardData = arCardDataResponse;
      contractInfoData = contractInfoDataResponse;

      dateRangeList = dateRangeListResponse || [];

      hasError = isError;
      errorObj = {
        message: undefined,
        status: undefined,
      };
    }

    if ((!isLoading && isError) || !data) {
      hasError = true;
      if (!data) {
        errorObj = { message: 'No data returned from the server', status: 404 };
      }
      if (isError && error instanceof Error) {
        const apiError = error as ApiError;

        errorObj = {
          message: apiError.response?.data.message || 'An error occurred',
          status: Number(apiError.response?.data.code) || 500,
        };
      }
      const {
        rewardCardDataResponse,
        kpiCardDataResponse,
        growthCardDataResponse,
        numericCardDataResponse,
        percentageCardDataResponse,
        irsCardDataResponse,
        arCardDataResponse,
        contractInfoDataResponse,
        dateRangeListResponse,
      } = formatUseGetContractByChainIdData(data.content, country);
      rewardCardData = rewardCardDataResponse;
      kpiCardData = kpiCardDataResponse;
      growthCardData = growthCardDataResponse;
      numericCardData = numericCardDataResponse;
      percentageCardData = percentageCardDataResponse;
      irsCardData = irsCardDataResponse;
      arCardData = arCardDataResponse;
      contractInfoData = contractInfoDataResponse;
      dateRangeList = dateRangeListResponse || [];
    }

    return {
      rewardCardData,
      kpiCardData,
      growthCardData,
      numericCardData,
      percentageCardData,
      irsCardData,
      arCardData,
      contractInfoData,
      dateRangeList,
      isLoading,
      hasError,
      errorObj,
      refetch,
    };
  };

  const useGetStoresByChain = ({ country, chainId, feature, status }: IGetStoresByChain) => {
    let hasError: boolean;
    let errorObj: TErrorObj;
    let storeData: [];

    const { isLoading, data, isError, error } = useQuery(
      ['stores-by-chain', chainId, country, status],
      () => {
        const url = `/api/link-commercial-kpis-service/chain/poc/byChainId?chainId=${chainId}&feature=${feature}&contractStatus=${status}`;
        return getApi({
          url,
          config: {
            headers: {
              country,
              requestTraceId: 'stores-by-chain',
            },
            params: {
              country,
            },
          },
        });
      },
      {
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 0,
        keepPreviousData: false,
      }
    );

    if ((!isLoading && isError) || (!isLoading && !data)) {
      hasError = true;
      if (!data) {
        errorObj = { message: 'No data returned from the server', status: 404 };
      }
      if (isError && error instanceof Error) {
        const apiError = error as ApiError;

        errorObj = {
          message: apiError.response?.statusText || 'An error occurred',
          status: apiError.response?.status || 500,
        };
      }
      storeData = [];
    } else if (!isLoading && data) {
      hasError = isError;
      errorObj = {
        message: null,
        status: null,
      };
      storeData = data.content;
    }

    return { isLoading, data: storeData, hasError, errorObj };
  };

  return {
    useGet,
    useGetById,
    usePost,
    useDeleteById,
    useDeleteByIds,
    useGetByContractName,
    useGetKpiFromChain,
    useGetContractId,
    useGetPINsFromChain,
    useGetContractByClientId,
    useGetStoresByChain,
  };
};
