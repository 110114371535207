/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppHeader, useUserMetadata } from 'admin-portal-shared-services';
import { get as getApi, post, postFormData } from '../../api';
import { IRequestFileDataRequestProps, ISpreeadsheetRequestResponse } from './UploadService.types';
import {
  QUERY_KEY_TEMPLATE,
  QUERY_KEY_UPLOAD,
  FEATURE_TYPE,
  urlAccordingCountryAndObjective,
} from './constants';

export const useUploadService = () => {
  const [appHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;
  const { data: userData } = useUserMetadata();

  const getSpreeadsheetTemplate = ({ featureType }: IRequestFileDataRequestProps) => {
    const templateForAR = urlAccordingCountryAndObjective[selectedCountry]?.template;
    const templateForZA = urlAccordingCountryAndObjective[selectedCountry]?.template[featureType];
    const url = selectedCountry === 'AR' ? `${templateForAR}` : `${templateForZA}`;

    const { data, refetch, error } = useQuery<ISpreeadsheetRequestResponse>({
      queryKey: [QUERY_KEY_TEMPLATE, featureType],
      queryFn: () => {
        return post({
          url: url,
          config: {
            headers: {
              country: selectedCountry,
              requestTraceId: `${QUERY_KEY_TEMPLATE}_${featureType}`,
            },
          },
        });
      },
      enabled: !!featureType,
    });

    let errorMessage: string | null = null;
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    return { data, refetch, error: errorMessage };
  };

  const postFileData = ({ actionType, featureType }: IRequestFileDataRequestProps) => {
    const { mutate, isLoading, isError, reset } = useMutation({
      mutationFn: async (fileToProcess: File) => {
        const data = new FormData();
        data.append('file', fileToProcess);

        const urlForAR = urlAccordingCountryAndObjective[selectedCountry]?.uploadProcess;
        const urlForZA =
          urlAccordingCountryAndObjective[selectedCountry]?.uploadProcess[featureType];

        const url = selectedCountry === 'AR' ? `${urlForAR}` : `${urlForZA}`;
        const response = await postFormData({
          url: url,
          config: {
            headers: {
              country: selectedCountry,
              requestTraceId: `${QUERY_KEY_UPLOAD}_${actionType}`,
            },
          },
          data,
        });

        return response;
      },
    });

    return { mutate, isLoading, isError, reset };
  };

  const postIRSFileData = ({ actionType }: IRequestFileDataRequestProps) => {
    const { userId, vendors, profile } = userData || {};
    const { preferredLanguage } = profile || {};
    const vendorId = vendors && vendors.length > 0 ? vendors[0].id : null;

    const { mutate, isLoading, isError, reset } = useMutation({
      mutationFn: async (fileToProcess: File) => {
        const data = new FormData();
        data.append('file', fileToProcess);

        const url = `${urlAccordingCountryAndObjective[selectedCountry].uploadProcess['IRS']}`;
        const response = await postFormData({
          url: url,
          config: {
            headers: {
              country: selectedCountry,
              requestTraceId: `${QUERY_KEY_UPLOAD}_${actionType}`,
              feature: `${FEATURE_TYPE.IRS}`,
              language: `${preferredLanguage}`,
              userId: `${userId}`,
              vendorId: `${vendorId}`,
            },
          },
          data,
        });
        return response;
      },
    });

    return { mutate, isLoading, isError, reset };
  };

  const getProcessingStatus = (processId: string, enable: boolean) => {
    const { refetch, data } = useQuery({
      queryKey: ['processingStatus', processId, enable],

      queryFn: async () => {
        const url = `api/link-commercial-kpis-service/spreadsheet?processId=${processId}`;

        const response = await getApi({
          url,
          config: {
            headers: {
              country: selectedCountry,
              requestTraceId: `${processId}`,
              feature: `${FEATURE_TYPE.IRS}`,
              language: `${userData.profile.preferredLanguage}`,
              userId: `${userData.userId}`,
              vendorId: `${userData.vendors[0].id}`,
            },
          },
        });
        return response.spreadsheetContent || response.spreadsheetDetailContent;
      },

      refetchInterval: (data) =>
        data?.spreadsheetContent?.status !== 'processed' ||
        data?.spreadsheetDetailContent?.status !== 'processed'
          ? 10000
          : false,

      refetchOnWindowFocus: false,
      enabled: enable,
    });

    return { refetch, data: data };
  };

  return {
    getSpreeadsheetTemplate,
    postFileData,
    postIRSFileData,
    getProcessingStatus,
  };
};
