import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface KPIsCircularProgressProps {
  reachedContractKPIs: number;
  contractKPIs: number;
  sizeBorder: number;
  sizeProgress: number;
  name: string;
  isComplete?: boolean;
}

function BorderCircularProgress({ size, ...props }) {
  return (
    <CircularProgress
      variant="determinate"
      sx={{ color: '#d9d9d9' }}
      size={size}
      thickness={4}
      value={100}
      {...props}
    />
  );
}

const renderTypography = (fontSize: number, text: string) => {
  return (
    <Typography fontSize={fontSize} variant="caption" component="div" color="black">
      {text}
    </Typography>
  );
};

function Label({ reachedContractKPIs, contractKPIs, fontSize, name }) {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {renderTypography(fontSize, `${reachedContractKPIs}/${contractKPIs}`)}
        {renderTypography(fontSize, name)}
      </Box>
    </Box>
  );
}

function ProgressCircularProgress({
  size,
  reachedContractKPIs,
  contractKPIs,
  isComplete,
  ...props
}) {
  return (
    <CircularProgress
      variant="determinate"
      sx={{
        color: isComplete ? '#31bd54' : '#1976d2',
        position: 'absolute',
        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }}
      size={size}
      thickness={4}
      value={(reachedContractKPIs / contractKPIs) * 100}
      {...props}
    />
  );
}

export const KPIsCircularProgress = ({
  reachedContractKPIs,
  contractKPIs,
  sizeProgress,
  sizeBorder,
  name,
  isComplete,
}: KPIsCircularProgressProps) => {
  const fontSize = sizeProgress / 5.6;

  return (
    <Box
      sx={{ position: 'relative', display: 'inline-flex' }}
      data-testid="circular-progress-container"
    >
      <BorderCircularProgress size={sizeBorder} />
      <Label
        reachedContractKPIs={reachedContractKPIs}
        contractKPIs={contractKPIs}
        fontSize={fontSize}
        name={name}
      />
      <ProgressCircularProgress
        size={sizeProgress}
        reachedContractKPIs={reachedContractKPIs}
        contractKPIs={contractKPIs}
        isComplete={isComplete}
      />
    </Box>
  );
};
