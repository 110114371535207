import { ArrowDown, ArrowUp } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';

export const SupportInfoContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  height: '1.95rem',
  width: '14.375rem',
  fontSize: '0.75rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '1.125rem',
  fontFamily: 'var(--fonts-paragraph)',
});

export const ArrowUpIcon = styled(ArrowUp, {
  width: '0.75rem',
  height: '0.75rem',
  flexShrink: '0',
});

export const ArrowDownIcon = styled(ArrowDown, {
  width: '0.75rem',
  height: '0.75rem',
  flexShrink: '0',
});
