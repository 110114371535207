import { AppHeaderConfigPayload } from 'admin-portal-shared-services';
import { MessageDescriptor } from 'react-intl';
import {
  Modules,
  contractCreatePath,
  contractDetailsPath,
  updateByUploadPath,
} from 'routes/Definition';

export const getAppHeaderConfig: (
  pageName: Modules,
  formatMessage: (descriptor: MessageDescriptor) => string
) => Promise<AppHeaderConfigPayload> = async (pageName, formatMessage) => {
  const {
    homePath,
    commercialAgreementsPath,
    contractManagementPath,
    visibilityPath,
    commercialAgreementsDetailsPath,
  } = await import('routes/Definition');

  switch (pageName) {
    case 'COMMERCIAL_AGREEMENTS':
      return {
        pageTitle: formatMessage({ id: 'pages.home.title' }),
        countrySelect: true,
        breadcrumbConfig: {
          homePath,
          items: getItemsCommercialAgreements(formatMessage, commercialAgreementsPath),
        },
      };
    case 'VISIBILITY':
      return {
        pageTitle: formatMessage({ id: 'pages.JBPVisibility.title' }),
        countrySelect: true,
        breadcrumbConfig: {
          homePath,
          items: getItemsVisibility(formatMessage, commercialAgreementsPath, visibilityPath),
        },
      };
    case 'CONTRACT_MANAGEMENT':
      return {
        pageTitle: formatMessage({ id: 'pages.JBPContractManagment.title' }),
        countrySelect: false,
        breadcrumbConfig: {
          homePath,
          items: getItemsContractManagement(
            formatMessage,
            commercialAgreementsPath,
            contractManagementPath
          ),
        },
      };
    case 'CONTRACT_CREATE':
      return {
        pageTitle: ' ',
        countrySelect: false,
        breadcrumbConfig: {
          homePath,
          items: getItemsContractCreate(
            formatMessage,
            commercialAgreementsPath,
            contractManagementPath,
            contractCreatePath
          ),
        },
      };
    case 'CONTRACT_DETAILS':
      return {
        pageTitle: formatMessage({ id: 'pages.JBPContractManagment.details.title' }),
        countrySelect: false,
        breadcrumbConfig: {
          homePath,
          items: getItemsContractDetails(
            formatMessage,
            commercialAgreementsPath,
            contractManagementPath,
            contractDetailsPath
          ),
        },
      };
    case 'UPDATE_UPLOAD_PAGE':
      return {
        pageTitle: formatMessage({ id: 'pages.home.navigationCards.UpdateByUpload.title' }),
        countrySelect: true,
        breadcrumbConfig: {
          homePath,
          items: getItemsUpdateKPIs(formatMessage, commercialAgreementsPath, updateByUploadPath),
        },
      };
    case 'PINS_VISIBILITY':
      return {
        pageTitle: formatMessage({ id: 'pages.PINVisibility.title' }),
        countrySelect: true,
        breadcrumbConfig: {
          homePath,
          items: getItemsPinsVisibility(formatMessage, commercialAgreementsPath, visibilityPath),
        },
      };
    case 'COMMERCIAL_AGREEMENTS_DETAILS':
      return {
        pageTitle: formatMessage({ id: 'pages.commercialAgreementsDetailsPage.title' }),
        countrySelect: true,
        breadcrumbConfig: {
          homePath,
          items: getItemsCommercialAgreementsDetails(
            formatMessage,
            commercialAgreementsPath,
            visibilityPath,
            commercialAgreementsDetailsPath
          ),
        },
      };
    case 'CREATE_UPLOAD_PAGE':
      return {
        pageTitle: formatMessage({ id: 'pages.home.navigationCards.CreateByUpload.title' }),
        countrySelect: false,
        breadcrumbConfig: {
          homePath,
          items: getItemsContractCreateUpload(
            formatMessage,
            commercialAgreementsPath,
            contractManagementPath,
            contractCreatePath
          ),
        },
      };
  }
};

const getItemsCommercialAgreements = (
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.commercialAgreements.title' }),
    path: paths[0],
    isCurrentPage: true,
  },
];

const getItemsVisibility = (
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.commercialAgreements.title' }),
    path: paths[0],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.JBPVisibility.title' }),
    path: paths[1],
    isCurrentPage: true,
  },
];

const getItemsContractManagement = (
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.commercialAgreements.title' }),
    path: paths[0],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.JBPContractManagment.title' }),
    path: paths[1],
    isCurrentPage: true,
  },
];

const getItemsContractCreate = (
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.commercialAgreements.title' }),
    path: paths[0],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.JBPContractManagment.title' }),
    path: paths[1],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.JBPContractManagment.createContract.title' }),
    path: paths[2],
    isCurrentPage: true,
  },
];

const getItemsContractDetails = (
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.commercialAgreements.title' }),
    path: paths[0],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.JBPContractManagment.title' }),
    path: paths[1],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'pages.JBPContractManagment.contractDetails' }),
    path: paths[2],
    isCurrentPage: true,
  },
];

const getItemsUpdateKPIs = (
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.commercialAgreements.title' }),
    path: paths[0],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.JPBUpdate.title' }),
    path: paths[1],
    isCurrentPage: true,
  },
];

const getItemsPinsVisibility = (
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.commercialAgreements.title' }),
    path: paths[0],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.PINVisibility.title' }),
    path: paths[1],
    isCurrentPage: true,
  },
];

const getItemsCommercialAgreementsDetails = (
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.commercialAgreements.title' }),
    path: paths[0],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.PINVisibility.title' }),
    path: paths[1],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.commercialAgreementsDetails.title' }),
    path: paths[2],
    isCurrentPage: true,
  },
];

const getItemsContractCreateUpload = (
  formatMessage: (descriptor: MessageDescriptor) => string,
  ...paths: string[]
) => [
  {
    label: formatMessage({ id: 'breadcrumb.commercialAgreements.title' }),
    path: paths[0],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.JBPContractManagment.title' }),
    path: paths[1],
    isCurrentPage: false,
  },
  {
    label: formatMessage({ id: 'breadcrumb.JBPContractManagment.createContractUpload.title' }),
    path: paths[2],
    isCurrentPage: true,
  },
];
