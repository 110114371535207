import { addMinutes, compareAsc, compareDesc, format, isValid, startOfMinute } from 'date-fns';

export const updateUrlValue = (field: string, value: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set(field, value);
  window.history.replaceState(null, '', url);
};

export const deleteUrlValue = (field: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(field);
  window.history.replaceState(null, '', url);
};

export const getDayBefore = (): Date => {
  const now = new Date();
  now.setHours(now.getHours() - 24);
  return now;
};

export const getDaysBefore = (days: number): Date => {
  const now = new Date();
  now.setHours(now.getHours() - 24 * days);
  return now;
};

export const roundToNearest15Minutes = (date: Date) => {
  if (!isValid(date)) return new Date();
  const minutes = date.getMinutes();
  const diff = 15 - (minutes % 15);
  const roundedDate = addMinutes(startOfMinute(date), diff >= 8 ? diff : -minutes % 15);
  return roundedDate;
};

export const getTimeFromDate = (date: Date) =>
  new Date(0, 0, 0, date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());

export const parseDate = (date: Date): string => format(date, "yyyy-MM-dd'T'HH:mm:ss");

export const sortDateAsc = (a: Date, b: Date) => {
  const timeA = getTimeFromDate(a);
  const timeB = getTimeFromDate(b);
  return compareAsc(timeA, timeB);
};

export const sortDateDesc = (a: Date, b: Date) => {
  const timeA = getTimeFromDate(a);
  const timeB = getTimeFromDate(b);
  return compareDesc(timeA, timeB);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getTabUUID = (): string => {
  let { tabUUID } = global as any;
  if (!tabUUID) {
    tabUUID = crypto.randomUUID();
    (global as any).tabUUID = tabUUID;
  }
  return tabUUID;
};

export type HistoryRoute = 'MonitoringView' | 'MonitoringDetails' | 'DataChunkDetails';

export const addPageToHistory = (route: HistoryRoute) => {
  const storageItem = localStorage.getItem(`navigationHistory-${getTabUUID()}`);
  const navigationHistory: HistoryRoute[] = storageItem ? JSON.parse(storageItem) : [];
  if (navigationHistory.includes(route)) return;
  localStorage.setItem(
    `navigationHistory-${getTabUUID()}`,
    JSON.stringify([...navigationHistory, route])
  );
};

export const hasPageInHistory = (route: HistoryRoute): boolean => {
  const storageItem = localStorage.getItem(`navigationHistory-${getTabUUID()}`);
  const navigationHistory: HistoryRoute[] = storageItem ? JSON.parse(storageItem) : [];
  return navigationHistory.includes(route);
};
