// segmentEventService.ts
import { Country } from 'admin-portal-shared-services';
import UserMetadata from 'admin-portal-shared-services/dist/services/userMetadata/model/UserMetadata';
import segmentAnalytics from 'analytics';
import { APP_ID, APP_NAME } from 'consts/configs';
import { ISegmentProps, ISegmentRequiredProps, SegmentEventsType } from 'interfaces/ISegment';
import { IVendor } from 'interfaces/IVendor';
import { MutableRefObject } from 'react';
import { getUserLocalData } from 'services/userLocalData/UserLocalDataService';
import { getUserEmail } from 'utils/authUserHelper';

const SegmentEvents = segmentAnalytics;

export const createSegmentEvent =
  (analyticsParamsRef: MutableRefObject<ISegmentRequiredProps | undefined>) =>
  (eventName: SegmentEventsType, args?: Partial<ISegmentProps>): void => {
    const { selectedCountry, selectedVendor, id, authorization } = getUserLocalData();

    const vendorProps = getVendorProps(selectedVendor);
    const userProps = getUserProps(selectedCountry, id, authorization);
    const pageProps = getCurrentPageProps(args?.page_name ?? null);

    if (!analyticsParamsRef.current) {
      analyticsParamsRef.current = {} as ISegmentRequiredProps;
    }

    analyticsParamsRef.current = {
      app_id: APP_ID,
      app_display_name: APP_NAME,
      country: selectedCountry,
      is_core_event: false,
      view_level: null,
      ...vendorProps,
      ...userProps,
      ...pageProps,
    };

    if (SegmentEvents[eventName]) {
      (SegmentEvents as any)[eventName]({
        ...analyticsParamsRef.current,
        ...args,
      });
    }
  };

const getVendorProps = (selectedVendor: IVendor) => {
  const { isManufacturer, name, id, serviceModel, country, tier } = selectedVendor;

  return {
    manufacturer_country: isManufacturer ? country : null,
    manufacturer_display_name: isManufacturer ? name : null,
    manufacturer_id: isManufacturer ? id : null,
    manufacturer_service_model: isManufacturer ? serviceModel : null,
    manufacturer_tier_name: null,
    vendor_country: country ?? null,
    vendor_display_name: name ?? null,
    vendor_id: id ?? null,
    vendor_service_model: serviceModel ?? null,
    vendor_tier_name: tier?.name ?? null,
  };
};

const getUserProps = (
  selectedCountry: Country,
  id?: string,
  authorization?: UserMetadata['authorization']
) => {
  const userEmail = getUserEmail();

  return {
    user_id: id || null,
    user_country: selectedCountry,
    user_email: userEmail,
    user_permission_group: authorization?.groups?.find((group) =>
      group.includes('DataIngestionPortal')
    ),
  };
};

const getCurrentPageProps = (pageName: string | null) => ({
  page_label: document.title,
  page_name: pageName,
  page_url: window.location.href,
  referrer: document.referrer,
});
