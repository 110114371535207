import { ReactSDKClient } from '@optimizely/react-sdk';
import { FeatureToggles, featureTogglesState } from 'consts/featureTogglesMap';
import { setStoplightLinks } from 'features/data-management/uploadData';
import { ICountriesList, setCountriesList } from 'features/europeCluster/europeCluster';
import { changeFeatureToggle } from 'features/featureToggle/featureToggle';
import { setNewTabEnabled, setRetentionDays } from 'features/monitoring/monitoringSlice';
import { ITemplate } from 'interfaces/ITemplate';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export function useFeatureToggle(optimizely: ReactSDKClient | null): void {
  const dispatch = useDispatch();
  let toggleState = featureTogglesState;
  let countriesList: ICountriesList[] = [];
  let stoplightLinks: ITemplate[] = [];
  let retentionDays = 10;
  let newTabEnabled = true;

  useEffect(() => {
    if (optimizely) {
      optimizely.onReady().then(() => {
        const decisionValues = optimizely.decideForKeys(
          Object.values(FeatureToggles).map((featureName) => featureName)
        );

        Object.values(decisionValues).forEach((decision) => {
          if (
            decision.flagKey === FeatureToggles.BEES_SYNC_EUROPE_CLUSTER &&
            !!(decision.variables?.enabled_countries as { list: ICountriesList[] })?.list.length
          ) {
            countriesList = (decision.variables?.enabled_countries as { list: ICountriesList[] })
              ?.list;
          }

          if (
            decision.flagKey === FeatureToggles.BEES_SYNC_STOPLIGHT_LINKS &&
            !!(decision.variables?.stoplight as { links: ITemplate[] })?.links.length
          ) {
            stoplightLinks = (decision.variables?.stoplight as { links: ITemplate[] })?.links;
          }

          if (decision.flagKey === FeatureToggles.BEES_SYNC_MONITORING_TOOL_V2) {
            retentionDays = Number(decision.variables.retention_days);
            newTabEnabled = Boolean(decision.variables.open_new_tab);
          }

          if (!decision.reasons.length) {
            toggleState = {
              ...toggleState,
              [decision.flagKey]: decision.enabled,
            };
          }
        });

        dispatch(setStoplightLinks(stoplightLinks));
        dispatch(setCountriesList(countriesList));
        dispatch(changeFeatureToggle(toggleState));
        dispatch(setRetentionDays(retentionDays));
        dispatch(setNewTabEnabled(newTabEnabled));
      });
    }
  }, []);
}
