import React from 'react';
import { Helmet } from 'react-helmet';

export const DocumentTitle = ({ children, titleTemplatePrefix='BEES One' }: { children: string, titleTemplatePrefix?: string }): React.ReactElement => {
  return (
    <Helmet titleTemplate={`${titleTemplatePrefix} - %s`}>
      <title>{children}</title>
    </Helmet>
  );
};
