import { Card as HexaCard } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Card = styled(HexaCard, {
  padding: '1.5rem',
  width: '48%',
  height: '200px',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 4px 0px !important',
});

export const Title = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '0.5rem',
});

export const CardContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '1 0 0',
  alignSelf: 'stretch',
});

export const TargetProgressContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  gap: '0.15rem',
});

export const TotalRevenueContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  gap: '0.15rem',
});
