import { Heading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const ProgressValueContainer = styled('div', {
  height: '2.55rem',
});

export const HeadingCard = styled(Heading, {
  paddingTop: '0.75rem',
  fontFamily: 'var(--fonts-heading)',
});

export const EmptyProgressContainer = styled('div', {
  fontSize: '1rem',
  fontStyle: 'normal',
  fontFamily: 'var(--fonts-paragraph)',
  lineHeight: '1.5rem',
  maxWidth: '28.625rem',
  paddingTop: '2rem',
  color: ' #1414148F',
  opacity: '56%',
});
