import React from 'react';
import InputMask from 'react-input-mask';
import { Input } from '@hexa-ui/components';
import { Calendar } from '@hexa-ui/icons';
import { FormControl, FormHelperText, FormLabel, OutlinedTextFieldProps } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { formatMessage } from 'i18n/formatters';
import useStyles from './MaskedInput.styles';

interface IMaskedInput extends OutlinedTextFieldProps {
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  mask: string;
  date?: boolean;
  inputTestId?: string;
  customPlaceholder?: string;
  required?: boolean;
  newLayout?: boolean;
  errorMessage?: string;
  optional?: boolean;
  value?: string;
  label?: string;
  suffix?: string | React.ReactNode;
}

export default function MaskedInput({
  onChange,
  value,
  onClick,
  id,
  name,
  label,
  mask,
  disabled,
  error,
  errorMessage,
  optional,
  className,
  required,
  date,
  inputTestId,
  customPlaceholder,
  newLayout = false,
  suffix,
}: IMaskedInput): JSX.Element {
  const { formControlClass, errorClass, optionalClass, labelClass, calendarIcon } = useStyles();

  if (newLayout) {
    return (
      <InputMask
        onClick={onClick}
        mask={mask}
        value={value}
        id={id}
        onChange={onChange}
        disabled={disabled}
      >
        {() => (
          <Input
            className={className}
            data-testid={inputTestId}
            id={id}
            width="100%"
            placeholder={customPlaceholder}
            label={label}
            name={name}
            required={required}
            onClick={onClick}
            disabled={disabled}
            hasError={error}
            sufix={suffix as string}
          />
        )}
      </InputMask>
    );
  }

  return (
    <FormControl className={`${formControlClass} ${className}`} error={error}>
      {label && (
        <FormLabel className={labelClass} htmlFor={id} data-testid="masked-input-label">
          {!required ? label : `${label}*`}
          {optional && (
            <span data-testid="masked-input-optional" className={optionalClass}>
              &nbsp;{formatMessage({ id: 'Components.OPTIONAL_LABEL' })}
            </span>
          )}
        </FormLabel>
      )}
      {error && (
        <FormHelperText className={errorClass} data-testid="masked-input-error">
          <Error fontSize="inherit" />
          {errorMessage}
        </FormHelperText>
      )}
      <InputMask
        onClick={onClick}
        mask={mask}
        value={value}
        id={id}
        onChange={onChange}
        disabled={disabled}
      >
        {() => (
          <Input
            data-testid={inputTestId}
            style={{ height: 40 }}
            id={id}
            width="100%"
            placeholder={customPlaceholder}
            label={label}
            name={name}
            required={required}
            onClick={onClick}
            disabled={disabled}
            hasError={error}
            sufix={
              date
                ? ((<Calendar className={calendarIcon} size="large" />) as unknown as string)
                : undefined
            }
          />
        )}
      </InputMask>
    </FormControl>
  );
}
