import { referrers, segmentScreenName, valueStreamNames } from '@constants';
import { Card } from '@hexa-ui/components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { isDeviceMobile } from '@utils';
import { useIntl } from 'react-intl';
import { buttonClicked } from '../../../analytics';
import * as Styles from './SummaryCard.styles';
import { SummaryCardProps } from './SummaryCard.types';
import { KPIsCircularProgress, LoadingSummaryCard } from './sub-components';

export const SummaryCard = ({
  isLoading,
  contractType,
  accepted,
  amountToAchieve,
  amountAchievedLastMonth,
  amountAchieved,
  totalKpis,
  country,
  allKpisAchieved,
  isAnyKpiStarted,
}: SummaryCardProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const titleLabel = formatMessage({ id: 'kpiCard.title' });

  if (isLoading) {
    return <LoadingSummaryCard />;
  }

  const contractTypeNeedsAcceptance = ['PIN', 'PN'];

  const triggerButtonClickedEvent = (buttonLabel, buttonName) => {
    buttonClicked({
      button_label: buttonLabel,
      button_name: buttonName,
      referrer: referrers.jbpVisibility,
      screen_name: segmentScreenName.commercialAgreements,
      url: !isDeviceMobile() ? window.location.href : null,
      store_id: '',
      value_stream: valueStreamNames.linkJbp,
    });
  };

  let supportMessage = null;
  if (allKpisAchieved && country === 'AR') {
    supportMessage = (
      <Styles.SupportMessageWrapper>
        {formatMessage({ id: 'kpiCard.allCompleted' })}
      </Styles.SupportMessageWrapper>
    );
  } else if (contractType === 'JBP' && amountAchievedLastMonth) {
    supportMessage = (
      <Typography
        fontFamily={'Work Sans'}
        fontSize={12}
        color="#808080"
        data-testid="amount-achieved-last-month"
      >
        {`${amountAchievedLastMonth} ${formatMessage({
          id: 'kpiCard.achievedLastMonth',
        })}`}
      </Typography>
    );
  }

  const renderAcceptanceMessage = () => {
    if (
      (country === 'AR' && !isAnyKpiStarted) ||
      (contractTypeNeedsAcceptance.includes(contractType) && !accepted)
    ) {
      return (
        <Styles.SupportMessageWrapper>
          {formatMessage({
            id: 'kpiCard.acceptTargets',
          })}
        </Styles.SupportMessageWrapper>
      );
    } else {
      return null;
    }
  };

  return (
    <Card
      border={'medium'}
      elevated={'medium'}
      style={{
        padding: '1.5rem',
        width: '49.5%',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 4px 0px',
      }}
    >
      <Box
        onClick={() => triggerButtonClickedEvent(titleLabel, `KPIs Summary - ${titleLabel}`)}
        style={{ display: 'flex', justifyContent: 'space-between' }}
        data-testid="card-container"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography fontFamily={'Barlow'} fontSize={16}>
            {titleLabel}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
            <Typography
              fontFamily={'Barlow'}
              fontSize={28}
              fontWeight={500}
              data-testid="amount-to-achieve"
            >
              {amountToAchieve}
            </Typography>
            <Typography fontSize={28} fontWeight={500}>
              {formatMessage({ id: 'kpiCard.amountToAchieve' })}
            </Typography>
          </Box>
          {renderAcceptanceMessage()}
          {supportMessage}
        </Box>
        {accepted && (
          <KPIsCircularProgress
            reachedContractKPIs={amountAchieved}
            contractKPIs={totalKpis}
            sizeProgress={90}
            sizeBorder={90}
            name={formatMessage({ id: 'kpiCard.name' })}
            isComplete={allKpisAchieved}
          />
        )}
      </Box>
    </Card>
  );
};
