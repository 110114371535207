export const QUERY_KEY_TEMPLATE = 'template';

export const QUERY_KEY_UPLOAD = 'upload';

export const SPREEADSHEET_JBP_TEMPLATE_URL = `api/link-commercial-kpis-service/driver/template`;

export const SPREEADSHEET_JBP_UPLOAD_URL = `api/link-commercial-kpis-service/driver/template/upload`;

export const SPREADSHEETS_IRS_TEMPLATE_URL = `api/link-commercial-kpis-service/contract/template`;

export const SPREADSHEETS_AR_TEMPLATE_URL = `api/link-commercial-kpis-service/contract/template/ar`;

export const SPREADSHEETS_AR_PROCESSING = `api/link-commercial-kpis-service/driver/template/upload?fileType=AR`;

export const SPREEADSHEET_IRS_UPLOAD_URL = `api/link-commercial-kpis-service/spreadsheet/upload`;

export const FEATURE_TYPE = {
  JBP: 'JBP',
  IRS: 'IRS',
  ESCALAS: 'ESCALAS',
};

export const urlAccordingCountryAndObjective = {
  AR: {
    template: SPREADSHEETS_AR_TEMPLATE_URL,
    uploadProcess: SPREADSHEETS_AR_PROCESSING,
  },
  ZA: {
    template: {
      IRS: SPREADSHEETS_IRS_TEMPLATE_URL,
      JBP: SPREEADSHEET_JBP_TEMPLATE_URL,
    },
    uploadProcess: {
      IRS: SPREEADSHEET_IRS_UPLOAD_URL,
      JBP: SPREEADSHEET_JBP_UPLOAD_URL,
    },
  },
};
