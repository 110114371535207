export const getCurrencySymbol = (currency: string, locale: string) => {
  try {
    const currencyFormatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    });

    const currencySymbol = currencyFormatter.formatToParts(1)[0].value;

    return currencySymbol;
  } catch (error) {
    console.error(
      `Failed to get currency symbol for currency "${currency}" and locale "${locale}"`,
      error
    );
    throw error;
  }
};
