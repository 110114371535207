import { IExtendedMonitoringDetailsRecord } from 'services/monitoring/recordService';
import { CustomStatus } from '../../consts/chunks';

interface ProgressCounterProps {
  chunkKey: string;
  value: IExtendedMonitoringDetailsRecord[];
  amount: number;
  stepStatus: CustomStatus;
  stepName: string;
}

export default function ProgressCounter({
  chunkKey,
  value,
  amount,
  stepStatus,
  stepName,
}: Readonly<ProgressCounterProps>) {
  const getChunkProgressTitle = () => {
    if (
      (stepName === 'ADHERENCE_VALIDATION' || stepName === 'DATA_DISTRIBUTION') &&
      stepStatus === CustomStatus.IN_PROGRESS
    ) {
      return 'advanced';
    }

    if (stepStatus === CustomStatus.COMPLETED) {
      return 'advanced';
    }

    if (chunkKey === CustomStatus.IN_PROGRESS) {
      return 'in progress';
    }

    return chunkKey.toLowerCase();
  };

  return (
    <div data-testid="progress-counter" key={chunkKey}>
      <div className="counter-chunk">
        {(chunkKey === CustomStatus.COMPLETED || chunkKey === CustomStatus.IN_PROGRESS) &&
          `${value.length} of ${amount} ${getChunkProgressTitle()}`}
      </div>
    </div>
  );
}
