import { Card, Heading, Paragraph } from '@hexa-ui/components';
import { Copy, Download } from '@hexa-ui/icons';
import { Box } from '@material-ui/core';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { ElementLocationType, ElementType, PageName, SpecificApp } from 'analytics';
import { SegmentEvents } from 'consts/segment';
import { formatMessage } from 'i18n/formatters';
import { SegmentProps } from 'interfaces/SegmentProps';
import CopyToClipboard from 'react-copy-to-clipboard';
import { colors } from 'themes/palette';
import './CardPayload.css';

function CardPayload({
  children,
  contentToCopy,
  onDownload,
  segmentProps,
}: {
  children: React.ReactElement;
  contentToCopy: string;
  onDownload: () => void;
  segmentProps: SegmentProps;
}): JSX.Element {
  const toastService = useToast();
  const {
    payload,
    errorMessage,
    status,
    event,
    action,
    entity,
    version,
    traceId,
    step,
    sourceSystem,
  } = segmentProps;

  const openCopyToast = () => {
    toastService.notify({
      message: formatMessage({ id: 'MonitoringPage.COPIED' }),
      type: TypeToast.INFO,
    });
  };

  return (
    <Card data-testid="card-chunk-details" border="medium" elevated="small">
      <Box
        className="row"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '24px',
        }}
      >
        <Box>
          <Heading size="H4">{formatMessage({ id: 'MonitoringPage.PAYLOAD' })}</Heading>
          <Paragraph
            size="small"
            css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
          >
            {formatMessage({ id: 'MonitoringPage.MESSAGE_PAYLOAD' })}
          </Paragraph>
        </Box>
        <Box className="wrapper-copy-download">
          <CopyToClipboard text={contentToCopy} onCopy={openCopyToast}>
            <Box
              className="row"
              onClick={() => {
                event(SegmentEvents.ELEMENT_CLICKED, {
                  page_name: PageName.BeesSyncMonitoringDataChunkDetails,
                  element_location_name: 'Data chunk details payload card',
                  element_location_type: ElementLocationType.Null,
                  element_type: ElementType.Button,
                  element_label: 'Copy Json',
                  element_name: 'ChunkDetailsJson_CopyButton',
                  specific_app: SpecificApp.Monitoring,
                  status,
                  version,
                  entity,
                  action,
                  data_chunk_trace_id: traceId,
                  data_chunk_step: step,
                  data_chunk_source_system: sourceSystem,
                  data_chunk_error_message: errorMessage,
                  data_chunk_payload_present: !!payload,
                });
              }}
            >
              <Copy
                size="large"
                style={{
                  color: '#006EDB',
                }}
              />
              <Paragraph size="small" weight="medium" css={{ color: '#006EDB' }}>
                {formatMessage({ id: 'MonitoringPage.COPY_JSON' })}
              </Paragraph>
            </Box>
          </CopyToClipboard>
          <Box onClick={onDownload}>
            <Box
              className="download"
              onClick={() => {
                event(SegmentEvents.ELEMENT_CLICKED, {
                  page_name: PageName.BeesSyncMonitoringDataChunkDetails,
                  element_location_name: 'Data chunk details payload card',
                  element_location_type: ElementLocationType.Null,
                  element_type: ElementType.Button,
                  element_label: 'Copy Json',
                  element_name: 'ChunkDetailsJson_CopyButton',
                  specific_app: SpecificApp.Monitoring,
                  status,
                  version,
                  entity,
                  action,
                  data_chunk_trace_id: traceId,
                  data_chunk_step: step,
                  data_chunk_source_system: sourceSystem,
                  data_chunk_error_message: errorMessage,
                  data_chunk_payload_present: !!payload,
                });
              }}
            >
              <Box className="circle-download">
                <Download size="medium" />
              </Box>
              <Paragraph size="small" weight="medium">
                {formatMessage({ id: 'MonitoringPage.DOWNLOAD' })}
              </Paragraph>
            </Box>
          </Box>
        </Box>
      </Box>

      {children}
    </Card>
  );
}
export default CardPayload;
