import { styled } from '@hexa-ui/theme';
import { Item } from '@radix-ui/react-select';

export const StyledSelectItem = styled(Item, {
  outline: 'none',
  cursor: 'default',
  borderRadius: '0.25rem',
  '&[data-active-item]': {
    background: '$brandAccentNeutralBackground',
  },
  '&:focus': {
    background: '$brandAccentNeutralBackground',
  },
});

export const StyledDiv = styled('div', {
  marginRight: '5px',
  '[data-testid="checkbox"]': { pointerEvents: 'none' },
});
