import { SkeletonLoader } from '@hexa-ui/components';
import * as Styles from './LoadingKPICard.styles';

export const LoadingSummaryCard = () => {
  return (
    <Styles.Card elevated="medium" border="medium" data-testid="loading-summary-card">
      <Styles.Title>
        <SkeletonLoader width="188px" height="1.125rem" data-testid="skeleton-loader" />
      </Styles.Title>
      <Styles.CardContent>
        <Styles.TargetProgressContainer>
          <SkeletonLoader width="13.625rem" height="1.5rem" data-testid="skeleton-loader" />
          <SkeletonLoader width="13.625rem" height="1.5rem" data-testid="skeleton-loader" />
        </Styles.TargetProgressContainer>
        <Styles.TotalRevenueContainer>
          <SkeletonLoader width="4.313rem" height="1.125rem" data-testid="skeleton-loader" />
          <SkeletonLoader width="4.313rem" height="1.125rem" data-testid="skeleton-loader" />
        </Styles.TotalRevenueContainer>
      </Styles.CardContent>
    </Styles.Card>
  );
};
