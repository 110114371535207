import { createFeatureToggleServiceV2, useAnalyticsService } from 'admin-portal-shared-services';
import { BeesProvider } from 'context/BeesContext/BeesContext';
import DialogProvider from 'context/DialogContext/DialogContext';
import { EnvConfig, EnvProvider, useEnvContext } from 'provider/EnvProvider/EnvProvider';
import ThemeProvider from 'provider/ThemeProvider/ThemeProvider';
import Router from 'routes/Router';
import fullstorySnippet from 'utils/snippets/fullstory';
import { OPTIMIZELY_PROJECT_NAME } from './constants';
import { IntlProvider } from './i18n';
import { setupLocalEnv } from './setupLocal';

import { QueryClient, QueryClientProvider } from 'react-query';

/* istanbul ignore next */
setupLocalEnv();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false
    }
  }
});

export default function App(props: Readonly<EnvConfig>) {
  const { useFullStory, segmentKey, optimizelyKey } = props;
  if (useFullStory) {
    fullstorySnippet();
  }

  useEnvContext();

  useAnalyticsService().load(segmentKey);

  const sdkKey = optimizelyKey;

  createFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME, sdkKey);

  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <BeesProvider>
              <DialogProvider>
                <Router />
              </DialogProvider>
            </BeesProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </IntlProvider>
    </EnvProvider>
  );
}
