import { Card, Heading, Paragraph } from '@hexa-ui/components';
import { Box } from '@material-ui/core';
import { formatMessage } from 'i18n/formatters';
import { colors } from 'themes/palette';

type CardErrorProps = {
  errorMessage?: string;
};

function CardErrorDetails({ errorMessage }: CardErrorProps): JSX.Element {
  return (
    <Card
      data-testid="card-chunk-details"
      border="medium"
      elevated="small"
      style={{ padding: '24px' }}
    >
      <Heading size="H4" style={{ marginBottom: '8px' }}>
        {formatMessage({ id: 'MonitoringPage.ERROR_DETAILS' })}
      </Heading>
      <Box>
        <span className="text-primary">
          {formatMessage({ id: 'MonitoringPage.ERROR_MESSAGE' })}
        </span>
        <Paragraph
          size="small"
          css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
        >
          {errorMessage}
        </Paragraph>
      </Box>
    </Card>
  );
}
export default CardErrorDetails;
