import { useIntl } from 'react-intl';
import { useProgressInfo } from './hooks';
import LoadingRewardCard from './LoadingRewardCard';
import ProgressValue from './ProgressValue';
import {
  StyledRewardCard,
  StyledRewardCardContent,
  StyledRewardCardTitle,
  StyledSupportMessage,
  TargetProgressContainer,
  TotalRevenueContainer,
} from './RewardCard.styles';
import SupportInfo from './SupportInfo';
import TotalRevenue from './TotalRevenue';

type ProgressInfoProps = {
  progressValue: number;
  lastProgressValue: number;
  historyStatus?: boolean;
};

type RewardCardProps = {
  isLoading?: boolean;
  locale: string;
  accepted?: boolean;
  progress: ProgressInfoProps;
  revenue: number;
  country?: string;
  allKpisAchieved?: boolean;
  isAnyKpiStarted?: boolean;
  toggles?: {
    showToEarnValue?: boolean;
  };
};

export const RewardCard = ({
  isLoading,
  locale,
  accepted,
  progress,
  revenue,
  country,
  allKpisAchieved,
  isAnyKpiStarted,
  toggles,
}: RewardCardProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const { progressDifference, historyStatusValue } = useProgressInfo(
    progress.progressValue,
    progress.lastProgressValue
  );

  const { showToEarnValue } = toggles;

  if (isLoading) {
    return <LoadingRewardCard />;
  }

  return (
    <StyledRewardCard data-testid={'reward-card'} border={'medium'} elevated={'medium'}>
      <StyledRewardCardTitle data-testid={'reward-card-title'}>
        {formatMessage({ id: 'rewardCard.title' })}
      </StyledRewardCardTitle>
      <StyledRewardCardContent>
        <TargetProgressContainer>
          <ProgressValue
            accepted={accepted}
            progress={progress.progressValue}
            locale={locale}
            country={country}
            isAnyKpiStarted={isAnyKpiStarted}
          />
          {allKpisAchieved && country === 'AR' ? (
            <StyledSupportMessage>
              {formatMessage({ id: 'rewardCard.targetProgress.allCompleted' })}
            </StyledSupportMessage>
          ) : (
            <SupportInfo
              accepted={accepted}
              support={progressDifference}
              locale={locale}
              historyStatus={progress?.historyStatus ? historyStatusValue : null}
            />
          )}
        </TargetProgressContainer>
        {showToEarnValue ? (
          <TotalRevenueContainer>
            <TotalRevenue accepted={accepted} revenue={revenue} locale={locale} />
          </TotalRevenueContainer>
        ) : null}
      </StyledRewardCardContent>
    </StyledRewardCard>
  );
};
