import {
  StyledRewardCard,
  StyledRewardCardTitle,
  StyledRewardCardContent,
  TargetProgressContainer,
  TotalRevenueContainer,
} from './LoadingRewardCard.styles';
import { SkeletonLoader } from '@hexa-ui/components';

const LoadingRewardCard = (): JSX.Element | null => {
  return (
    <StyledRewardCard data-testid="loading-reward-card" border={'medium'} elevated={'medium'}>
      <StyledRewardCardTitle>
        <SkeletonLoader width="188px" height="1.125rem" />
      </StyledRewardCardTitle>
      <StyledRewardCardContent>
        <TargetProgressContainer>
          <SkeletonLoader width="13.625rem" height="1.5rem" />
          <SkeletonLoader width="13.625rem" height="1.5rem" />
        </TargetProgressContainer>
        <TotalRevenueContainer>
          <SkeletonLoader width="4.313rem" height="1.125rem" />
          <SkeletonLoader width="4.313rem" height="1.125rem" />
        </TotalRevenueContainer>
      </StyledRewardCardContent>
    </StyledRewardCard>
  );
};

export default LoadingRewardCard;
