import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { abbreviateNumber } from '../../../utils/formmattedNumbers';
import { getCurrencyByLocale } from '../../NumberDisplay';
import { ArrowDownIcon, ArrowUpIcon, SupportInfoContainer } from './SupportInfo.styles';

type SupportInfoProps = {
  accepted?: boolean;
  support: number;
  locale: string;
  historyStatus?: string | null;
};

type SupportValueState = {
  suffix: string;
  abbreviatedNumber: string;
};

const INITIAL_VALUE = '0';

const SupportInfo = ({
  accepted,
  support,
  locale,
  historyStatus,
}: SupportInfoProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const [supportValue, setSupportValue] = useState<SupportValueState>({
    suffix: '',
    abbreviatedNumber: INITIAL_VALUE,
  });

  useEffect(() => {
    if (support) {
      try {
        const supportValueConverted = abbreviateNumber({ value: support });
        setSupportValue(supportValueConverted);
      } catch (error) {
        console.error('Failed to abbreviate number:', error);
        setSupportValue({ suffix: '', abbreviatedNumber: INITIAL_VALUE });
      }
    } else {
      setSupportValue({ suffix: '', abbreviatedNumber: INITIAL_VALUE });
    }
  }, [support]);

  const renderSupportInfoContainer = ({ icon, text, color, className }) => (
    <SupportInfoContainer data-testid={className} className={className} style={{ color: color }}>
      {icon}
      {`${getCurrencyByLocale(locale)} ${supportValue.abbreviatedNumber} ${
        supportValue.suffix
      } ${text}`}
    </SupportInfoContainer>
  );

  const renderSupportInfo = () => {
    const statusToClass = {
      increase: 'support-value-increase',
      decrease: 'support-value-decrease',
      neutral: 'support-value-neutral',
    };
    const className = statusToClass[historyStatus] || 'support-value-neutral';

    let icon = null;
    let text = '';
    let color = '';

    switch (historyStatus) {
      case 'increase':
        icon = <ArrowUpIcon size="tiny" fr="" />;
        text = `${formatMessage({ id: 'rewardCard.targetProgress.supportInfo.increase' })}`;
        color = 'green';
        break;
      case 'decrease':
        icon = <ArrowDownIcon size="tiny" fr="" />;
        text = `${formatMessage({ id: 'rewardCard.targetProgress.supportInfo.decrease' })}`;
        color = 'red';
        break;
      case 'neutral':
        text = `${formatMessage({ id: 'rewardCard.targetProgress.supportInfo.decrease' })}`;
        break;
      default:
        break;
    }

    return historyStatus === null ? (
      <SupportInfoContainer data-testid={'no-support-info'} />
    ) : (
      renderSupportInfoContainer({ icon, text, color, className })
    );
  };

  return accepted ? renderSupportInfo() : null;
};

export default React.memo(SupportInfo);
