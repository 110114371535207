/* eslint-disable @typescript-eslint/no-empty-function */
import { Paragraph, SearchField, TextLink } from '@hexa-ui/components';
import { Store2 } from '@hexa-ui/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Root as SelectRoot } from './Select';
import * as Styles from './SelectWithSearch.styles';

type Account = {
  name?: string;
  accountId?: string;
  clientName?: string;
  clientId?: string;
  vendorId?: string;
};

type DefaultStoreState = {
  name: string;
  accountId: string;
  vendorId?: string;
  hasFilter?: boolean;
};

type SelectWithSearchProps = {
  isLoading?: boolean;
  accounts: Account[];
  isChainLevel?: boolean;
  defaultAccount: Account;
  onAccountChange?: () => void;
  defaultStoreState: DefaultStoreState;
  setDefaultStoreState: (value: DefaultStoreState) => void;
};

export const SelectWithSearch = ({
  isLoading,
  accounts,
  isChainLevel = true,
  defaultAccount,
  onAccountChange,
  defaultStoreState: defaultStore,
  setDefaultStoreState: setDefaultStore,
}: SelectWithSearchProps) => {
  const { formatMessage } = useIntl();
  const [selectedAccount, setSelectedAccount] = useState(defaultAccount);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLButtonElement>(null);
  const [click, setClick] = useState(0);
  const [showChainDefault, setShowChainDefault] = useState(true);

  const showAllStores = isChainLevel && showChainDefault && !defaultStore.hasFilter;

  const filteredList = useMemo(
    () => {
      if (isChainLevel) {
        if (searchTerm.length > 2) {
          return accounts
            .filter(
              (term) =>
                term.name.toLowerCase().includes(searchTerm) || term.accountId.includes(searchTerm)
            )
            .sort((a, b) => a.name.localeCompare(b.name));
        } else {
          return accounts.sort((a, b) => a.name.localeCompare(b.name));
        }
      } else {
        if (searchTerm.length > 2) {
          return accounts
            .filter(
              (term) =>
                term.clientName.toLowerCase().includes(searchTerm) ||
                term.clientId.includes(searchTerm)
            )
            .sort((a, b) => a.clientName.localeCompare(b.clientName));
        } else {
          return accounts.sort((a, b) => a.clientName.localeCompare(b.clientName));
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, accounts]
  );

  useEffect(() => {}, [selectedAccount, click]);

  const resetSearchTerm = () => {
    setSearchTerm('');
  };

  const handleSearch = (term) => {
    const searchTerm = term.target.value;
    setSearchTerm(searchTerm);
  };

  const onClear = () => {
    resetSearchTerm();
  };

  const loadAgainAllStores = () => {
    setClick((prev) => prev + 1);
    setShowChainDefault(true);
    setSelectedAccount(defaultAccount);
    setDefaultStore({
      name: '',
      accountId: '',
      vendorId: '',
      hasFilter: false,
    });
    onAccountChange();
    resetSearchTerm();
  };

  const handleClick = (account) => {
    setClick((prev) => prev + 1);
    setShowChainDefault(false);
    setSelectedAccount(account);
    setDefaultStore({
      name: isChainLevel ? account.name : account.clientName,
      accountId: isChainLevel ? account.accountId : account.clientId,
      vendorId: account.vendorId,
      hasFilter: true,
    });
    onAccountChange();
  };

  const handleOnClose = () => {
    resetSearchTerm();
  };

  if (isLoading) {
    return null;
  } else {
    return (
      <Styles.Wrapper>
        <SelectRoot
          name="select-with-search"
          shape="pill"
          size="small"
          icon={<Store2 />}
          onBlur={() => searchFieldRef.current?.focus()}
          onClose={handleOnClose}
          ref={selectRef}
          clicked={click}
          value={
            showAllStores
              ? formatMessage({ id: 'components.selectWithSearch.allStores' })
              : formatMessage({ id: 'components.selectWithSearch.storeName' })
                  .replace('%storeName', defaultStore.name)
                  .replace('%storeCode', defaultStore.accountId)
          }
        >
          <Styles.ListWrapper>
            <Styles.SearchFieldWrapper>
              <SearchField.Root
                placeholder={formatMessage({ id: 'components.selectWithSearch.searchPlaceholder' })}
                onChange={(e) => handleSearch(e)}
                onClear={() => onClear()}
                onBlur={() => selectRef.current?.click()}
                autoFocus={true}
                ref={searchFieldRef}
                value={searchTerm}
              />
            </Styles.SearchFieldWrapper>
            {filteredList.length > 0 ? (
              <>
                <Styles.TextLinkWrapper>
                  <TextLink hasUnderline={false} onClick={() => loadAgainAllStores()}>
                    {formatMessage({ id: 'components.selectWithSearch.showAllStores' })}
                  </TextLink>
                </Styles.TextLinkWrapper>
                {filteredList.map((account) => (
                  <Styles.Option
                    key={isChainLevel ? account.accountId : account.clientId}
                    onClick={() => handleClick(account)}
                  >
                    <Styles.Paragraph>
                      {formatMessage({ id: 'components.selectWithSearch.storeName' })
                        .replace('%storeName', isChainLevel ? account.name : account.clientName)
                        .replace('%storeCode', isChainLevel ? account.accountId : account.clientId)}
                    </Styles.Paragraph>
                  </Styles.Option>
                ))}
              </>
            ) : (
              <Styles.NoResultsWrapper>
                <Paragraph size="xsmall" colortype="secondary" alignment="center">
                  {formatMessage({ id: 'components.selectWithSearch.noResults' })}
                </Paragraph>
              </Styles.NoResultsWrapper>
            )}
          </Styles.ListWrapper>
        </SelectRoot>
      </Styles.Wrapper>
    );
  }
};
