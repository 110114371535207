import { withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk';
import { useSidebar } from 'admin-portal-shared-services';
import { RootState } from 'app/store';
import NotFound404 from 'components/NotFound404/NotFound404';
import { FeatureTogglesWithoutOptimizely } from 'consts/featureTogglesMap';
import { setShouldChangePath } from 'features/europeCluster/europeCluster';
import { changeToggleWithoutOptimizely } from 'features/featureToggle/featureToggle';
import useEventPageProps from 'hooks/useEventPageProps';
import { useFeatureToggle } from 'hooks/useFeatureToggle';
import useHandleNavigationEvents from 'hooks/useHandleNavigationEvents';
import { useMenuOptions } from 'hooks/useMenuOptions';
import { useRequestInterceptor } from 'hooks/useRequestInterceptor';
import DataChunkDetails from 'pages/DataChunkDetails/DataChunkDetails';
import MonitoringDetailsPageV2 from 'pages/MonitoringDetails/v2/MonitoringDetailsPageV2';
import React, { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AppContainer, Content, LoadingContainer, LoadingIcon } from './Router.styles';

const WelcomePage = lazy(() => import('./pages/WelcomePage/WelcomePage'));
const DataManagementPage = lazy(() => import('./pages/DataManagement/DataManagementPage'));
const MonitoringPage = lazy(() => import('./pages/Monitoring/MonitoringPage'));
const MonitoringDetailsPage = lazy(() => import('./pages/MonitoringDetails/MonitoringDetailsPage'));
const PayloadValidatorPage = lazy(() => import('./pages/PayloadValidator/PayloadValidatorPage'));

export const BASE_URL = '/ingestion';
export const MONITORINGPAGE_URL = `${BASE_URL}/monitoring`;
export const MONITORING_DETAILS_PAGE_URL = `${BASE_URL}/monitoring/:id`;
export const MONITORING_DETAILS_V2_URL = `${BASE_URL}/monitoring/v2/:id`;
export const PAYLOADVALIDATORPAGE_URL = `${BASE_URL}/payload-validator`;
export const DATAMANAGEMENTPAGE_URL = `${BASE_URL}/data-management`;
export const DATAMANAGEMENTPAGE_TAB_URL = `${BASE_URL}/data-management/:tab`;
export const DATA_CHUNK_DETAILS = `${BASE_URL}/monitoring/chunk-details/:id`;
interface RouterProps extends WithOptimizelyProps {
  payloadValidator: boolean;
}

function RouterWithoutOptimizely({ optimizely, payloadValidator }: RouterProps): JSX.Element {
  const dispatch = useDispatch();
  const featureToggles = useSelector((state: RootState) => state.featureToggle);

  useFeatureToggle(optimizely);
  useRequestInterceptor();
  useHandleNavigationEvents();
  useEventPageProps();

  const { menuItems, menuUtilitiesItems } = useMenuOptions();

  useSidebar({
    items: menuItems,
    utils: menuUtilitiesItems,
  });

  React.useEffect(() => {
    if (!featureToggles.isSomethingLoading) {
      dispatch(setShouldChangePath(featureToggles.europeCluster));
    }
  }, [featureToggles.isSomethingLoading]);

  React.useEffect(() => {
    dispatch(
      changeToggleWithoutOptimizely({
        toggleKey: FeatureTogglesWithoutOptimizely.BEES_SYNC_PAYLOAD_VALIDATOR,
        toggleValue: payloadValidator,
      })
    );
  }, [payloadValidator]);

  function renderLoading() {
    return (
      <LoadingContainer data-testid="transition-spinner">
        <LoadingIcon />
      </LoadingContainer>
    );
  }

  return (
    <AppContainer id="dataIngestionContainer">
      <Suspense fallback={renderLoading()}>
        {!featureToggles.isSomethingLoading ? (
          <Content id="dataIngestionMainContentArea">
            <Switch>
              <Route path={BASE_URL} component={WelcomePage} exact />
              <Route path={DATAMANAGEMENTPAGE_URL} component={DataManagementPage} exact />
              <Route path={DATAMANAGEMENTPAGE_TAB_URL} component={DataManagementPage} exact />
              <Route path={MONITORINGPAGE_URL} component={MonitoringPage} exact />
              <Route path={MONITORING_DETAILS_PAGE_URL} component={MonitoringDetailsPage} exact />
              <Route path={MONITORING_DETAILS_V2_URL} component={MonitoringDetailsPageV2} exact />
              <Route path={DATA_CHUNK_DETAILS} component={DataChunkDetails} exact />
              {featureToggles.payloadValidator && (
                <Route path={PAYLOADVALIDATORPAGE_URL} component={PayloadValidatorPage} exact />
              )}
              <Route path="/" exact />
              <Route path="*">
                <NotFound404 />
              </Route>
            </Switch>
          </Content>
        ) : (
          renderLoading()
        )}
      </Suspense>
    </AppContainer>
  );
}

const Router = withOptimizely<RouterProps, unknown>(RouterWithoutOptimizely);

export default Router;
