export enum SourceSystem {
  ETL = 'ETL',
  RELAY = 'GENERIC_RELAY',
  ADAPTER = 'DATA_ADAPTER_SERVICE',
}

export enum Steps {
  DATA_ENTRY = 'DATA_ENTRY',
  ADHERENCE_VALIDATION = 'ADHERENCE_VALIDATION',
  DATA_DISTRIBUTION = 'DATA_DISTRIBUTION',
  CONFIRMATION = 'CONFIRMATION',
}

export enum Status {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum CustomStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}
