import { styled } from '@hexa-ui/theme';

export const RevenueValueContainer = styled('div', {
  textAlign: 'right',
  color: 'green',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '1.25rem',
  fontFamily: 'var(--fonts-paragraph)',
  minWidth: '4.336rem',
});

export const RevenueTextContainer = styled('div', {
  textAlign: 'right',
  fontSize: '0.75rem',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '1.125rem',
  fontFamily: 'var(--fonts-paragraph)',
});
