import { Calendar } from '@hexa-ui/components';
import * as Icon from '@hexa-ui/icons';
import MaskedInput from 'components/MaskedInput/MaskedInput';
import { format, isValid, parse, startOfDay } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import useStyles, { Container, InputContainer } from './CalendarPicker.styles';

interface IDatePicker {
  date?: Date;
  setStartDate: (value: Date) => void;
  disabled?: boolean;
  required?: boolean;
  minDateAllowed?: Date;
  maxDateAllowed?: Date;
  onRawChange?: (value: Date) => void;
}

export default function CalendarPicker({
  date = new Date(),
  setStartDate,
  disabled,
  required = false,
  minDateAllowed,
  maxDateAllowed,
  onRawChange,
}: IDatePicker): JSX.Element {
  const [selected, setSelected] = useState<Date>();
  const [inputValue, setInputValue] = useState<string>(format(date, 'MM/dd/y'));

  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  const { calendar, calendarIcon, calendarInput } = useStyles();

  useEffect(() => {
    setInputValue(format(date, 'MM/dd/y'));
  }, [date]);

  const closePopper = () => {
    setIsPopperOpen(false);
    inputRef?.current?.focus();
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.currentTarget.value);
    const inputDate = parse(e.currentTarget.value, 'MM/dd/y', new Date());
    if (onRawChange) onRawChange(inputDate);

    /* istanbul ignore else */
    if (isValid(inputDate) && inputDate <= new Date()) {
      if (minDateAllowed && inputDate < startOfDay(minDateAllowed)) return;
      setSelected(inputDate);
      setStartDate(inputDate);
    }
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);

    inputRef?.current?.focus();
  };

  const handleDaySelect = (selectedDate: Date) => {
    setSelected(selectedDate);
    /* istanbul ignore else */
    if (selectedDate) {
      setStartDate(selectedDate);
      setInputValue(format(selectedDate, 'MM/dd/y'));
      closePopper();
    }
  };

  return (
    <Container data-testid="datepicker-calendar-start">
      <InputContainer ref={popperRef}>
        <MaskedInput
          inputTestId="calendar-input"
          variant="outlined"
          style={{ height: 40 }}
          className={calendarInput}
          customPlaceholder={`${format(new Date(), 'MM/dd/y')}`}
          mask="99/99/9999"
          value={inputValue}
          date
          onChange={handleInputChange}
          newLayout
          disabled={disabled}
          required={required}
          suffix={
            <Icon.Calendar
              data-testid="calendar-button"
              onClick={handleButtonClick}
              className={calendarIcon}
              size="large"
            />
          }
        />
      </InputContainer>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <Calendar
              data-testid="datepicker-calendar"
              className={calendar}
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={selected}
              today={
                isValid(parse(inputValue, 'MM/dd/y', new Date())) ? new Date(inputValue) : undefined
              }
              toDate={maxDateAllowed ?? new Date()}
              onChange={(value) => handleDaySelect(value as Date)}
              fromDate={minDateAllowed}
            />
          </div>
        </FocusTrap>
      )}
    </Container>
  );
}
