import { Heading } from '@hexa-ui/components';
import { ArrowLeft } from '@hexa-ui/icons';
import { Box } from '@material-ui/core';
import Badge from 'components/Badge/Badge';
import { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { MONITORINGPAGE_URL } from 'Router';
import { hasPageInHistory, HistoryRoute } from '../../FilterContainer/utils';
import './HeaderStatus.css';

type BadgeProps = {
  header: string;
  badgeStatus?: IndicatorEnum;
  previousPageName: HistoryRoute;
};

export enum IndicatorEnum {
  FAILED = 'FAILED',
  CONCLUDED_WITH_FAILURES = 'CONCLUDED_WITH_FAILURES',
  CONCLUDED = 'CONCLUDED',
  PROCESSING = 'PROCESSING',
}

function HeaderStatus({ header, badgeStatus, previousPageName }: BadgeProps): JSX.Element {
  const history = useHistory();
  const historyBack = (evt: unknown) => {
    const event = evt as MouseEvent<HTMLAnchorElement>;
    event.stopPropagation();
    event.preventDefault();
    if (hasPageInHistory(previousPageName)) {
      history.goBack();
    } else {
      history.push(MONITORINGPAGE_URL);
    }
  };

  return (
    <Box className="wrapper-title" data-testid="header-status">
      <Box data-testid="back" className="circle" onClick={historyBack}>
        <ArrowLeft size="large" />
      </Box>
      <Heading size="H2" style={{ marginRight: '16px' }}>
        {header}
      </Heading>
      <Badge altColors status={badgeStatus as IndicatorEnum} />
    </Box>
  );
}
export default HeaderStatus;
