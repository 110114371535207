import { Tooltip } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { abbreviateNumber } from '../../../utils/formmattedNumbers';
import { getCurrencyByLocale, numberFormat } from '../../NumberDisplay';
import {
  EmptyProgressContainer,
  HeadingCard,
  ProgressValueContainer,
} from './ProgressValue.styles';

type ProgressValueProps = {
  accepted?: boolean;
  progress?: number;
  locale: string;
  country?: string;
  isAnyKpiStarted?: boolean;
};
type ProgressValueState = {
  suffix: string;
  abbreviatedNumber: string;
};
const INITIAL_VALUE = '0';

const ProgressValue = ({
  accepted,
  progress,
  locale,
  country,
  isAnyKpiStarted,
}: ProgressValueProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const [progressValue, setProgressValue] = useState<ProgressValueState>({
    suffix: '',
    abbreviatedNumber: INITIAL_VALUE,
  });

  useEffect(() => {
    if (progress) {
      try {
        const progressValueConverted = abbreviateNumber({ value: progress });
        setProgressValue(progressValueConverted);
      } catch (error) {
        console.error('Failed to abbreviate number:', error);
        setProgressValue({ suffix: '', abbreviatedNumber: INITIAL_VALUE });
      }
    } else {
      setProgressValue({ suffix: '', abbreviatedNumber: INITIAL_VALUE });
    }
  }, [progress]);

  if ((country === 'AR' && !isAnyKpiStarted) || !accepted) {
    return (
      <EmptyProgressContainer className="empty-progress">
        {formatMessage({ id: 'rewardCard.targetProgress.emptyState' })}
      </EmptyProgressContainer>
    );
  }

  return accepted ? (
    <ProgressValueContainer data-testid="progress-value">
      <HeadingCard size="H2">
        {progressValue.suffix === '' ? (
          `${numberFormat({
            value: Number(progressValue.abbreviatedNumber),
            locale,
            style: 'currency',
          })}`
        ) : (
          <Tooltip
            placement="top"
            text={`${numberFormat({ value: progress, locale, style: 'currency' })}`}
          >
            {locale === 'CA'
              ? `${progressValue.abbreviatedNumber} ${progressValue.suffix} ${getCurrencyByLocale(
                  locale
                )}`
              : `${getCurrencyByLocale(locale)} ${progressValue.abbreviatedNumber} ${
                  progressValue.suffix
                }`}
          </Tooltip>
        )}
      </HeadingCard>
    </ProgressValueContainer>
  ) : (
    <EmptyProgressContainer data-testid="empty-progress">
      {formatMessage({ id: 'rewardCard.targetProgress.emptyState' })}
    </EmptyProgressContainer>
  );
};

export default ProgressValue;
