/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppHeader, usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { get as getApi, put as putApi } from '../../api';
import { TDriverResponse, TUpdateDriverProgress } from './DriverService.types';

export const DRIVER_BASE_URL = `api/link-commercial-kpis-service/driver`;

export const useDriverService = () => {
  const { preferredLanguage } = usePreferredLanguageV2();
  const [appHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;

  const get = (service: 'measure' | 'type' | 'category' | 'group', chainId = '') => {
    const {
      refetch,
      data = {
        content: [],
      },
    } = useQuery<TDriverResponse>({
      queryKey: [`driver/${service}`, chainId],
      queryFn: () => {
        const url = `${DRIVER_BASE_URL}/${service}?chainId=${chainId}`;

        return getApi({
          url,
          config: {
            headers: {
              preferredLanguage,
              country: selectedCountry,
            },
          },
        });
      },
    });
    return { refetch, data: data.content };
  };

  const put = () =>
    useMutation({
      mutationFn: (body: TUpdateDriverProgress) =>
        putApi({ url: `${DRIVER_BASE_URL}/progress`, body }),
    });

  return {
    get,
    put,
  };
};
