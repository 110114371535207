import { ListItem } from '@hexa-ui/components';
import { Check } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import { ItemText } from '@radix-ui/react-select';
import React, { useEffect, useRef } from 'react';
import { StyledDiv, StyledSelectItem } from './SelectOption.styles';

export interface SelectOptionProps {
  value: string;
  children: React.ReactNode;
  multiple?: boolean;
  selectedValues?: string | string[];
  disabled?: boolean;
  onMultipleChange?: (label: string) => void;
}

const SelectOption = ({
  value,
  children,
  multiple = false,
  selectedValues,
  disabled = false,
  onMultipleChange,
  ...restProps
}: SelectOptionProps): JSX.Element => {
  const isSelected = selectedValues?.includes(value);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedValues && Array.isArray(selectedValues)) {
      if (selectedValues.length > 0) {
        if (selectedValues[0] === value && onMultipleChange) {
          onMultipleChange(ref?.current?.textContent ?? '');
        }
      }
    }
  }, [onMultipleChange, ref, selectedValues, value]);

  return (
    <StyledSelectItem
      disabled={disabled}
      value={value}
      data-testid={(restProps as Record<string, unknown>)['data-testid']}
    >
      <ListItem disabled={disabled} variant="selectable" spacing="medium">
        <div ref={ref} style={{ display: 'flex', alignItems: 'center' }}>
          {multiple && (
            <StyledDiv>
              <StyledCheckbox disabled={disabled} data-state={isSelected ? 'checked' : ''}>
                <StyledCheckedIcon size="medium" />
              </StyledCheckbox>
            </StyledDiv>
          )}
          {typeof children === 'string' ? <ItemText>{children}</ItemText> : children}
        </div>
      </ListItem>
    </StyledSelectItem>
  );
};

export default SelectOption;

const StyledCheckbox = styled('div', {
  all: 'unset',
  backgroundColor: '$neutral0',
  width: 20,
  height: 20,
  borderRadius: '6px',
  display: 'flex',
  border: '1px solid',
  borderColor: '$interfaceLabelSecondary',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  boxSizing: 'border-box',
  '&:hover, &:focus-visible': {
    border: '2px solid',
    borderColor: '$interfaceLabelPrimary',
    '&[data-state=checked], &[data-state=indeterminate]': {
      border: '1px solid',
      borderColor: '$interfaceForegroundLabelPrimary',
      outline: '1px solid $interfaceLabelPrimary',
    },
  },
  '&[data-state=checked], &[data-state=indeterminate]': {
    backgroundColor: '$interfaceLabelPrimary',
  },
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        borderColor: '$interfaceLabelDisabled',
        '&[data-state=checked], &[data-state=indeterminate]': {
          backgroundColor: '$interfaceLabelDisabled',
          borderColor: 'transparent',
        },
      },
    },
  },
});

const StyledCheckedIcon = styled(Check, {
  color: '#FFF',
  variant: {
    checked: {
      false: {
        display: 'none',
      },
    },
  },
});
