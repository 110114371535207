/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface AcceptedDealsItem {
	/**
	 * The buy in period field, expected values: '10 july 2023 - 30 july 2023'
	 */
	buy_in_period: string | null
	/**
	 * The deal type field, expected values: 'Sell-in'
	 */
	deal_type: string | null
	/**
	 * The display period field, expected values: '10 july 2023 - 30 july 2023'
	 */
	display_period: string | null
	/**
	 * The item name field, expected values: ‘Príncipe Corona Vanilla - 25 Unidades’
	 */
	item_name: string | null
	/**
	 * The item packaging field, expected values: '450.0ML'
	 */
	item_packaging: string | null
	/**
	 * The item retailer price field, expected values: ‘10.99 - 6.99'
	 */
	item_retailer_price: string | null
	/**
	 * The item sku field, expected values: ‘0001779’
	 */
	item_sku: string | null
	/**
	 * The item wholesaler price field, expected values: ‘20.99 - 12.99’
	 */
	item_wholesaler_price: string | null
	/**
	 * The recomposition field, expected values: 1.99
	 */
	recomposition: number | null
}
export interface AcceptDealsClicked {
	/**
	 * The array of Accepted Deals. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	accepted_deals: AcceptedDealsItem[] | null
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * The name of the button displayed to the user. Samples:'Create new contract'
	 */
	button_name: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * Total number of deals accepted by the user.
	 */
	total_deals_accepted: number | null
}
export interface AddAdditionalDriverClicked {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
}
export interface AddChainStarted {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the list type selected by the user to manage. Samples: KAM, GN, Merchandiser
	 */
	list_type: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface BeesCareRequestAccessButtonClick {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ButtonClicked {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * The name of the button displayed to the user. Samples:'Create new contract'
	 */
	button_name: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer A...
	 */
	store_id: string | null
	/**
	 * The url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to. Samples: https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html
	 */
	url: string | null
	/**
	 * The value stream of the event. It must be an ENUM.
	 */
	value_stream: string
}
export interface CancelContractCanceled {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
}
export interface CancelContractConfirmed {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * The identification number of the contract.
	 */
	contract_number: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
}
export interface CancelContractStarted {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * The identification number of the contract.
	 */
	contract_number: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
}
export interface ChainAddingCanceled {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
}
export interface ChainsItem {
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
}
export interface ChainAddingConfirmed {
	/**
	 * The array of chains. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	chains: ChainsItem[] | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
	/**
	 * The number of chains added to the KAM's catalog.
	 */
	quantity_added: number | null
}
export interface ChainRemovingCanceled {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
}
export interface ChainRemovingConfirmed {
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ChainRemovingStarted {
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ChainSearched {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The total number of registers is shown on the list.
	 */
	quantity_of_registers: number | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The text informed in the search field to make the search for a KAM.
	 */
	search_text: string | null
}
export interface ContractsItem {
	/**
	 * The ID of the contracts related to the selected chain.
	 */
	contract_id: string | null
	/**
	 * The number of the contracts related to the selected chain.
	 */
	contract_number: string | null
}
export interface ChainSelected {
	/**
	 * The ID of the chain selected by the user.
	 */
	chain_id: string | null
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * The array of contracts. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	contracts: ContractsItem[] | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ChainsListViewed {
	/**
	 * The total number of registers is shown on the list.
	 */
	quantity_of_registers: number | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface LeversItem {
	/**
	 * The category of the KPI is displayed on the screen.
	 */
	kpi_category: string | null
	/**
	 * The Communication errors description when a lever dashboard is not loaded due to some error.
	 */
	kpi_failure_reason: string | null
	/**
	 * The Id of the KPI.
	 */
	kpi_id: string | null
	/**
	 * The KPI's successfull status. Sample: Yes or No.
	 */
	kpi_load_success: boolean | null
	/**
	 * The type of KPI that is been shown. E.g. Growth, Trade
	 */
	kpi_type: string | null
}
export interface CommercialAgreementsPageLoaded {
	/**
	 * The name of the agreement the user is accessing. Samples. 'PIN', 'JBP'
	 */
	agreement_type: string | null
	/**
	 * The current date.
	 */
	date: string | null
	/**
	 * The array of levers. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	levers: LeversItem[] | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer A...
	 */
	store_id: string | null
	/**
	 * The value stream of the event. It must be an ENUM.
	 */
	value_stream: string
}
export interface ContractCreationCanceled {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ContractDetailsPageViewed {
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * The identification number of the contract.
	 */
	contract_number: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The total number of drivers added to the contract.
	 */
	quantity_of_drivers: number | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ContractDriversStepViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ContractGeneralInformationStepViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ContractManagementInitialPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ContractManagementStarted {
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ContractsManagementPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface CreateDealsClicked {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * The name of the button displayed to the user. Samples:'Create new contract'
	 */
	button_name: string | null
	/**
	 * Total number of deals with errors.
	 */
	deals_with_errors: number
	/**
	 * Name of the file being uploaded. Samples: 'PO 123'.
	 */
	file_name: string | null
	/**
	 * What is the type of file that is being uploaded. Samples: 'IMAGE', 'PDF', 'XLS'
	 */
	file_type: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The time stamp to when the user started this
	 */
	time_stamp: string | null
	/**
	 * Total number of deals read
	 */
	total_deals_checked: number | null
}
export interface CreateDealsPageViewed {
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The time stamp to when the user started this
	 */
	time_stamp: string | null
}
export interface CreateNewContractStarted {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface CustomerListInitialPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface CustomerListProfileSelected {
	/**
	 * What is the list type selected by the user to manage. Samples: KAM, GN, Merchandiser
	 */
	list_type: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface CustomerListStarted {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface DeleteDriverCanceled {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
}
export interface DeleteDriverConfirmed {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The measurement type of the driver.
	 */
	driver_measurement: string | null
	/**
	 * The name of the driver.
	 */
	driver_name: string | null
	/**
	 * The type of the driver.
	 */
	driver_type: string | null
}
export interface DeleteDriverStarted {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
}
export interface DeliveryDetailsClicked {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface DeliveryHistoryClicked {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface DeliverySkUsViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
}
export interface DownloadTemplateClicked {
	/**
	 * The name of the agreement the user is accessing. Samples. 'PIN', 'JBP'
	 */
	agreement_type: string | null
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * The dowload sucessfull status. Sample: Yes or No
	 */
	downloaded_successfull: boolean | null
	/**
	 * The error description of why the file wasn't downloaded (when applicable).
	 */
	failure_reason: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface DriverModalClosed {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface DriverProgressSaved {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * The identification number of the contract.
	 */
	contract_number: string | null
	/**
	 * The name of the driver.
	 */
	driver_name: string | null
	/**
	 * The type of the driver.
	 */
	driver_type: string | null
	/**
	 * The new progress value set by the user.
	 */
	new_progress: string | null
	/**
	 * The old progress was set to the driver before the user updated it.
	 */
	old_progress: string | null
}
export interface EditContractStarted {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * The identification number of the contract.
	 */
	contract_number: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
}
export interface EditDriverCanceled {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
}
export interface EditDriverCompleted {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
}
export interface EditDriverStarted {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
}
export interface ExportErrorsClicked {
	/**
	 * The name of the agreement the user is accessing. Samples. 'PIN', 'JBP'
	 */
	agreement_type: string | null
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * The dowload sucessfull status. Sample: Yes or No
	 */
	downloaded_successfull: boolean | null
	/**
	 * The error description of why the file wasn't downloaded (when applicable).
	 */
	failure_reason: string | null
	/**
	 * The size of the file that is being processed on the upload. E.g.: 15mb
	 */
	file_size: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface FileSelected {
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface FinishUploadClicked {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface GoToBeesCareButtonClicked {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface HomePageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface InformationCenterViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface JbpVisibilityPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface JbpVisibilityStarted {
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface KamDealsPageViewed {
	/**
	 * POC ID associated with user. Samples: \"0000442254\”
	 */
	poc_id: string | null
	/**
	 * The name of the POC. The name field from the Accounts Microservice. Samples: 'Bar da Maria'
	 */
	poc_name: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The time stamp to when the user started this
	 */
	time_stamp: string | null
}
export interface KamSelected {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
}
export interface KaMsCustomersListPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The e-mail address of the selected KAM by the user.
	 */
	kam_email: string | null
	/**
	 * The name of the KAM selected by the user.
	 */
	kam_name: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface KaMsListPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface KpiAccepted {
	/**
	 * The ID of the chain selected by the user.
	 */
	chain_id: string | null
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * The category of the KPI that the user interacted with.
	 */
	kpi_category: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer A...
	 */
	store_id: string | null
	/**
	 * The value stream of the event. It must be an ENUM.
	 */
	value_stream: string
}
export interface KpiInteraction {
	/**
	 * The ID of the chain selected by the user.
	 */
	chain_id: string | null
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * The category of the KPI that the user interacted with.
	 */
	kpi_category: string | null
	/**
	 * The type of the KPI that the user interacted with. E.g.: Growth; Trade
	 */
	kpi_type: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer A...
	 */
	store_id: string | null
	/**
	 * The value stream of the event. It must be an ENUM.
	 */
	value_stream: string
}
export interface KpIsPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface MenuInteraction {
	/**
	 * The top-level menu category that the user interacted with. Samples: Management, Visibility
	 */
	menu_category: string | null
}
export interface NewContractSaved {
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * The end date of the contract duration.
	 */
	contract_end_date: string | null
	/**
	 * The identification number of the contract.
	 */
	contract_number: string | null
	/**
	 * The start date of the contract duration.
	 */
	contract_start_date: string | null
	/**
	 * The total number of drivers added to the contract.
	 */
	quantity_of_drivers: number | null
}
export interface DrivesItem {
	/**
	 * The type of measurement of the driver was added to the contract.
	 */
	driver_measurement: string | null
	/**
	 * The name of the driver was added to the contract.
	 */
	driver_name: string | null
	/**
	 * The type of driver was added to the contract. E.g.: Sell-in, Trade, Sell-out.
	 */
	driver_type: string | null
}
export interface NewDriverSaved {
	/**
	 * The array of drives. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	drives: DrivesItem[] | null
	/**
	 * The total number of drivers added to the contract.
	 */
	quantity_of_drivers: number | null
}
export interface NewDriverStarted {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
}
export interface OrderDetailsClicked {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface PageViewed {
	/**
	 * the status of the page. Sample successful, error, empty
	 */
	page_status?: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer A...
	 */
	store_id: string | null
	/**
	 * The url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to. Samples: https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html
	 */
	url: string | null
	/**
	 * The value stream of the event. It must be an ENUM.
	 */
	value_stream: string
}
export interface PeriodInteraction {
	/**
	 * The selected period by the user filter. E.g: Aug/2023
	 */
	period_selected: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface ReturnStepClicked {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
}
export interface SearchChainsInteraction {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The total number of registers is shown on the list.
	 */
	quantity_of_registers: number | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The text informed in the search field to make the search for a KAM.
	 */
	search_text: string | null
}
export interface SearchContractInteraction {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The total number of registers is shown on the list.
	 */
	quantity_of_registers: number | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The text informed in the search field to make the search for a KAM.
	 */
	search_text: string | null
}
export interface SearchKamInteraction {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The total number of registers is shown on the list.
	 */
	quantity_of_registers: number | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The text informed in the search field to make the search for a KAM.
	 */
	search_text: string | null
}
export interface StoreSearched {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The total number of registers is shown on the list.
	 */
	quantity_of_registers: number | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The text informed in the search field to make the search for a KAM.
	 */
	search_text: string | null
}
export interface StoreSelected {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
	/**
	 * The name of the store that was selected by the user on the store’s list
	 */
	store_name: string | null
}
export interface TicketsTabAccessed {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface UpdateDriverProgressCanceled {
	/**
	 * The button label displayed to the user. Samples:'Add chain'
	 */
	button_label: string | null
	/**
	 * The identification number of the contract.
	 */
	contract_number: string | null
	/**
	 * The name of the driver.
	 */
	driver_name: string | null
}
export interface UpdateDriverProgressStarted {
	/**
	 * The name of the chain selected by the user.
	 */
	chain_name: string | null
	/**
	 * The identification number of the contract.
	 */
	contract_number: string | null
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * The measurement type of the driver.
	 */
	driver_measurement: string | null
	/**
	 * The name of the driver.
	 */
	driver_name: string | null
	/**
	 * The type of the driver.
	 */
	driver_type: string | null
}
export interface UpdateKpIsStarted {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface UploadCompleted {
	/**
	 * The number of valid chains identified in the file during the upload.
	 */
	chains_found: number | null
	/**
	 * The number of valid contracts identified in the file during the upload.
	 */
	contracts_found: number | null
	/**
	 * The format of the file that is being processed on the upload. E.g.: '.csv', '.xlsx'
	 */
	file_format: string | null
	/**
	 * Name of the file being uploaded. Samples: 'PO 123'.
	 */
	file_name: string | null
	/**
	 * The size of the file that is being processed on the upload. E.g.: 15mb
	 */
	file_size: string | null
	/**
	 * The number of lines with inconsistencies that have been identified during the upload.
	 */
	inconsistencies: number | null
	/**
	 * The number of KPIs that have been updated by the upload.
	 */
	kpis_updated: number | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface UploadProcessingStarted {
	/**
	 * The error description of why the file wasn't uploaded. E.g.: 'Wrong file format', 'File size limit exceeded', 'Upload failed'
	 */
	error_reason: string | null
	/**
	 * The format of the file that is being processed on the upload. E.g.: '.csv', '.xlsx'
	 */
	file_format: string | null
	/**
	 * Name of the file being uploaded. Samples: 'PO 123'.
	 */
	file_name: string | null
	/**
	 * The size of the file that is being processed on the upload. E.g.: 15mb
	 */
	file_size: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}
export interface UploadSpreadsheetPageViewed {
	/**
	 * What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
	 */
	country: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen.
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
	 */
	screen_name: string | null
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef AcceptedDealsItem
 * @property {string | null} buy_in_period - The buy in period field, expected values: '10 july 2023 - 30 july 2023'
 * @property {string | null} deal_type - The deal type field, expected values: 'Sell-in'
 * @property {string | null} display_period - The display period field, expected values: '10 july 2023 - 30 july 2023'
 * @property {string | null} item_name - The item name field, expected values: ‘Príncipe Corona Vanilla - 25 Unidades’
 * @property {string | null} item_packaging - The item packaging field, expected values: '450.0ML'
 * @property {string | null} item_retailer_price - The item retailer price field, expected values: ‘10.99 - 6.99'
 * @property {string | null} item_sku - The item sku field, expected values: ‘0001779’
 * @property {string | null} item_wholesaler_price - The item wholesaler price field, expected values: ‘20.99 - 12.99’
 * @property {number | null} recomposition - The recomposition field, expected values: 1.99
 */
/**
 * @typedef AcceptDealsClicked
 * @property {AcceptedDealsItem[] | null} accepted_deals - The array of Accepted Deals. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} button_name - The name of the button displayed to the user. Samples:'Create new contract'
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {number | null} total_deals_accepted - Total number of deals accepted by the user.
 */
/**
 * @typedef AddAdditionalDriverClicked
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 */
/**
 * @typedef AddChainStarted
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} list_type - What is the list type selected by the user to manage. Samples: KAM, GN, Merchandiser
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef BeesCareRequestAccessButtonClick
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ButtonClicked
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} button_name - The name of the button displayed to the user. Samples:'Create new contract'
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} store_id - Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer A...
 * @property {string | null} url - The url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to. Samples: https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html
 * @property {string} value_stream - The value stream of the event. It must be an ENUM.
 */
/**
 * @typedef CancelContractCanceled
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 */
/**
 * @typedef CancelContractConfirmed
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} contract_number - The identification number of the contract.
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 */
/**
 * @typedef CancelContractStarted
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} contract_number - The identification number of the contract.
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 */
/**
 * @typedef ChainAddingCanceled
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 */
/**
 * @typedef ChainsItem
 * @property {string | null} chain_name - The name of the chain selected by the user.
 */
/**
 * @typedef ChainAddingConfirmed
 * @property {ChainsItem[] | null} chains - The array of chains. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 * @property {number | null} quantity_added - The number of chains added to the KAM's catalog.
 */
/**
 * @typedef ChainRemovingCanceled
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 */
/**
 * @typedef ChainRemovingConfirmed
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ChainRemovingStarted
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ChainSearched
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {number | null} quantity_of_registers - The total number of registers is shown on the list.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} search_text - The text informed in the search field to make the search for a KAM.
 */
/**
 * @typedef ContractsItem
 * @property {string | null} contract_id - The ID of the contracts related to the selected chain.
 * @property {string | null} contract_number - The number of the contracts related to the selected chain.
 */
/**
 * @typedef ChainSelected
 * @property {string | null} chain_id - The ID of the chain selected by the user.
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {ContractsItem[] | null} contracts - The array of contracts. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ChainsListViewed
 * @property {number | null} quantity_of_registers - The total number of registers is shown on the list.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef LeversItem
 * @property {string | null} kpi_category - The category of the KPI is displayed on the screen.
 * @property {string | null} kpi_failure_reason - The Communication errors description when a lever dashboard is not loaded due to some error.
 * @property {string | null} kpi_id - The Id of the KPI.
 * @property {boolean | null} kpi_load_success - The KPI's successfull status. Sample: Yes or No.
 * @property {string | null} kpi_type - The type of KPI that is been shown. E.g. Growth, Trade
 */
/**
 * @typedef CommercialAgreementsPageLoaded
 * @property {string | null} agreement_type - The name of the agreement the user is accessing. Samples. 'PIN', 'JBP'
 * @property {string | null} date - The current date.
 * @property {LeversItem[] | null} levers - The array of levers. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} store_id - Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer A...
 * @property {string} value_stream - The value stream of the event. It must be an ENUM.
 */
/**
 * @typedef ContractCreationCanceled
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ContractDetailsPageViewed
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} contract_number - The identification number of the contract.
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {number | null} quantity_of_drivers - The total number of drivers added to the contract.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ContractDriversStepViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ContractGeneralInformationStepViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ContractManagementInitialPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ContractManagementStarted
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ContractsManagementPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef CreateDealsClicked
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} button_name - The name of the button displayed to the user. Samples:'Create new contract'
 * @property {number} deals_with_errors - Total number of deals with errors.
 * @property {string | null} file_name - Name of the file being uploaded. Samples: 'PO 123'.
 * @property {string | null} file_type - What is the type of file that is being uploaded. Samples: 'IMAGE', 'PDF', 'XLS'
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} time_stamp - The time stamp to when the user started this
 * @property {number | null} total_deals_checked - Total number of deals read
 */
/**
 * @typedef CreateDealsPageViewed
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} time_stamp - The time stamp to when the user started this
 */
/**
 * @typedef CreateNewContractStarted
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef CustomerListInitialPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef CustomerListProfileSelected
 * @property {string | null} list_type - What is the list type selected by the user to manage. Samples: KAM, GN, Merchandiser
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef CustomerListStarted
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef DeleteDriverCanceled
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 */
/**
 * @typedef DeleteDriverConfirmed
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} driver_measurement - The measurement type of the driver.
 * @property {string | null} driver_name - The name of the driver.
 * @property {string | null} driver_type - The type of the driver.
 */
/**
 * @typedef DeleteDriverStarted
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 */
/**
 * @typedef DeliveryDetailsClicked
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef DeliveryHistoryClicked
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef DeliverySkUsViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 */
/**
 * @typedef DownloadTemplateClicked
 * @property {string | null} agreement_type - The name of the agreement the user is accessing. Samples. 'PIN', 'JBP'
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {boolean | null} downloaded_successfull - The dowload sucessfull status. Sample: Yes or No
 * @property {string | null} failure_reason - The error description of why the file wasn't downloaded (when applicable).
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef DriverModalClosed
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef DriverProgressSaved
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} contract_number - The identification number of the contract.
 * @property {string | null} driver_name - The name of the driver.
 * @property {string | null} driver_type - The type of the driver.
 * @property {string | null} new_progress - The new progress value set by the user.
 * @property {string | null} old_progress - The old progress was set to the driver before the user updated it.
 */
/**
 * @typedef EditContractStarted
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} contract_number - The identification number of the contract.
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 */
/**
 * @typedef EditDriverCanceled
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 */
/**
 * @typedef EditDriverCompleted
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 */
/**
 * @typedef EditDriverStarted
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 */
/**
 * @typedef ExportErrorsClicked
 * @property {string | null} agreement_type - The name of the agreement the user is accessing. Samples. 'PIN', 'JBP'
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {boolean | null} downloaded_successfull - The dowload sucessfull status. Sample: Yes or No
 * @property {string | null} failure_reason - The error description of why the file wasn't downloaded (when applicable).
 * @property {string | null} file_size - The size of the file that is being processed on the upload. E.g.: 15mb
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef FileSelected
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef FinishUploadClicked
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef GoToBeesCareButtonClicked
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef HomePageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef InformationCenterViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef JbpVisibilityPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef JbpVisibilityStarted
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef KamDealsPageViewed
 * @property {string | null} poc_id - POC ID associated with user. Samples: \"0000442254\”
 * @property {string | null} poc_name - The name of the POC. The name field from the Accounts Microservice. Samples: 'Bar da Maria'
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} time_stamp - The time stamp to when the user started this
 */
/**
 * @typedef KamSelected
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 */
/**
 * @typedef KaMsCustomersListPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} kam_email - The e-mail address of the selected KAM by the user.
 * @property {string | null} kam_name - The name of the KAM selected by the user.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef KaMsListPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef KpiAccepted
 * @property {string | null} chain_id - The ID of the chain selected by the user.
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} kpi_category - The category of the KPI that the user interacted with.
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} store_id - Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer A...
 * @property {string} value_stream - The value stream of the event. It must be an ENUM.
 */
/**
 * @typedef KpiInteraction
 * @property {string | null} chain_id - The ID of the chain selected by the user.
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} kpi_category - The category of the KPI that the user interacted with.
 * @property {string | null} kpi_type - The type of the KPI that the user interacted with. E.g.: Growth; Trade
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} store_id - Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer A...
 * @property {string} value_stream - The value stream of the event. It must be an ENUM.
 */
/**
 * @typedef KpIsPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef MenuInteraction
 * @property {string | null} menu_category - The top-level menu category that the user interacted with. Samples: Management, Visibility
 */
/**
 * @typedef NewContractSaved
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} contract_end_date - The end date of the contract duration.
 * @property {string | null} contract_number - The identification number of the contract.
 * @property {string | null} contract_start_date - The start date of the contract duration.
 * @property {number | null} quantity_of_drivers - The total number of drivers added to the contract.
 */
/**
 * @typedef DrivesItem
 * @property {string | null} driver_measurement - The type of measurement of the driver was added to the contract.
 * @property {string | null} driver_name - The name of the driver was added to the contract.
 * @property {string | null} driver_type - The type of driver was added to the contract. E.g.: Sell-in, Trade, Sell-out.
 */
/**
 * @typedef NewDriverSaved
 * @property {DrivesItem[] | null} drives - The array of drives. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} quantity_of_drivers - The total number of drivers added to the contract.
 */
/**
 * @typedef NewDriverStarted
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 */
/**
 * @typedef OrderDetailsClicked
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef PageViewed
 * @property {string | null} [page_status] - the status of the page. Sample successful, error, empty
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} store_id - Unique id of the store. Randomly generated, UUID formatted. Each store is a group of different vendors in the Customer A...
 * @property {string | null} url - The url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to. Samples: https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html
 * @property {string} value_stream - The value stream of the event. It must be an ENUM.
 */
/**
 * @typedef PeriodInteraction
 * @property {string | null} period_selected - The selected period by the user filter. E.g: Aug/2023
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef ReturnStepClicked
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 */
/**
 * @typedef SearchChainsInteraction
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {number | null} quantity_of_registers - The total number of registers is shown on the list.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} search_text - The text informed in the search field to make the search for a KAM.
 */
/**
 * @typedef SearchContractInteraction
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {number | null} quantity_of_registers - The total number of registers is shown on the list.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} search_text - The text informed in the search field to make the search for a KAM.
 */
/**
 * @typedef SearchKamInteraction
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {number | null} quantity_of_registers - The total number of registers is shown on the list.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} search_text - The text informed in the search field to make the search for a KAM.
 */
/**
 * @typedef StoreSearched
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {number | null} quantity_of_registers - The total number of registers is shown on the list.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} search_text - The text informed in the search field to make the search for a KAM.
 */
/**
 * @typedef StoreSelected
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 * @property {string | null} store_name - The name of the store that was selected by the user on the store’s list
 */
/**
 * @typedef TicketsTabAccessed
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef UpdateDriverProgressCanceled
 * @property {string | null} button_label - The button label displayed to the user. Samples:'Add chain'
 * @property {string | null} contract_number - The identification number of the contract.
 * @property {string | null} driver_name - The name of the driver.
 */
/**
 * @typedef UpdateDriverProgressStarted
 * @property {string | null} chain_name - The name of the chain selected by the user.
 * @property {string | null} contract_number - The identification number of the contract.
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} driver_measurement - The measurement type of the driver.
 * @property {string | null} driver_name - The name of the driver.
 * @property {string | null} driver_type - The type of the driver.
 */
/**
 * @typedef UpdateKpIsStarted
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef UploadCompleted
 * @property {number | null} chains_found - The number of valid chains identified in the file during the upload.
 * @property {number | null} contracts_found - The number of valid contracts identified in the file during the upload.
 * @property {string | null} file_format - The format of the file that is being processed on the upload. E.g.: '.csv', '.xlsx'
 * @property {string | null} file_name - Name of the file being uploaded. Samples: 'PO 123'.
 * @property {string | null} file_size - The size of the file that is being processed on the upload. E.g.: 15mb
 * @property {number | null} inconsistencies - The number of lines with inconsistencies that have been identified during the upload.
 * @property {number | null} kpis_updated - The number of KPIs that have been updated by the upload.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef UploadProcessingStarted
 * @property {string | null} error_reason - The error description of why the file wasn't uploaded. E.g.: 'Wrong file format', 'File size limit exceeded', 'Upload failed'
 * @property {string | null} file_format - The format of the file that is being processed on the upload. E.g.: '.csv', '.xlsx'
 * @property {string | null} file_name - Name of the file being uploaded. Samples: 'PO 123'.
 * @property {string | null} file_size - The size of the file that is being processed on the upload. E.g.: 15mb
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */
/**
 * @typedef UploadSpreadsheetPageViewed
 * @property {string | null} country - What is the Country which the user is accessing the App information. Samples: Brazil, United States, South Africa
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen.
 * @property {string | null} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Home Screen'
 */

/**
 * When the user hit the button for accept one or all deals
 *
 * @param {AcceptDealsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function acceptDealsClicked(
	props: AcceptDealsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accept Deals Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks on the add additional driver button.

 *
 * @param {AddAdditionalDriverClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addAdditionalDriverClicked(
	props: AddAdditionalDriverClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Additional Driver Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks on the add chain button to manage the customer list of a KAM.



 *
 * @param {AddChainStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addChainStarted(
	props: AddChainStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Chain Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks on the ‘Request access’ button in Link Admin.
 *
 * @param {BeesCareRequestAccessButtonClick} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function beesCareRequestAccessButtonClick(
	props: BeesCareRequestAccessButtonClick,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'BEES Care Request Access Button Click',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be fired whenever the user clicks in a button on the BEES Hub Modern Trade Management
 *
 * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function buttonClicked(
	props: ButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user cancels the cancelation of a contract.
 *
 * @param {CancelContractCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelContractCanceled(
	props: CancelContractCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cancel Contract Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user confirms the cancelation of a contract.



 *
 * @param {CancelContractConfirmed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelContractConfirmed(
	props: CancelContractConfirmed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cancel Contract Confirmed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user starts the cancelation of a contract.



 *
 * @param {CancelContractStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelContractStarted(
	props: CancelContractStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cancel Contract Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects a chain to be added to the KAMs catalog.

 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainAdded(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user cancels the action of adding chains to the catalog.


 *
 * @param {ChainAddingCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainAddingCanceled(
	props: ChainAddingCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Adding Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user confirms the addition of chain to the KAM's catalog.






 *
 * @param {ChainAddingConfirmed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainAddingConfirmed(
	props: ChainAddingConfirmed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Adding Confirmed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user cancels the action of removing a chain from the KAM's catalog.


 *
 * @param {ChainRemovingCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainRemovingCanceled(
	props: ChainRemovingCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Removing Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user confirms the removal of a chain from the KAM's catalog.




 *
 * @param {ChainRemovingConfirmed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainRemovingConfirmed(
	props: ChainRemovingConfirmed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Removing Confirmed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks on the remove button of a chain added in the KAM's catalog.




 *
 * @param {ChainRemovingStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainRemovingStarted(
	props: ChainRemovingStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Removing Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user makes a search for chains by the search bar
 *
 * @param {ChainSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainSearched(
	props: ChainSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects a contract to see its KPIs page.
 *
 * @param {ChainSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainSelected(
	props: ChainSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chain Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees a list of chains.

 *
 * @param {ChainsListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chainsListViewed(
	props: ChainsListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chains List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the KPIs finish loading.
 *
 * @param {CommercialAgreementsPageLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function commercialAgreementsPageLoaded(
	props: CommercialAgreementsPageLoaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Commercial Agreements Page Loaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user cancels the creation of the new contract.


 *
 * @param {ContractCreationCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contractCreationCanceled(
	props: ContractCreationCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Contract Creation Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees the contract details page.




 *
 * @param {ContractDetailsPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contractDetailsPageViewed(
	props: ContractDetailsPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Contract Details Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the users sees the contract drivers step on the creation page.

 *
 * @param {ContractDriversStepViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contractDriversStepViewed(
	props: ContractDriversStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Contract Drivers Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees the contract general information step on the creation page.

 *
 * @param {ContractGeneralInformationStepViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contractGeneralInformationStepViewed(
	props: ContractGeneralInformationStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Contract General Information Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees the initial page of the contract management feature.

 *
 * @param {ContractManagementInitialPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contractManagementInitialPageViewed(
	props: ContractManagementInitialPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Contract Management Initial Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects the Contract Management feature on the commercial agreements home page.
 *
 * @param {ContractManagementStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contractManagementStarted(
	props: ContractManagementStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Contract Management Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers whenever the user sees the Contracts management page.
 *
 * @param {ContractsManagementPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contractsManagementPageViewed(
	props: ContractsManagementPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Contracts Management Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be fired whenever the user clicks in the Create button on the Create Deals page.
 *
 * @param {CreateDealsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createDealsClicked(
	props: CreateDealsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Deals Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event must be fired whenever the user sees the Create deals Page Viewed.
 *
 * @param {CreateDealsPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createDealsPageViewed(
	props: CreateDealsPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Deals Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user starts the creation of a new contract.


 *
 * @param {CreateNewContractStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createNewContractStarted(
	props: CreateNewContractStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create New Contract Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees the initial page of the Customers List feature.


 *
 * @param {CustomerListInitialPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerListInitialPageViewed(
	props: CustomerListInitialPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customer List Initial Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects a user profile on the customer list feature.

 *
 * @param {CustomerListProfileSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerListProfileSelected(
	props: CustomerListProfileSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customer List Profile Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects the customers list app on the homepage.


 *
 * @param {CustomerListStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerListStarted(
	props: CustomerListStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customer List Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user cancels the deletion of a driver.
 *
 * @param {DeleteDriverCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteDriverCanceled(
	props: DeleteDriverCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delete Driver Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user confirms the deletion of a driver.




 *
 * @param {DeleteDriverConfirmed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteDriverConfirmed(
	props: DeleteDriverConfirmed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delete Driver Confirmed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks on the delete button of a driver during the creation of a new contract.
 *
 * @param {DeleteDriverStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteDriverStarted(
	props: DeleteDriverStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delete Driver Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks in some card of the Last Mile feature.
 *
 * @param {DeliveryDetailsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryDetailsClicked(
	props: DeliveryDetailsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delivery Details Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks in the arrow of the last mile card to see the delivery history.
 *
 * @param {DeliveryHistoryClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryHistoryClicked(
	props: DeliveryHistoryClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delivery History Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks in the load more button on the delivery details page to see the SKUs that are in the order.
 *
 * @param {DeliverySkUsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliverySkUsViewed(
	props: DeliverySkUsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delivery SKUs Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user downloads a template to use for the upload.
 *
 * @param {DownloadTemplateClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function downloadTemplateClicked(
	props: DownloadTemplateClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Download Template Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user closes the creation driver modal.

 *
 * @param {DriverModalClosed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function driverModalClosed(
	props: DriverModalClosed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Driver Modal Closed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user saves the updates made on the driver's current achievement.





 *
 * @param {DriverProgressSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function driverProgressSaved(
	props: DriverProgressSaved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Driver Progress Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user starts the edition of a contract.



 *
 * @param {EditContractStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editContractStarted(
	props: EditContractStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Edit Contract Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user canceled the edition of a driver.
 *
 * @param {EditDriverCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editDriverCanceled(
	props: EditDriverCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Edit Driver Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user saves the edition made on a driver.
 *
 * @param {EditDriverCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editDriverCompleted(
	props: EditDriverCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Edit Driver Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks on the edit button of a driver during the creation of a new contract.
 *
 * @param {EditDriverStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editDriverStarted(
	props: EditDriverStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Edit Driver Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks on the button to export the errors found during the file upload.
 *
 * @param {ExportErrorsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportErrorsClicked(
	props: ExportErrorsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Errors Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user starts the file selection to perform the upload.
 *
 * @param {FileSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileSelected(
	props: FileSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'File Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks on the button to finish the file upload.
 *
 * @param {FinishUploadClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function finishUploadClicked(
	props: FinishUploadClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Finish Upload Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks on the ‘Go to BEES Care’ button in Link Admin.
 *
 * @param {GoToBeesCareButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function goToBeesCareButtonClicked(
	props: GoToBeesCareButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Go To BEES Care Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees the Commercial Agreements app's home page.


 *
 * @param {HomePageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function homePageViewed(
	props: HomePageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Home Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks in the Information Center application into Link Admin and the Information Center page is displayed.
 *
 * @param {InformationCenterViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function informationCenterViewed(
	props: InformationCenterViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Information Center Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers whenever the user sees the JBP Visibility initial page.


 *
 * @param {JbpVisibilityPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function jbpVisibilityPageViewed(
	props: JbpVisibilityPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'JBP Visibility Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects the JBP Visibility feature on the commercial agreements home page.
 *
 * @param {JbpVisibilityStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function jbpVisibilityStarted(
	props: JbpVisibilityStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'JBP Visibility Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event must be fired whenever the user sees the KAM Deals page
 *
 * @param {KamDealsPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kamDealsPageViewed(
	props: KamDealsPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KAM Deals Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects a KAM to manage his customers list.


 *
 * @param {KamSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kamSelected(
	props: KamSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KAM Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the users sees the managing page of a KAM's list of customers.




 *
 * @param {KaMsCustomersListPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kaMsCustomersListPageViewed(
	props: KaMsCustomersListPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KAMs Customers List Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees the KAM's customers list page.


 *
 * @param {KaMsListPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kaMsListPageViewed(
	props: KaMsListPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KAMs List Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Trigger when the user accepts a KPI on the Commercial Agreements visibility page
 *
 * @param {KpiAccepted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kpiAccepted(
	props: KpiAccepted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KPI Accepted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user interacts with the dashboards on the KPIs page.
 *
 * @param {KpiInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kpiInteraction(
	props: KpiInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KPI Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees a contract's KPIs page.
 *
 * @param {KpIsPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kpIsPageViewed(
	props: KpIsPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'KPIs Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user interacts with the menus.
 *
 * @param {MenuInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteraction(
	props: MenuInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user finishes the creation of the new contract.




 *
 * @param {NewContractSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newContractSaved(
	props: NewContractSaved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Contract Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user finishes the creation of a new driver to the contract.




 *
 * @param {NewDriverSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newDriverSaved(
	props: NewDriverSaved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Driver Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks on the add driver button.
 *
 * @param {NewDriverStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newDriverStarted(
	props: NewDriverStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Driver Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks in the arrow of some order to see the orders details on the Chain View.
 *
 * @param {OrderDetailsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderDetailsClicked(
	props: OrderDetailsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Details Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event should be fired whenever the user accesses the page BEES Hub Modern Trade Management
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user interacts with the period tabs on the KPIs page.
 *
 * @param {PeriodInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function periodInteraction(
	props: PeriodInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Period Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user returns to the previous step of the registration flow.

 *
 * @param {ReturnStepClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function returnStepClicked(
	props: ReturnStepClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Return Step Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user makes a search for chains.



 *
 * @param {SearchChainsInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchChainsInteraction(
	props: SearchChainsInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Chains Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user makes a search for contracts on the page.



 *
 * @param {SearchContractInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchContractInteraction(
	props: SearchContractInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Contract Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user makes a search for KAMs.



 *
 * @param {SearchKamInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchKamInteraction(
	props: SearchKamInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search KAM Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user makes a search for stores.
 *
 * @param {StoreSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function storeSearched(
	props: StoreSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Store Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks in some store of the store list.
 *
 * @param {StoreSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function storeSelected(
	props: StoreSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Store Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user clicks to access the ‘Tickets’ tab in Link Admin
 *
 * @param {TicketsTabAccessed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ticketsTabAccessed(
	props: TicketsTabAccessed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tickets Tab Accessed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user cancels the updating of a driver's current achievement


 *
 * @param {UpdateDriverProgressCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updateDriverProgressCanceled(
	props: UpdateDriverProgressCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Update Driver Progress Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user starts the updating of a driver current achievement.





 *
 * @param {UpdateDriverProgressStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updateDriverProgressStarted(
	props: UpdateDriverProgressStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Update Driver Progress Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user selects the Update KPIs through file upload feature.
 *
 * @param {UpdateKpIsStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updateKpIsStarted(
	props: UpdateKpIsStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Update KPIs Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the file upload is completed.
 *
 * @param {UploadCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadCompleted(
	props: UploadCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Upload Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the file upload starts processing.
 *
 * @param {UploadProcessingStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadProcessingStarted(
	props: UploadProcessingStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Upload Processing Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggers when the user sees the Upload KPIs Spreadsheet page.
 *
 * @param {UploadSpreadsheetPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadSpreadsheetPageViewed(
	props: UploadSpreadsheetPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Upload Spreadsheet Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * When the user hit the button for accept one or all deals
	 *
	 * @param {AcceptDealsClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	acceptDealsClicked,
	/**
	 * Triggers when the user clicks on the add additional driver button.
	
	 *
	 * @param {AddAdditionalDriverClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addAdditionalDriverClicked,
	/**
	 * Triggers when the user clicks on the add chain button to manage the customer list of a KAM.
	
	
	
	 *
	 * @param {AddChainStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addChainStarted,
	/**
	 * Triggers when the user clicks on the ‘Request access’ button in Link Admin.
	 *
	 * @param {BeesCareRequestAccessButtonClick} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	beesCareRequestAccessButtonClick,
	/**
	 * This event should be fired whenever the user clicks in a button on the BEES Hub Modern Trade Management
	 *
	 * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	buttonClicked,
	/**
	 * Triggers when the user cancels the cancelation of a contract.
	 *
	 * @param {CancelContractCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cancelContractCanceled,
	/**
	 * Triggers when the user confirms the cancelation of a contract.
	
	
	
	 *
	 * @param {CancelContractConfirmed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cancelContractConfirmed,
	/**
	 * Triggers when the user starts the cancelation of a contract.
	
	
	
	 *
	 * @param {CancelContractStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cancelContractStarted,
	/**
	 * Triggers when the user selects a chain to be added to the KAMs catalog.
	
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainAdded,
	/**
	 * Triggers when the user cancels the action of adding chains to the catalog.
	
	
	 *
	 * @param {ChainAddingCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainAddingCanceled,
	/**
	 * Triggers when the user confirms the addition of chain to the KAM's catalog.
	
	
	
	
	
	
	 *
	 * @param {ChainAddingConfirmed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainAddingConfirmed,
	/**
	 * Triggers when the user cancels the action of removing a chain from the KAM's catalog.
	
	
	 *
	 * @param {ChainRemovingCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainRemovingCanceled,
	/**
	 * Triggers when the user confirms the removal of a chain from the KAM's catalog.
	
	
	
	
	 *
	 * @param {ChainRemovingConfirmed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainRemovingConfirmed,
	/**
	 * Triggers when the user clicks on the remove button of a chain added in the KAM's catalog.
	
	
	
	
	 *
	 * @param {ChainRemovingStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainRemovingStarted,
	/**
	 * Triggers when the user makes a search for chains by the search bar
	 *
	 * @param {ChainSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainSearched,
	/**
	 * Triggers when the user selects a contract to see its KPIs page.
	 *
	 * @param {ChainSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainSelected,
	/**
	 * Triggers when the user sees a list of chains.
	
	 *
	 * @param {ChainsListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chainsListViewed,
	/**
	 * Triggers when the KPIs finish loading.
	 *
	 * @param {CommercialAgreementsPageLoaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	commercialAgreementsPageLoaded,
	/**
	 * Triggers when the user cancels the creation of the new contract.
	
	
	 *
	 * @param {ContractCreationCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contractCreationCanceled,
	/**
	 * Triggers when the user sees the contract details page.
	
	
	
	
	 *
	 * @param {ContractDetailsPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contractDetailsPageViewed,
	/**
	 * Triggers when the users sees the contract drivers step on the creation page.
	
	 *
	 * @param {ContractDriversStepViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contractDriversStepViewed,
	/**
	 * Triggers when the user sees the contract general information step on the creation page.
	
	 *
	 * @param {ContractGeneralInformationStepViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contractGeneralInformationStepViewed,
	/**
	 * Triggers when the user sees the initial page of the contract management feature.
	
	 *
	 * @param {ContractManagementInitialPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contractManagementInitialPageViewed,
	/**
	 * Triggers when the user selects the Contract Management feature on the commercial agreements home page.
	 *
	 * @param {ContractManagementStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contractManagementStarted,
	/**
	 * Triggers whenever the user sees the Contracts management page.
	 *
	 * @param {ContractsManagementPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	contractsManagementPageViewed,
	/**
	 * This event should be fired whenever the user clicks in the Create button on the Create Deals page.
	 *
	 * @param {CreateDealsClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createDealsClicked,
	/**
	 * This event must be fired whenever the user sees the Create deals Page Viewed.
	 *
	 * @param {CreateDealsPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createDealsPageViewed,
	/**
	 * Triggers when the user starts the creation of a new contract.
	
	
	 *
	 * @param {CreateNewContractStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createNewContractStarted,
	/**
	 * Triggers when the user sees the initial page of the Customers List feature.
	
	
	 *
	 * @param {CustomerListInitialPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerListInitialPageViewed,
	/**
	 * Triggers when the user selects a user profile on the customer list feature.
	
	 *
	 * @param {CustomerListProfileSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerListProfileSelected,
	/**
	 * Triggers when the user selects the customers list app on the homepage.
	
	
	 *
	 * @param {CustomerListStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerListStarted,
	/**
	 * Triggers when the user cancels the deletion of a driver.
	 *
	 * @param {DeleteDriverCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deleteDriverCanceled,
	/**
	 * Triggers when the user confirms the deletion of a driver.
	
	
	
	
	 *
	 * @param {DeleteDriverConfirmed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deleteDriverConfirmed,
	/**
	 * Triggers when the user clicks on the delete button of a driver during the creation of a new contract.
	 *
	 * @param {DeleteDriverStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deleteDriverStarted,
	/**
	 * Triggers when the user clicks in some card of the Last Mile feature.
	 *
	 * @param {DeliveryDetailsClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deliveryDetailsClicked,
	/**
	 * Triggers when the user clicks in the arrow of the last mile card to see the delivery history.
	 *
	 * @param {DeliveryHistoryClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deliveryHistoryClicked,
	/**
	 * Triggers when the user clicks in the load more button on the delivery details page to see the SKUs that are in the order.
	 *
	 * @param {DeliverySkUsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deliverySkUsViewed,
	/**
	 * Triggers when the user downloads a template to use for the upload.
	 *
	 * @param {DownloadTemplateClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	downloadTemplateClicked,
	/**
	 * Triggers when the user closes the creation driver modal.
	
	 *
	 * @param {DriverModalClosed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	driverModalClosed,
	/**
	 * Triggers when the user saves the updates made on the driver's current achievement.
	
	
	
	
	
	 *
	 * @param {DriverProgressSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	driverProgressSaved,
	/**
	 * Triggers when the user starts the edition of a contract.
	
	
	
	 *
	 * @param {EditContractStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	editContractStarted,
	/**
	 * Triggers when the user canceled the edition of a driver.
	 *
	 * @param {EditDriverCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	editDriverCanceled,
	/**
	 * Triggers when the user saves the edition made on a driver.
	 *
	 * @param {EditDriverCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	editDriverCompleted,
	/**
	 * Triggers when the user clicks on the edit button of a driver during the creation of a new contract.
	 *
	 * @param {EditDriverStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	editDriverStarted,
	/**
	 * Triggers when the user clicks on the button to export the errors found during the file upload.
	 *
	 * @param {ExportErrorsClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportErrorsClicked,
	/**
	 * Triggers when the user starts the file selection to perform the upload.
	 *
	 * @param {FileSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileSelected,
	/**
	 * Triggers when the user clicks on the button to finish the file upload.
	 *
	 * @param {FinishUploadClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	finishUploadClicked,
	/**
	 * Triggers when the user clicks on the ‘Go to BEES Care’ button in Link Admin.
	 *
	 * @param {GoToBeesCareButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	goToBeesCareButtonClicked,
	/**
	 * Triggers when the user sees the Commercial Agreements app's home page.
	
	
	 *
	 * @param {HomePageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	homePageViewed,
	/**
	 * Triggers when the user clicks in the Information Center application into Link Admin and the Information Center page is displayed.
	 *
	 * @param {InformationCenterViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	informationCenterViewed,
	/**
	 * Triggers whenever the user sees the JBP Visibility initial page.
	
	
	 *
	 * @param {JbpVisibilityPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	jbpVisibilityPageViewed,
	/**
	 * Triggers when the user selects the JBP Visibility feature on the commercial agreements home page.
	 *
	 * @param {JbpVisibilityStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	jbpVisibilityStarted,
	/**
	 * This event must be fired whenever the user sees the KAM Deals page
	 *
	 * @param {KamDealsPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kamDealsPageViewed,
	/**
	 * Triggers when the user selects a KAM to manage his customers list.
	
	
	 *
	 * @param {KamSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kamSelected,
	/**
	 * Triggers when the users sees the managing page of a KAM's list of customers.
	
	
	
	
	 *
	 * @param {KaMsCustomersListPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kaMsCustomersListPageViewed,
	/**
	 * Triggers when the user sees the KAM's customers list page.
	
	
	 *
	 * @param {KaMsListPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kaMsListPageViewed,
	/**
	 * Trigger when the user accepts a KPI on the Commercial Agreements visibility page
	 *
	 * @param {KpiAccepted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kpiAccepted,
	/**
	 * Triggers when the user interacts with the dashboards on the KPIs page.
	 *
	 * @param {KpiInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kpiInteraction,
	/**
	 * Triggers when the user sees a contract's KPIs page.
	 *
	 * @param {KpIsPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kpIsPageViewed,
	/**
	 * Triggers when the user interacts with the menus.
	 *
	 * @param {MenuInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteraction,
	/**
	 * Triggers when the user finishes the creation of the new contract.
	
	
	
	
	 *
	 * @param {NewContractSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newContractSaved,
	/**
	 * Triggers when the user finishes the creation of a new driver to the contract.
	
	
	
	
	 *
	 * @param {NewDriverSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newDriverSaved,
	/**
	 * Triggers when the user clicks on the add driver button.
	 *
	 * @param {NewDriverStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newDriverStarted,
	/**
	 * Triggers when the user clicks in the arrow of some order to see the orders details on the Chain View.
	 *
	 * @param {OrderDetailsClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderDetailsClicked,
	/**
	 * This event should be fired whenever the user accesses the page BEES Hub Modern Trade Management
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * Triggers when the user interacts with the period tabs on the KPIs page.
	 *
	 * @param {PeriodInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	periodInteraction,
	/**
	 * Triggers when the user returns to the previous step of the registration flow.
	
	 *
	 * @param {ReturnStepClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	returnStepClicked,
	/**
	 * Triggers when the user makes a search for chains.
	
	
	
	 *
	 * @param {SearchChainsInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchChainsInteraction,
	/**
	 * Triggers when the user makes a search for contracts on the page.
	
	
	
	 *
	 * @param {SearchContractInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchContractInteraction,
	/**
	 * Triggers when the user makes a search for KAMs.
	
	
	
	 *
	 * @param {SearchKamInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchKamInteraction,
	/**
	 * Triggers when the user makes a search for stores.
	 *
	 * @param {StoreSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	storeSearched,
	/**
	 * Triggers when the user clicks in some store of the store list.
	 *
	 * @param {StoreSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	storeSelected,
	/**
	 * Triggers when the user clicks to access the ‘Tickets’ tab in Link Admin
	 *
	 * @param {TicketsTabAccessed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ticketsTabAccessed,
	/**
	 * Triggers when the user cancels the updating of a driver's current achievement
	
	
	 *
	 * @param {UpdateDriverProgressCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	updateDriverProgressCanceled,
	/**
	 * Triggers when the user starts the updating of a driver current achievement.
	
	
	
	
	
	 *
	 * @param {UpdateDriverProgressStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	updateDriverProgressStarted,
	/**
	 * Triggers when the user selects the Update KPIs through file upload feature.
	 *
	 * @param {UpdateKpIsStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	updateKpIsStarted,
	/**
	 * Triggers when the file upload is completed.
	 *
	 * @param {UploadCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uploadCompleted,
	/**
	 * Triggers when the file upload starts processing.
	 *
	 * @param {UploadProcessingStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uploadProcessingStarted,
	/**
	 * Triggers when the user sees the Upload KPIs Spreadsheet page.
	 *
	 * @param {UploadSpreadsheetPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uploadSpreadsheetPageViewed,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2MQFSM0dqzp0Zhef9Urrr3RZ842`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
