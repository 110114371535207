import { Spinner } from '@BarrelComponents'
import { LoadingScreen as LaunchLoadingScreen } from '@Src/modules/customer/LaunchEditor/components/LaunchEditorLoadingScreen'
import { LoadingScreen as WebViewLoadingScreen } from '@Src/modules/customer/WebViewEditor/components/LoadingScreen'
import { WithSidebar } from '@Src/shared/hocs/WithSidebar/WithSidebar'
import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import RouterHistoryProvider from './RouterHistoryProvider'

const BASENAME = '/cms'
const HomePage = lazy(() => import('@Src/modules/general/Home/Home'))
const Showcase = lazy(() => import('@Src/modules/general/Showcase/Showcase'))
const DeepLinkGenerator = lazy(
  () => import('@Src/modules/deepLink/DeepLinkGenerator/DeepLinkGenerator')
)
const MonitoringDashboard = lazy(
  () => import('@Src/modules/deepLink/MonitoringDashboard/MonitoringDashboard')
)
const ZoneSelectionPage = lazy(() => import('@Src/modules/zone/ZoneSelection/ZoneSelection'))
const StoreSelectorPage = lazy(() => import('@Src/modules/zone/StoreSelector/StoreSelector'))
const ContentListPage = lazy(() => import('@Src/modules/customer/ContentList/ContentList'))

const LaunchEditor = lazy(() => import('@Src/modules/customer/LaunchEditor/LaunchEditor'))

const MiNegocioList = lazy(() => import('@Src/modules/miNegocio/MiNegocioList/MiNegocioList'))
const MiNegocioEvent = lazy(() => import('@Src/modules/miNegocio/MiNegocioEvent/MiNegocioEvent'))
const CreateMiNegocioTag = lazy(
  () => import('@Src/modules/miNegocio/MiNegocioTag/content/Create/CreateMiNegocioTag')
)
const EditMiNegocioTag = lazy(
  () => import('@Src/modules/miNegocio/MiNegocioTag/content/Edit/EditMiNegocioTag')
)
const CreateMiNegocioAuthor = lazy(
  () =>
    import(
      '@Src/modules/miNegocio/MiNegocioAuthor/content/CreateMiNegocioAuthor/CreateMiNegocioAuthor'
    )
)
const EditMiNegocioAuthor = lazy(
  () =>
    import('@Src/modules/miNegocio/MiNegocioAuthor/content/EditMiNegocioAuthor/EditMiNegocioAuthor')
)
const MiNegocioLongtip = lazy(
  () => import('@Src/modules/miNegocio/MiNegocioLongtip/MiNegocioLongtip')
)
const MiNegocioVideo = lazy(() => import('@Src/modules/miNegocio/MiNegocioVideo/MiNegocioVideo'))
const CreateMiNegocioCategory = lazy(
  () =>
    import(
      '@Src/modules/miNegocio/MiNegocioCategory/content/CreateMiNegocioCategory/CreateMiNegocioCategory'
    )
)
const EditMiNegocioCategory = lazy(
  () =>
    import(
      '@Src/modules/miNegocio/MiNegocioCategory/content/EditMiNegocioCategory/EditMiNegocioCategory'
    )
)
const MiNegocioPodcasts = lazy(
  () => import('@Src/modules/miNegocio/MiNegocioPodcasts/MiNegocioPodcasts')
)
const MiNegocioDailyFeed = lazy(
  () => import('@Src/modules/miNegocio/MiNegocioDailyFeed/MiNegocioDailyFeed')
)
const MiNegocioChallenge = lazy(
  () => import('@Src/modules/miNegocio/MiNegocioChallenge/MiNegocioChallenge')
)
const MiNegocioSingleEvent = lazy(
  () => import('@Src/modules/miNegocio/MiNegocioSingleEvent/MiNegocioSingleEvent')
)

const WebViewEditor = lazy(() => import('@Src/modules/customer/WebViewEditor/WebViewEditor'))

const ZoneConfiguration = lazy(
  () => import('@Src/modules/zone/ZoneConfiguration/ZoneConfiguration')
)
const PageBuilderExample = lazy(
  () => import('@Src/components/templates/PageBuilder/PageBuilderExample/PageBuilderExample')
)
const PageBuilderExample2 = lazy(
  () => import('@Src/components/templates/PageBuilder/PageBuilderExample/PageBuilderExample2')
)

const LaunchEditorWIP = lazy(() => import('@Src/modules/customer/LaunchEditor.WIP/LaunchEditorWIP'))

const Loading = WithSidebar(() => <Spinner />)

const Router = () => {
  return (
    <BrowserRouter basename={BASENAME}>
      <RouterHistoryProvider>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/" element={<ZoneSelectionPage />} />
            <Route path="page-builder" element={<PageBuilderExample />} />
            <Route path="page-builder2" element={<PageBuilderExample2 />} />
            <Route path="loading" element={<Loading />} />
            <Route path="home" element={<HomePage />} />
            <Route path="showcase" element={<Showcase />} />
            <Route path="deep-link-generator" element={<DeepLinkGenerator />} />
            <Route path="monitoring-dashboard" element={<MonitoringDashboard />} />
            <Route path="partner-storefront" element={<StoreSelectorPage />} />
            <Route path="banners-launch-list" element={<Outlet />}>
              <Route index element={<Navigate replace to="/banners-launch-list/customer" />} />

              <Route path="customer" element={<ContentListPage />} />
              <Route path="partner" element={<ContentListPage />} />

              <Route path="create-new-launch" element={<LaunchEditor />} />
              <Route path="view-launch/:guid" element={<LaunchEditor />} />
              <Route path="edit-launch/:guid" element={<LaunchEditor />} />

              <Route path="launch-editor-wip" element={<LaunchEditorWIP />} />
              <Route path="launch-editor-wip/:guid" element={<LaunchEditorWIP />} />

              <Route path="loading" element={<LaunchLoadingScreen />} />
            </Route>
            <Route path="mi-negocio-content-list" element={<Outlet />}>
              <Route index element={<MiNegocioList />} />
              <Route path="new-multiple-events" element={<MiNegocioEvent />} />
              <Route path="edit-multiple-events/:guid" element={<MiNegocioEvent />} />
              <Route path="new-tag" element={<CreateMiNegocioTag />} />
              <Route path="edit-tag/:id" element={<EditMiNegocioTag />} />
              <Route path="new-author" element={<CreateMiNegocioAuthor />} />
              <Route path="edit-author/:guid" element={<EditMiNegocioAuthor />} />
              <Route path="create-new-long-tip" element={<MiNegocioLongtip />} />
              <Route path="long-tip/:guid" element={<MiNegocioLongtip />} />
              <Route path="new-category" element={<CreateMiNegocioCategory />} />
              <Route path="edit-category/:id" element={<EditMiNegocioCategory />} />
              <Route path="new-podcast" element={<MiNegocioPodcasts />} />
              <Route path="new-challenge" element={<MiNegocioChallenge />} />
              <Route path="challenge/:guid" element={<MiNegocioChallenge />} />
              <Route path="podcast/:guid" element={<MiNegocioPodcasts />} />
              <Route path="create-video" element={<MiNegocioVideo />} />
              <Route path="edit-video/:guid" element={<MiNegocioVideo />} />
              <Route path="new-daily-feed" element={<MiNegocioDailyFeed />} />
              <Route path="daily-feed/:guid" element={<MiNegocioDailyFeed />} />
              <Route path="create-event" element={<MiNegocioSingleEvent />} />
              <Route path="edit-event/:guid" element={<MiNegocioSingleEvent />} />
            </Route>
            <Route path="web-view" element={<Outlet />}>
              <Route path="create-web-view" element={<WebViewEditor />} />
              <Route path="web-view-editor/:guid" element={<WebViewEditor />} />

              <Route path="loading" element={<WebViewLoadingScreen />} />
            </Route>
            <Route path="zone-configuration" element={<Outlet />}>
              <Route index element={<ZoneConfiguration />} />
            </Route>
          </Routes>
        </Suspense>
      </RouterHistoryProvider>
    </BrowserRouter>
  )
}
export default Router
