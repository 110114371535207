import { SkeletonLoader } from '@hexa-ui/components';
import { Box } from '@material-ui/core';
import './SkeletonMonitoringDetailsV2.css';

function SkeletonMonitoringDetailsV2(): JSX.Element {
  return (
    <Box data-testid="skeleton-monitoring-details-v2">
      <Box className="wrapper-box">
        <SkeletonLoader width="400px" height="50px" variant="body" />
      </Box>
      <Box className="wrapper-box">
        <SkeletonLoader width="100%" height="148px" variant="body" />
      </Box>
      <Box className="wrapper-box">
        <SkeletonLoader width="100%" height="48px" variant="body" />
      </Box>
      <Box className="wrapper-box">
        <SkeletonLoader width="100%" height="500px" variant="body" />
      </Box>
    </Box>
  );
}

export default SkeletonMonitoringDetailsV2;
