import { BILLION, MILLION } from 'utils/formattedAchievement';

const THOUSAND = 1000;
const LOG_BASE = 3;
// The number of decimal places to use when formatting numbers
const PRECISION = 1;

function floorToFixed(number, precision) {
  const power = Math.pow(10, precision);
  return (Math.floor(number * power) / power).toFixed(precision);
}

export const calculateSuffix = (value, initialNumber = MILLION, country = 'US') => {
  const suffixes = country === 'AR' ? ['', '', 'MM', 'Bi', 'T'] : ['', '', 'M', 'B', 'T'];
  if (initialNumber >= BILLION) {
    if (value >= BILLION) {
      const suffixNum = Math.floor(Math.log10(value) / LOG_BASE);
      if (suffixNum >= suffixes.length) {
        throw new Error('Number too large to abbreviate');
      }
      return suffixes[suffixNum];
    }
  } else {
    if (value >= MILLION) {
      const suffixNum = Math.floor(Math.log10(value) / LOG_BASE);
      if (suffixNum >= suffixes.length) {
        throw new Error('Number too large to abbreviate');
      }
      return suffixes[suffixNum];
    } else {
      return '';
    }
  }
};

export const formatNumber = (value, precision = PRECISION) => {
  const isValueNumber = typeof value === 'number';
  const isValueFinite = isFinite(value);
  const isValuePositive = value >= 0;
  const isPrecisionNumber = typeof precision === 'number';
  const isPrecisionPositive = precision >= 0;

  if (
    !isValueNumber ||
    isNaN(value) ||
    !isValueFinite ||
    !isValuePositive ||
    !isPrecisionNumber ||
    !isPrecisionPositive
  ) {
    return '0';
  }
  const isValueLarge = value >= MILLION;
  const newValue = isValueLarge
    ? value / Math.pow(THOUSAND, Math.floor(Math.log10(value) / LOG_BASE))
    : value;

  return isValueLarge ? floorToFixed(newValue, precision).replace('.', ',') : newValue.toString();
};

export const abbreviateNumber = ({
  value,
  precision = PRECISION,
  initialNumber = MILLION,
  country = 'US',
}) => {
  try {
    const suffix = calculateSuffix(value, initialNumber, country);
    const formattedValue = formatNumber(value, precision);
    return { abbreviatedNumber: formattedValue, suffix };
  } catch (error) {
    return { abbreviatedNumber: '0', suffix: '' };
  }
};
