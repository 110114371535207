import { Card } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const StyledRewardCard = styled(Card, {
  display: 'flex',
  padding: '1.5rem',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: '8.875rem',
  flex: '1 0 0',
  width: '48%',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 4px 0px !important',
});

export const StyledRewardCardTitle = styled('div', {
  height: '1.188rem',
  color: 'var(--light-txt-txt, #141414)',
  fontFamily: 'var(--fonts-heading)',
  fontSize: '1rem',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '1.25rem',
});

export const StyledRewardCardContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '1 0 0',
  alignSelf: 'stretch',
  width: '100%',
  height: '4.63rem',
});

export const TargetProgressContainer = styled('div', {});

export const TotalRevenueContainer = styled('div', {});

export const StyledSupportMessage = styled('div', {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  height: '18px',
  marginTop: '12px',
  color: 'var(--txt-default, #141414)',
  fontFamily: 'Work Sans',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '18px',
});
