import ItemLabel from './components/ItemLabel/ItemLabel';
import SelectOption from './components/SelectOption/SelectOption';
import SelectRoot from './Select';

const Root = SelectRoot;
const Option = SelectOption;
const Label = ItemLabel;

export type { ItemLabelProps } from './components/ItemLabel/ItemLabel';
export type { SelectProps } from './Select';
export { Label, Option, Root };
