import { monthsOfYear } from '../formattedDate';

interface GetMonthsRangeArgs {
  startDate: string;
  endDate: string;
  locale: string;
}

export interface MonthsOfUpdate {
  value: string;
  label: string;
}

export interface GetMonthsToFilterArgs {
  locale: string;
}

type GetMonthsRange = (args: GetMonthsRangeArgs) => MonthsOfUpdate[];

export const getMonthsRange: GetMonthsRange = ({ startDate = '', endDate = '', locale }) => {
  try {
    const months = monthsOfYear(locale);
    const start = startDate.split(/-/);
    const end = endDate.split(/-/);
    const from = new Date(Number(start[0]), Number(start[1]) - 1, Number(start[2]));
    const to = new Date(Number(end[0]), Number(end[1]) - 1, Number(end[2]));
    const fromYear = from.getFullYear();
    const toYear = to.getFullYear();

    const fromMonth = from.getMonth();
    const toMonth = to.getMonth();

    const method = ({ range, start, end, fromYear }) => {
      for (let i = start; i <= end; i++) {
        range.push({
          label: months[i],
          value: `${('00' + (i + 1)).slice(-2)}${fromYear}`,
        });
      }
      return range;
    };

    if (fromYear !== toYear) {
      const range = [];

      const fromRange = method({ range, start: fromMonth, end: 11, fromYear });
      const toRange = method({ range: fromRange, start: 0, end: toMonth, fromYear: toYear });

      return toRange;
    }
    return method({ range: [], start: fromMonth, end: toMonth, fromYear });
  } catch (e) {
    return [];
  }
};

type GetMonthRangeToFilterArg = (args: GetMonthsToFilterArgs) => MonthsOfUpdate[];

export const getMonthRangeToFilter: GetMonthRangeToFilterArg = ({ locale }) => {
  try {
    const months = monthsOfYear(locale);

    const from = new Date(new Date().getFullYear(), 0, 1);
    const to = new Date(new Date().getFullYear(), 11, 31);
    const fromYear = from.getFullYear();

    const fromMonth = from.getMonth();
    const toMonth = to.getMonth();

    const method = ({ range, start, end, fromYear }) => {
      for (let i = start; i <= end; i++) {
        range.push({
          label: `${fromYear} - ${months[i].substring(0, 3)}`,
          value: `${('00' + (i + 1)).slice(-2)}${fromYear}`,
        });
      }
      return range;
    };

    return method({ range: [], start: fromMonth, end: toMonth, fromYear });
  } catch (e) {
    return [];
  }
};

export interface FormatMonthsToPeriodFilterArgs {
  locale: string;
  dateRangeList: [];
}

type FormatMonthsToPeriodFilterArg = (args: FormatMonthsToPeriodFilterArgs) => MonthsOfUpdate[];

export const formatMonthsToPeriodFilter: FormatMonthsToPeriodFilterArg = ({
  locale,
  dateRangeList,
}) => {
  try {
    const months = monthsOfYear(locale);

    const range = [];
    dateRangeList.map((item: string) => {
      range.push({
        label: `${months[parseInt(item.substring(0, 2)) - 1].substring(0, 3)} ${item.substring(2)}`,
        value: `${item}`,
      });
    });

    return range;
  } catch (e) {
    return [];
  }
};
