export const MAX_FILE_SIZE = 2 * 1024 * 1024 // 2MB
export const MAX_FILE_SIZE_PDF = 5 * 1024 * 1024 // 5MB
export const ACCEPTED_FILE_TYPES = '.csv, .xlsx'
export const ACCEPTED_FILE_TYPES_PDF = 'application/pdf'

export const ANALYSIS_MAX_ATTEMPTS = 300
export const ANALYSIS_RETRY_DELAY = 3000

export const STATUS = {
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  AWAITING_ACCEPTANCE: 'AWAITING_ACCEPTANCE',
  ENDED: 'ENDED',
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_ACCEPTED: 'NOT_ACCEPTED',
  PLANNED: 'PLANNED'
}

export const EDITING_OPTIONS = {
  NOT_ALLOWED: { option: 'notAllowed', value: 'NOT_ALLOWED', event: 'don’t allow editing'},
  VISIBLE: { option: 'visible', value: 'VISIBLE', event: 'until the offer is visible' },
  ACCEPTED: { option: 'accepted', value: 'ACCEPTED', event: 'until the offer is accepted' },
  START: { option: 'start', value: 'START', event: 'until the offer starts' }
} as const

export const MAX_ACHIEVED = 'MAX_ACHIEVED'
export const MIN_NOT_ACHIEVED = 'MIN_NOT_ACHIEVED'
export const SELL_IN = 'SELLIN'
export const SELL_OUT = 'SELLOUT'

export const VENDOR_ABI = 'ABI'
export const VENDOR_LOREAL = 'LOREAL'
