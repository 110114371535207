import { Box } from '@material-ui/core';
import { useMemo } from 'react';
import './Badge.css';

type BadgeProps = {
  status: IndicatorEnum;
  altColors?: boolean;
};

export enum IndicatorEnum {
  FAILED = 'FAILED',
  CONCLUDED_WITH_FAILURES = 'CONCLUDED_WITH_FAILURES',
  CONCLUDED = 'CONCLUDED',
  PROCESSING = 'PROCESSING',
}

function Badge({ status, altColors }: BadgeProps): JSX.Element {
  const statusInfo = useMemo(() => {
    switch (status) {
      case IndicatorEnum.PROCESSING:
        return {
          name: 'In progress',
          border: altColors ? '#E6F2FF' : '#D5E9FE',
          dot: altColors ? '#006EDB' : '#0F7FFA',
          label: altColors ? '#006EDB' : '#0F7FFA',
          background: altColors ? '#E6F2FF' : '',
        };
      case IndicatorEnum.CONCLUDED_WITH_FAILURES:
        return {
          name: 'Completed with failures',
          border: '#FFF1D4',
          dot: '#FFAA33',
          label: '#B37724',
          background: altColors ? '#FFF1D4' : '',
        };
      case IndicatorEnum.FAILED:
        return {
          name: 'Failed',
          border: altColors ? '#FFE6E6' : '#FFE7E5',
          dot: altColors ? '#E82727' : '#E53C32',
          label: altColors ? '#E82727' : '#C3332B',
          background: altColors ? '#FFE6E6' : '',
        };
      case IndicatorEnum.CONCLUDED:
        return {
          name: 'Completed',
          border: altColors ? '#E6F9E6' : '#CCEFD4',
          dot: altColors ? '#009900' : '#31BD54',
          label: altColors ? '#009900' : '#2AA147',
          background: altColors ? '#E6F9E6' : '',
        };
      default:
        return { name: '-', tooltipMessage: '-' };
    }
  }, [status]);

  return (
    <Box
      className="badge"
      style={{
        border: `1px solid ${statusInfo.border}`,
        backgroundColor: `${statusInfo.background}`,
        height: altColors ? '24px' : '26px',
      }}
    >
      <div className="dot" style={{ backgroundColor: `${statusInfo.dot}` }} />
      <div className="label" style={{ color: `${statusInfo.label}` }}>
        {statusInfo.name}
      </div>
    </Box>
  );
}
export default Badge;
