import { ZoneStore } from '@Src/shared/contexts/ZonesContext/ZonesContext.types'
import { StoreFeatures } from '@Src/shared/utils/storeUtils/class/StoreFeatures'
import { StoreType as SegmentStoreType } from '@Src/types/segment.type'
import { MI_NEGOCIO_STORE_ID } from '../../constants'
import { StoreType } from '../store.enum'

export class Store {
  id: string

  name: string

  defaultVendorId?: string | null

  isAbiStore?: boolean

  features?: StoreFeatures

  constructor(zoneStore: ZoneStore) {
    this.id = zoneStore.id
    this.name = zoneStore.name
    this.isAbiStore = zoneStore.isAbiStore
    this.features = zoneStore.features
    this.defaultVendorId = zoneStore.defaultVendorId
  }

  get storeTypeSegmentProperty(): SegmentStoreType {
    return this.isAbiStore ? SegmentStoreType.ABI : SegmentStoreType.PARTNER
  }

  get storeType(): StoreType {
    if (this.isAbiStore) return StoreType.CUSTOMER
    if (this.id === MI_NEGOCIO_STORE_ID) return StoreType.MI_NEGOCIO
    return StoreType.PARTNER
  }

  static findById(stores: Store[], storeId: string): Store | null {
    return stores.find((store) => store.id === storeId) || null
  }
}
