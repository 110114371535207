import { Tooltip } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { abbreviateNumber } from '../../../utils/formmattedNumbers';
import { getCurrencyByLocale, numberFormat } from '../../NumberDisplay';
import { RevenueTextContainer, RevenueValueContainer } from './TotalRevenue.styles';

type TotalRevenueProps = {
  accepted?: boolean;
  revenue: number;
  locale: string;
};

type RevenueValueState = {
  suffix: string;
  abbreviatedNumber: string;
};

const INITIAL_VALUE = '0';

const TotalRevenue = ({ revenue, locale }: TotalRevenueProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const [revenueValue, setRevenueValue] = useState<RevenueValueState>({
    suffix: '',
    abbreviatedNumber: INITIAL_VALUE,
  });

  useEffect(() => {
    if (revenue) {
      try {
        const revenueValueConverted = abbreviateNumber({ value: revenue });
        setRevenueValue(revenueValueConverted);
      } catch (error) {
        setRevenueValue({ suffix: '', abbreviatedNumber: INITIAL_VALUE });
      }
    } else {
      setRevenueValue({ suffix: '', abbreviatedNumber: INITIAL_VALUE });
    }
  }, [revenue]);

  return (
    <>
      <RevenueValueContainer data-testid="revenue-value">
        {revenueValue.suffix === '' ? (
          `${numberFormat({
            value: Number(revenueValue.abbreviatedNumber),
            locale,
            style: 'currency',
          })}`
        ) : (
          <Tooltip
            placement="top"
            text={`${numberFormat({ value: revenue, locale, style: 'currency' })}`}
          >
            {locale === 'CA'
              ? `${revenueValue.abbreviatedNumber} ${revenueValue.suffix} ${getCurrencyByLocale(
                  locale
                )}`
              : `${getCurrencyByLocale(locale)} ${revenueValue.abbreviatedNumber} ${
                  revenueValue.suffix
                }`}
          </Tooltip>
        )}
      </RevenueValueContainer>
      <RevenueTextContainer data-testid="revenue-text">
        {formatMessage({ id: 'rewardCard.totalRevenue.toEarn' })}
      </RevenueTextContainer>
    </>
  );
};

export default TotalRevenue;
