import { Paragraph } from '@hexa-ui/components';

interface Props {
  fullText: string;
  textToHighLight?: string;
}

export const HighLight = ({ fullText, textToHighLight }: Props) => {
  const regex = new RegExp(`(${textToHighLight ?? ''})`, 'i');

  const lower = (str: string) => str.toLowerCase();

  return (
    <Paragraph>
      {fullText.split(regex).map((chunck, index) => {
        return lower(chunck) === lower(textToHighLight ?? '') ? (
          <mark key={index}>{chunck}</mark>
        ) : (
          chunck
        );
      })}
    </Paragraph>
  );
};
