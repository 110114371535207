import { styled } from '@hexa-ui/theme';

export const SupportMessageWrapper = styled('div', {
  top: '40px',
  color: ' #141414',
  fontFamily: 'Work Sans',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '18px',
  marginTop: '10px',
});
