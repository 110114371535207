import {
  Card,
  Grid,
  Heading,
  Badge as HexaBadge,
  OnRowReturnType,
  Paragraph,
  SearchField,
  Table,
  Toggle,
} from '@hexa-ui/components';
import { ChevronDown, Filter, Sync, X } from '@hexa-ui/icons';
import { EmptyState as EmptyStateIllustration } from '@hexa-ui/illustrations';
import { Box, useMediaQuery } from '@material-ui/core';
import { useDecision } from '@optimizely/react-sdk';
import { ElementLocationType, ElementType, PageName, SpecificApp } from 'analytics';
import Badge, { IndicatorEnum } from 'components/Badge/Badge';
import FilterPanel, { ITabProps } from 'components/FilterPanel/FilterPanel';
import ModalDescriptions from 'components/ModalDescriptions/ModalDescriptions';
import { SegmentEvents } from 'consts/segment';
import UserInfoContext from 'context/UserInfoContext';
import {
  addMinutes,
  format,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  isWithinInterval,
  parseISO,
  subMinutes,
} from 'date-fns';
import { useAppHeaderService } from 'hooks/useAppHeaderService';
import useEventPageProps from 'hooks/useEventPageProps';
import { useSegmentEvent } from 'hooks/useSegmentAnalytics';
import { formatMessage } from 'i18n/formatters';
import { SegmentProps } from 'interfaces/SegmentProps';
import ChunkUpdateInfoCard from 'pages/Monitoring/components/v2/components/ChunkUpdateInfoCard/ChunkUpdateInfoCard';
import { SelectItem } from 'pages/Monitoring/components/v2/components/Dropdown/Dropdown';
import HeaderStatus from 'pages/Monitoring/components/v2/components/HeaderStatus/HeaderStatus';
import { statusChunkDetails } from 'pages/Monitoring/components/v2/FilterContainer/filterContainerData';
import {
  addPageToHistory,
  getDaysBefore,
  hasPageInHistory,
  parseDate,
  roundToNearest15Minutes,
  sortDateAsc,
  sortDateDesc,
} from 'pages/Monitoring/components/v2/FilterContainer/utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { BASE_URL, MONITORINGPAGE_URL } from 'Router';
import {
  useGetRecordDetailsQuery,
  useGetRecordsFromTraceIdQuery,
  useGetStatusQuery,
} from 'services/baseApi';
import { IMonitoringParams } from 'services/monitoring/monitoring';
import {
  getStepChunks,
  IExtendedMonitoringDetailsRecord,
  IStep,
} from 'services/monitoring/recordService';
import { convert24ToAMPM, formatTimeDifference, getFormattedDate } from 'utils/date';
import LastUpdatedTab, { DateFieldsStatus } from './components/LastUpdatedTab/LastUpdatedTab';
import ProgressCounter from './components/ProgressCounter/ProgressCounter';
import SkeletonMonitoringDetailsV2 from './components/SkeletonMonitoringDetailsV2/SkeletonMonitoringDetailsV2';
import StatusTab from './components/StatusTab/StatusTab';
import StepCard from './components/StepCard/StepCard';
import { CustomStatus } from './consts/chunks';

import './MonitoringDetailsPageV2.css';

type AppliedFilters = {
  startDate?: Date;
  endDate?: Date;
  status?: SelectItem[];
};

function EmptyStateTable() {
  return (
    <Box
      style={{
        display: 'flex',
        maxWidth: '228px',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginRight: 'auto',
        marginLeft: 'auto',
        height: '100%',
        padding: '1rem',
        textAlign: 'center',
      }}
    >
      <EmptyStateIllustration.NoDataYet width="128px" />
      <Paragraph>{formatMessage({ id: 'MonitoringDetailsPage.NO_DATA' })}</Paragraph>
    </Box>
  );
}

interface IMonitoringDetailsPageV2Props {
  segmentProps?: SegmentProps;
}

function MonitoringDetailsPageV2({
  segmentProps,
}: Readonly<IMonitoringDetailsPageV2Props>): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const { id: traceId } = useParams<{ id: string }>();

  const searchParams = new URLSearchParams(location.search);
  const parentTraceId = searchParams.get('parent-trace-id') ?? undefined;
  const urlStartDate = useMemo(
    () => searchParams.get('start-date') ?? getFormattedDate(getDaysBefore(10)),
    []
  );
  const urlEndDate = searchParams.get('end-date') ?? getFormattedDate(new Date());
  const urlCountry = searchParams.get('country');
  const urlVendor = searchParams.get('vendor');

  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const { selectedCountry: country, selectedVendor: vendor } = useContext(UserInfoContext);
  const isLargeUp = useMediaQuery('(min-width:1200px)');
  const [selectedStatus, setSelectedStatus] = useState<SelectItem[]>([]);
  const [dateFieldsStatus, setDateFieldsStatus] = useState<DateFieldsStatus>();
  const [chunksToDisplay, setChunksToDisplay] = useState<IExtendedMonitoringDetailsRecord[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilters>({ status: selectedStatus });
  const [showSimilarIds, setShowSimilarIds] = useState(false);
  const [traceIdSearch, setTraceIdSearch] = useState('');
  const [currentStepTitle, setCurrentStepTitle] = useState('');
  const [currentStep, setCurrentStep] = useState<IStep>({
    name: '',
    status: '',
    amount: 0,
    chunks: {},
  });
  const event = useSegmentEvent();

  const onApplyFilter = () => {
    setAppliedFilters({ endDate, startDate, status: selectedStatus });

    const times = {
      start: convert24ToAMPM(new Date(appliedFilters.startDate ?? new Date())),
      end: convert24ToAMPM(new Date(appliedFilters.endDate ?? new Date())),
    };

    const datePeriod = formatTimeDifference(
      parseDate(appliedFilters?.startDate || new Date()),
      parseDate(appliedFilters?.endDate || new Date())
    );

    const timeToLoad =
      statusResponse.fulfilledTimeStamp && statusResponse.startedTimeStamp
        ? statusResponse.fulfilledTimeStamp - statusResponse.startedTimeStamp
        : undefined;

    const eventParams = {
      filter_status_in_progress: selectedStatus.map((s) => s.value).includes('PROCESSING'),
      filter_status_failed: selectedStatus.map((s) => s.value).includes('FAILED'),
      filter_status_completed: selectedStatus.map((s) => s.value).includes('CONCLUDED'),
      filter_start_date: format(appliedFilters.startDate ?? new Date(), 'yyyy/MM/dd'),
      filter_end_date: format(appliedFilters.endDate ?? new Date(), 'yyyy/MM/dd'),
      filter_start_time: format(appliedFilters.startDate ?? new Date(), 'HH:mm:ss'),
      filter_end_time: format(appliedFilters.startDate ?? new Date(), 'HH:mm:ss'),
      filter_start_time_day_period: times.start.period,
      filter_end_time_day_period: times.end.period,
      list_time_to_load: timeToLoad,
      number_of_results: statusResponse.data?.pagination?.totalElementsPage || 0,
      in_progress_number_of_results: filteredTableData.filter(
        (data) => data.statusCode === 'IN_PROGRESS'
      ).length,
      failed_number_of_results: filteredTableData.filter((data) => data.statusCode === 'FAILED')
        .length,
      completed_number_of_results: filteredTableData.filter(
        (data) => data.statusCode === 'COMPLETED'
      ).length,
      filtered_period: datePeriod,
      specific_app: SpecificApp.Monitoring,
      page_name: PageName.BeesSyncMonitoringMainList,
      is_core_event: true,
    };
    event(SegmentEvents.FILTER_APPLIED, eventParams);
    setIsFilterPanelOpen(false);
  };

  const onCancelFilter = () => {
    setIsFilterPanelOpen(false);
  };

  useAppHeaderService({
    hidePageTitle: true,
    countrySelect: false,
    vendorSelect: false,
    breadcrumbConfig: {
      homePath: BASE_URL,
      items: [
        {
          label: formatMessage({ id: 'MonitoringPage.TITLE' }),
          isCurrentPage: false,
          // path: `${MONITORINGPAGE_URL}`,
          path: hasPageInHistory('MonitoringView')
            ? `${MONITORINGPAGE_URL}/nocache`
            : MONITORINGPAGE_URL,
          onClick: hasPageInHistory('MonitoringView')
            ? () => history.goBack()
            : () => history.push(MONITORINGPAGE_URL),
        },
        {
          label: formatMessage({ id: 'MonitoringDetailsPage.TITLE' }),
          isCurrentPage: true,
          path: history.location.pathname,
        },
      ],
    },
  });

  const tableColumns = [
    {
      Header: 'Trace ID',
      accessor: 'traceId',
      disableSortBy: true,
    },
    {
      Header: 'Last Update',
      accessor: 'updatedAt',
      disableSortBy: true,
      customRender: (date: string) => format(parseISO(date), 'MMM dd, yyyy - HH:mm:ss'),
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
    },
  ];

  const onStatusFilterSelect = useCallback(
    (item: SelectItem) => {
      if (selectedStatus.findIndex((status) => status.value === item.value) >= 0) {
        setSelectedStatus(selectedStatus.filter((status) => status.value !== item.value)); // remove status
      } else {
        setSelectedStatus([...selectedStatus, item]); // add status
      }
    },
    [selectedStatus]
  );

  const statusDictionary: { [key: string]: string } = {
    SUCCESS: 'CONCLUDED',
    FAILURE: 'FAILED',
    IN_PROGRESS: 'PROCESSING',
    FAILED: 'FAILED',
    COMPLETED: 'CONCLUDED',
  };

  const getInitialsChunksToDisplay = () => {
    if (!steps.length) return [];
    // get the last step that has chunks
    const lastStepWithChunks = steps
      .slice()
      .reverse()
      .find((step) => step.amount !== 0);

    const lastStepWithFailedChunks = steps
      .slice()
      .reverse()
      .find((step) => step.amount !== 0 && step.status === CustomStatus.FAILED);

    const stepToDisplay = lastStepWithFailedChunks || lastStepWithChunks || steps[0];

    setCurrentStep(stepToDisplay);
    setCurrentStepTitle(stepToDisplay.name);

    return Object.values(stepToDisplay.chunks).flat();
  };

  const updateChunksToDisplay = (stepName: string) => {
    const step = steps.find((s) => s.name === stepName);
    setCurrentStep(step ?? currentStep);
    setCurrentStepTitle(stepName);
    return step ? Object.values(step.chunks).flat() : [];
  };

  const tableData = chunksToDisplay.map((record) => ({
    traceId: record.traceID,
    updatedAt: record.createdAt,
    status: (
      <Badge status={statusDictionary[record.status.customStatus] as IndicatorEnum} />
    ) as unknown as string,
    statusCode: record.status.customStatus,
  }));

  const minStartDate: Date = useMemo(() => {
    const dates = tableData?.map((td) => parseISO(td.updatedAt).getTime()) ?? [];
    return new Date(Math.min(...dates));
  }, [tableData]);

  const maxEndDate: Date = useMemo(() => {
    const dates = tableData?.map((td) => parseISO(td.updatedAt).getTime()) ?? [];
    return new Date(Math.max(...dates));
  }, [tableData]);

  const minStartTime: Date = useMemo(() => {
    const dates = tableData?.map((td) => parseISO(td.updatedAt)) ?? [];
    dates.sort(sortDateAsc);
    const earliestTime = dates[0];
    return roundToNearest15Minutes(earliestTime);
  }, [tableData]);

  const maxEndTime: Date = useMemo(() => {
    const dates = tableData?.map((td) => parseISO(td.updatedAt)) ?? [];
    dates.sort(sortDateDesc);
    const latestTime = dates[0];
    return roundToNearest15Minutes(latestTime);
  }, [tableData]);

  const startDateInitialState = useMemo(
    () => (isValid(minStartDate) ? minStartDate : new Date()),
    [minStartDate]
  );
  const endDateInitialState = useMemo(
    () => (isValid(maxEndDate) ? maxEndDate : new Date()),
    [maxEndDate]
  );
  const [startDate, setStartDate] = useState(startDateInitialState);
  const [endDate, setEndDate] = useState(endDateInitialState);

  useEffect(() => {
    if (tableData.length === 0) return;
    setStartDate(startDateInitialState);
    setEndDate(endDateInitialState);
  }, [tableData.length]);

  const queryParams: IMonitoringParams = useMemo(
    () => ({
      country: urlCountry ?? country,
      vendor: urlVendor ?? vendor,
      'parent-trace-id': traceId,
      'start-date': urlStartDate,
      'end-date': urlEndDate,
    }),
    [country, vendor, traceId]
  );

  const queryOptions = { skip: !country };
  const recordDetails = useGetRecordDetailsQuery(
    {
      country: queryParams.country,
      vendor: queryParams.vendor,
      'parent-trace-id': queryParams['parent-trace-id'],
    },
    queryOptions
  );
  const recordDetailsTrace = useGetRecordsFromTraceIdQuery(
    {
      'parent-trace-id': queryParams['parent-trace-id'],
      id: traceId,
      country: queryParams.country,
      vendor: queryParams.vendor,
    },
    queryOptions
  );
  const statusResponse = useGetStatusQuery(
    { ...queryParams, 'parent-trace-id': parentTraceId },
    queryOptions
  );
  const currentStatus = useMemo(
    () => statusResponse.data?.apiResponse.find((status) => status.traceId === traceId),
    [statusResponse.data]
  );

  const overrideAttributes = useMemo(
    () => ({
      entity: currentStatus?.entity.replaceAll(' ', '_').toUpperCase() ?? '',
      version: currentStatus?.version ?? '',
      country: country ?? '',
      operation: currentStatus?.action ?? '',
      vendorId: vendor ?? '',
    }),
    [currentStatus, country, vendor]
  );

  const VSFeedBackLayerDecision = useDecision(
    'feedback_layer_segmented_config',
    { autoUpdate: true },
    { overrideAttributes }
  );

  const VSFeedbackLayerEnabled = useMemo(
    () => VSFeedBackLayerDecision[0]?.variables?.isEntityIntegratedWithSync as boolean,
    [VSFeedBackLayerDecision]
  );

  const { steps } = recordDetails.data
    ? getStepChunks(recordDetails.data.apiResponse, recordDetailsTrace.data, VSFeedbackLayerEnabled)
    : { steps: [] };

  const isFilterClearDisabled = useMemo(
    () =>
      selectedStatus.length === 0 &&
      isEqual(startDate, startDateInitialState) &&
      isEqual(endDate, endDateInitialState),
    [appliedFilters, startDateInitialState, endDateInitialState]
  );

  const onClearFilter = () => {
    if (isFilterClearDisabled) return;
    setSelectedStatus([]);
    setStartDate(startDateInitialState);
    setEndDate(endDateInitialState);
  };

  const refetchAll = () => {
    recordDetails.refetch();
    recordDetailsTrace.refetch();
    statusResponse.refetch();
  };

  const isLoading = useMemo(
    () =>
      statusResponse.isUninitialized ||
      recordDetails.isUninitialized ||
      statusResponse.isLoading ||
      recordDetails.isLoading ||
      recordDetailsTrace.isLoading ||
      recordDetailsTrace.isUninitialized ||
      recordDetails.isFetching,
    [
      statusResponse.isLoading,
      recordDetails.isLoading,
      statusResponse.isUninitialized,
      recordDetails.isUninitialized,
      recordDetailsTrace.isLoading,
      recordDetailsTrace.isUninitialized,
      recordDetails.isFetching,
    ]
  );

  const getLastStep = useMemo(() => steps[steps.length - 1], [steps]);

  const allAvailableSteps = useMemo(() => {
    const allSteps = steps.map((step) => step.name);
    return allSteps;
  }, [steps]);

  useEffect(() => {
    addPageToHistory('MonitoringDetails'); // adds this route to the local history
  }, []);

  useEffect(() => {
    if (recordDetails.data && !isLoading && !chunksToDisplay.length) {
      setChunksToDisplay(getInitialsChunksToDisplay());
    }
  }, [isLoading, recordDetails.data]);

  // Set default status filter to failed if there are failed chunks
  useEffect(() => {
    if (steps.some((step) => step.status === CustomStatus.FAILED)) {
      setSelectedStatus([{ label: 'Failed', value: CustomStatus.FAILED }]);
      setAppliedFilters({
        ...appliedFilters,
        status: [{ label: 'Failed', value: CustomStatus.FAILED }],
      });
    }
  }, [isLoading]);

  useEventPageProps(
    {
      page_name: PageName.BeesSyncMonitoringDataUpdateDetails,
    },
    {
      specific_app: SpecificApp.Monitoring,
      is_core_event: false,
      status: statusResponse.data?.apiResponse[0]?.status,
      entity: statusResponse.data?.apiResponse[0]?.entity,
      action: statusResponse.data?.apiResponse[0]?.action,
      version: statusResponse.data?.apiResponse[0]?.version,
      all_available_ingestion_steps: allAvailableSteps,
      parent_trace_id: statusResponse.data?.apiResponse[0]?.parentTraceId,
    }
  );

  const filterPanelTabs: ITabProps[] = [
    {
      label: 'Last updated',
      content: (
        <LastUpdatedTab
          startDate={startDate}
          minStartDate={minStartDate}
          setStartDate={setStartDate}
          minStartTime={
            isEqual(minStartTime, maxEndTime) ? subMinutes(minStartTime, 15) : minStartTime
          }
          endDate={endDate}
          maxEndDate={maxEndDate}
          setEndDate={setEndDate}
          maxEndTime={isEqual(minStartTime, maxEndTime) ? addMinutes(maxEndTime, 15) : maxEndTime}
          onChange={setDateFieldsStatus}
        />
      ),
    },
    {
      label: 'Status',
      counter: selectedStatus.length,
      content: (
        <StatusTab
          selectedStatus={selectedStatus}
          onItemSelect={onStatusFilterSelect}
          status={statusChunkDetails}
        />
      ),
    },
  ];

  const onRowClick = (_: { [key: string]: string }, index: number): OnRowReturnType => ({
    onClick: () => {
      const id =
        recordDetails.data?.apiResponse.find((record) => record.traceID === _?.traceId)?.id ??
        recordDetails.data?.apiResponse[index]?.id ??
        recordDetailsTrace?.data?.[0]?.id;

      history.push(`${BASE_URL}/monitoring/chunk-details/${id}`);
    },
  });

  const isApplyDisabled = useMemo(
    () =>
      (!dateFieldsStatus?.isStartValid || !dateFieldsStatus.isEndValid) &&
      selectedStatus.length === 0,
    [dateFieldsStatus, selectedStatus]
  );

  const onClickStep = (step: IStep) => {
    if (step.status === CustomStatus.COMPLETED) return;

    // clear filters
    setSelectedStatus([]);
    setAppliedFilters({ status: [] });

    const chunks = updateChunksToDisplay(step.name);
    setChunksToDisplay(chunks);
  };

  const shouldShowFilterRow = useMemo(
    () => appliedFilters?.status && appliedFilters?.status?.length > 0,
    [appliedFilters]
  );

  const filteredTableData = useMemo(() => {
    let results = [...tableData];
    if (traceIdSearch) {
      results = results.filter((data) =>
        showSimilarIds ? data.traceId.includes(traceIdSearch) : data.traceId === traceIdSearch
      );
    }
    if (!appliedFilters) return results;
    if (appliedFilters.status?.length && appliedFilters.status?.length > 0) {
      results = results.filter((data) => {
        if (data.statusCode === 'COMPLETED') data.statusCode = 'CONCLUDED';
        if (data.statusCode === 'IN_PROGRESS') data.statusCode = 'PROCESSING';
        return appliedFilters.status?.map((status) => status.value).includes(data.statusCode);
      });
    }
    if (isValid(appliedFilters.startDate) && isValid(appliedFilters.endDate)) {
      results = results.filter((data) =>
        isWithinInterval(parseISO(data.updatedAt), {
          start: appliedFilters.startDate || 0,
          end: appliedFilters.endDate || 0,
        })
      );
    }
    if (isValid(appliedFilters.startDate) && !isValid(appliedFilters.endDate)) {
      results = results.filter((data) =>
        isAfter(parseISO(data.updatedAt), appliedFilters.startDate || 0)
      );
    }
    if (!isValid(appliedFilters.startDate) && isValid(appliedFilters.endDate)) {
      results = results.filter((data) =>
        isBefore(parseISO(data.updatedAt), appliedFilters.endDate || 0)
      );
    }
    console.log('results', results);
    return results;
  }, [tableData, appliedFilters, traceIdSearch]);

  return isLoading && chunksToDisplay ? (
    <Box>
      <SkeletonMonitoringDetailsV2 />
    </Box>
  ) : (
    <Box data-testid="monitoring-details-page-v2">
      <HeaderStatus
        header={formatMessage({ id: 'MonitoringDetailsPage.TITLE' })}
        badgeStatus={currentStatus?.status as IndicatorEnum}
        previousPageName="MonitoringView"
      />
      <ChunkUpdateInfoCard
        segmentProps={{ event }}
        parentTraceID={currentStatus?.parentTraceId ?? '-'}
        requestDate={currentStatus?.updatedAt ?? '-'}
        entity={currentStatus?.entity ?? '-'}
        version={currentStatus?.version ?? '-'}
        action={currentStatus?.action ?? '-'}
        requestType={currentStatus?.parentTraceId.toLowerCase().includes('csv') ? 'CSV' : 'API'}
      />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '16px',
        }}
      >
        <Box
          data-testid="chunks-overview"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Heading size="H3">
            {formatMessage({ id: 'MonitoringPage.DATA_CHUNKS_OVERVIEW' })}
          </Heading>
          <Paragraph>
            Last updated at:{' '}
            {recordDetails.fulfilledTimeStamp && isValid(recordDetails.fulfilledTimeStamp)
              ? format(recordDetails.fulfilledTimeStamp, 'yyyy/dd/MM HH:mm:ss')
              : '-'}
          </Paragraph>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <ModalDescriptions
            steps={steps}
            segmentProps={{
              event,
              action: currentStatus?.action,
              status: currentStatus?.status,
              entity: currentStatus?.entity,
              version: currentStatus?.version,
              viewedStepWhenTriggered: currentStep.name,
              viewedStepSituationWhenTriggered: currentStep.status,
            }}
          />
          <Box data-testid="refetch-all" onClick={() => refetchAll()}>
            <Box
              data-testid="segment-button-updated"
              className="button-updated"
              onClick={() => {
                if (!segmentProps) return;
                segmentProps.event(SegmentEvents.ELEMENT_CLICKED, {
                  page_name: PageName.BeesSyncMonitoringDataChunkDetails,
                  element_location_name: 'Data chunks overview',
                  element_location_type: ElementLocationType.Page,
                  element_type: ElementType.Button,
                  element_label: 'Update',
                  element_name: 'UpdateDetails_UpdateButton',
                  specific_app: SpecificApp.Monitoring,
                  status: segmentProps.status,
                  action: currentStatus?.action,
                  entity: currentStatus?.entity,
                  version: currentStatus?.version,
                  viewed_step_when_triggered: currentStep.name,
                  viewed_step_situation_when_triggered: currentStep.status,
                });
              }}
            >
              <Box className="icon-sync">
                <Sync size="medium" />
              </Box>
              <Paragraph weight="medium" size="basis">
                {formatMessage({ id: 'MonitoringPage.UPDATE' })}
              </Paragraph>
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid.Container style={{ paddingTop: '16px', width: '100%', rowGap: '16px' }}>
        <Grid.Item sm={12} lg={2} style={{ padding: 0 }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignContent: 'flex-start',
              width: '100%',
              gap: '12px',
              marginRight: isLargeUp ? '16px' : 0,
            }}
          >
            {Object.entries(steps || {}).map(([key, step]) => (
              <StepCard
                segmentProps={{
                  event,
                  status: currentStatus?.status,
                  action: currentStatus?.action,
                  entity: currentStatus?.entity,
                  version: currentStatus?.version,
                }}
                key={key}
                index={key}
                step={`${step.name}`}
                status={step.status}
                amount={step.amount}
                chunks={step.chunks}
                isLastStep={getLastStep.name === step.name}
                onClick={() => onClickStep(step)}
                style={{ minWidth: isLargeUp ? '100%' : 'unset' }}
              />
            ))}
          </Box>
        </Grid.Item>
        <Grid.Item sm={12} lg={10} style={{ padding: 0 }}>
          <Card
            border="medium"
            elevated="small"
            style={{
              width: '100%',
              padding: '1rem',
              minHeight: '50vh',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Heading size="H3">
                {formatMessage({ id: `DescriptionsUpdateDetails.${currentStepTitle}` })}
              </Heading>

              {currentStep.chunks.FAILED && currentStep.chunks.FAILED.length !== 0 && (
                <Box
                  style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    color: '#BA0B0B',
                  }}
                >
                  <HexaBadge.Counter
                    counterLabel={currentStep.chunks.FAILED.length}
                    className="step-badge"
                  />
                  <Box className="step-status">
                    <Paragraph colortype="error" size="small">
                      {formatMessage({ id: 'MonitoringPage.FAILED' })}
                    </Paragraph>
                  </Box>
                </Box>
              )}
            </Box>

            {currentStep.name &&
              Object.entries(currentStep.chunks).map(([key, value]) => (
                <ProgressCounter
                  key={key}
                  chunkKey={key}
                  value={value}
                  amount={currentStep.amount}
                  stepStatus={currentStep.status as CustomStatus}
                  stepName={currentStep.name}
                />
              ))}

            <Box
              style={{
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '1rem',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            >
              <Box
                className="button-filter"
                onClick={() => {
                  setIsFilterPanelOpen(!isFilterPanelOpen);
                }}
              >
                <Filter size="medium" />
                <Paragraph weight="medium" size="basis">
                  {formatMessage({ id: 'MonitoringPage.FILTER' })}
                </Paragraph>
                <ChevronDown size="medium" />
              </Box>

              <Box>
                <SearchField.Root
                  className="button-search-trace-id"
                  placeholder="Search by trace ID"
                  size="medium"
                  value={traceIdSearch}
                  onChange={(e) => setTraceIdSearch(e.currentTarget.value)}
                  onClear={() => setTraceIdSearch('')}
                />
              </Box>
              <Box
                style={{
                  position: 'absolute',
                  zIndex: 1,
                }}
              >
                <FilterPanel
                  isOpen={isFilterPanelOpen}
                  tabs={filterPanelTabs}
                  onApplyFilter={onApplyFilter}
                  onCancelFilter={onCancelFilter}
                  onClearFilter={onClearFilter}
                  isApplyDisabled={isApplyDisabled}
                  isClearDisabled={isFilterClearDisabled}
                />
              </Box>
            </Box>
            {shouldShowFilterRow && (
              <Box className="filter-active">
                <Paragraph weight="normal" size="basis">
                  {formatMessage({ id: 'MonitoringPage.FILTERED_BY' })}
                </Paragraph>
                {selectedStatus.length === Object.keys(statusChunkDetails).length && (
                  <Box className="button-filter-active">
                    <Paragraph weight="medium" size="small">
                      <Paragraph>{`Status: ${formatMessage({
                        id: 'MonitoringPage.ALL',
                      })}`}</Paragraph>
                    </Paragraph>
                    <X
                      onClick={() => {
                        setSelectedStatus([]);
                        setAppliedFilters({ ...appliedFilters, status: [] });
                      }}
                      className="icon-remove"
                      size="tiny"
                    />
                  </Box>
                )}
                {selectedStatus.length !== Object.keys(statusChunkDetails).length &&
                  selectedStatus.map((status) => (
                    <Box key={status.value} className="button-filter-active">
                      <Paragraph weight="medium" size="small">
                        <Paragraph>Status: {status.label}</Paragraph>
                      </Paragraph>
                      <X
                        onClick={() => {
                          const newStatusSelection = selectedStatus.filter(
                            (selected) => selected.value !== status.value
                          );
                          setSelectedStatus(newStatusSelection);
                          setAppliedFilters({ ...appliedFilters, status: newStatusSelection });
                        }}
                        className="icon-remove"
                        size="tiny"
                      />
                    </Box>
                  ))}
                <Box className="text-clear-all" onClick={() => setAppliedFilters({ status: [] })}>
                  {formatMessage({ id: 'MonitoringPage.CLEAR_ALL' })}
                </Box>
              </Box>
            )}
            {traceIdSearch && (
              <Box className="show-similar-ids-row">
                <Toggle
                  disabled={isLoading}
                  checked={showSimilarIds}
                  onCheckedChange={(value) => setShowSimilarIds(value)}
                />
                <div>{formatMessage({ id: 'MonitoringPage.SHOW_SIMILAR_ID_MATCHES' })}</div>
              </Box>
            )}
            <Table
              onRow={onRowClick}
              columns={tableColumns}
              data={filteredTableData || []}
              emptyMessage={<EmptyStateTable />}
              pagination={{
                pageSize: 50,
              }}
            />
          </Card>
        </Grid.Item>
      </Grid.Container>
    </Box>
  );
}

export default MonitoringDetailsPageV2;
