import { format, isValid } from 'date-fns';
import { formatMessage } from 'i18n/formatters';
import { IMonitoring } from 'interfaces/IMonitoring';
import { IMonitoringRecords } from 'interfaces/IMonitoringResponse';
import { v4 as uuid } from 'uuid';

export const convertToMonitoringFormat = (items: Array<IMonitoringRecords>): Array<IMonitoring> =>
  items.map((item: IMonitoringRecords) => {
    let labelEntity = '';
    let labelAction = '';
    let labelVersion = '';
    let date = new Date(item.createdAt);
    if (!isValid(date)) {
      date = new Date();
    }
    const time = format(date, 'MM/dd/yyyy HH:mm:ss');
    if (item.entity) {
      const name = item.entity?.name;
      const substring: string = name?.slice(1);
      const defaultMessage = `${name?.charAt(0).toUpperCase()}${substring?.replace(/_/g, ' ')}`;
      labelEntity = formatMessage({
        id: `Entities.${name?.toUpperCase()}`,
        defaultMessage,
      });
      labelAction = item.entity?.operation;
      labelVersion = item.entity?.version;
    }
    return {
      id: uuid(),
      traceId: item.traceID,
      time,
      parentTraceId: item.parentTraceID,
      entity: labelEntity,
      status: item.status?.name,
      action: labelAction,
      version: labelVersion,
      statusMessage: item.status?.message,
      updatedAt: item.updatedAt,
    };
  });
