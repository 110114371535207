import React, { createContext, useEffect, useState } from 'react'
import { RouterProps, useLocation } from 'react-router-dom'
import { RouteHistory } from './RouterHistoryProvider.types'

const defaultValues: RouteHistory = {
  current: '/',
  previous: null
}

export const RouterContext = createContext(defaultValues)

export const RouterHistoryProvider = ({ children }: RouterProps): React.ReactElement => {
  const location = useLocation()
  const url = window.location.href
  const [route, setRoute] = useState<RouteHistory>({
    current: url,
    previous: defaultValues.previous
  })

  useEffect(() => {
    if (url === route.current) return
    setRoute({ current: url, previous: route.current })
  }, [location])

  return <RouterContext.Provider value={route}>{children}</RouterContext.Provider>
}

export default RouterHistoryProvider
