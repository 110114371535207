import { Checkbox } from '@hexa-ui/components';
import { Box } from '@material-ui/core';
import { SelectItem } from 'pages/Monitoring/components/v2/components/Dropdown/Dropdown';
import './StatusTab.css';

type StatusTabProps = {
  status: SelectItem[];
  selectedStatus: SelectItem[];
  onItemSelect: (item: SelectItem) => void;
};

function StatusTab({ status, selectedStatus, onItemSelect }: Readonly<StatusTabProps>) {
  return (
    <Box className="statusTab">
      {status.map((item, i) => (
        <Checkbox
          className="checkbox"
          id={item.value}
          key={item.value}
          label={item.label}
          checked={!!selectedStatus.find((selectedItem) => selectedItem.value === item.value)}
          onClick={() => onItemSelect(item)}
        />
      ))}
    </Box>
  );
}

export default StatusTab;
