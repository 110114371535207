import { Button, Card } from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import { Box, Typography } from '@material-ui/core';
import { formatMessage } from 'i18n/formatters';
import React, { ReactNode } from 'react';
import './FilterPanel.css';

export interface ITabProps {
  label: string;
  content: ReactNode;
  counter?: number;
}

interface ITabButtonProps {
  label: string;
  activeTab: number;
  setActiveTab: (tab: number) => void;
  index: number;
  counter?: number;
}

interface ITabPanelProps {
  value: number;
  index: number;
  children: ReactNode;
}

interface IFilterPanelProps {
  isOpen: boolean;
  tabs: Array<ITabProps>;
  onApplyFilter: () => void;
  onCancelFilter: () => void;
  onClearFilter: () => void;
  isApplyDisabled?: boolean;
  isClearDisabled?: boolean;
}

function Tab({ activeTab, setActiveTab, index, label, counter }: Readonly<ITabButtonProps>) {
  return (
    <Box
      className={`tab-button ${activeTab === index ? 'tab-button-active' : ''}`}
      onClick={() => setActiveTab(index)}
    >
      <Typography>{label}</Typography>
      {Number(counter) > 0 && <Typography>{counter}</Typography>}
    </Box>
  );
}

function TabPanel(props: Readonly<ITabPanelProps>): JSX.Element {
  const { children, value, index } = props;
  return (
    <div
      hidden={value.toString() !== index?.toString()}
      role="tabpanel"
      id={`vertical-tabpanel-${index?.toString()}`}
      aria-labelledby={`vertical-tab-${index?.toString()}`}
      style={{ flex: 1 }}
    >
      {children}
    </div>
  );
}

function FilterPanel({
  isOpen,
  tabs,
  onApplyFilter,
  onCancelFilter,
  onClearFilter,
  isApplyDisabled,
  isClearDisabled,
}: Readonly<IFilterPanelProps>): JSX.Element | null {
  const [activeTab, setActiveTab] = React.useState(0);

  if (!isOpen) {
    return null;
  }

  return (
    <Card
      data-testid="filter-panel"
      elevated="medium"
      border="medium"
      disabled={!isOpen}
      className="card-filter"
    >
      <Box className="tabs">
        <Box className="tab-list">
          {tabs.map((tab, i) => (
            <Tab
              key={tab.label}
              label={tab.label}
              activeTab={activeTab}
              index={i}
              setActiveTab={setActiveTab}
              counter={tab.counter}
            />
          ))}
        </Box>
        <Box className="tab-content">
          {tabs.map((tab, index) => (
            <TabPanel key={tab.label} value={activeTab} index={index}>
              {tab.content}
            </TabPanel>
          ))}
        </Box>
      </Box>
      <Box className="wrapper-footer">
        <Box
          className={`button-clear-all-active ${
            isClearDisabled ? 'button-clear-all-disabled' : ''
          }`}
          onClick={onClearFilter}
        >
          <Box className="icon-clear">
            <X size="large" />
          </Box>
          <Box>{formatMessage({ id: 'MonitoringPage.CLEAR_ALL' })}</Box>
        </Box>
        <Box className="wrapper-cancel-apply">
          <Box className="cancel" onClick={onCancelFilter}>
            {formatMessage({ id: 'MonitoringPage.CANCEL' })}
          </Box>
          <Button onClick={onApplyFilter} disabled={isApplyDisabled}>
            {formatMessage({ id: 'MonitoringPage.APPLY' })}
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

export default FilterPanel;
