import { Dialog, Heading, Paragraph } from '@hexa-ui/components';
import { Box } from '@material-ui/core';
import { ElementLocationType, ElementType, PageName, SpecificApp } from 'analytics';
import { SegmentEvents } from 'consts/segment';
import { formatMessage } from 'i18n/formatters';
import { SegmentProps } from 'interfaces/SegmentProps';
import { IStep } from 'services/monitoring/recordService';
import './ModalDescriptions.css';

interface IModalDescriptionsProps {
  steps: IStep[];
  segmentProps?: SegmentProps;
}

function ModalDescriptions({
  steps,
  segmentProps,
}: Readonly<IModalDescriptionsProps>): JSX.Element {
  const descriptions = [
    {
      title: formatMessage({ id: 'DescriptionsUpdateDetails.DATA_ENTRY' }),
      subtitle: formatMessage({ id: 'DescriptionsUpdateDetails.DATA_ENTRY_SUBTITLE' }),
      step: 'DATA_ENTRY',
    },
    {
      title: formatMessage({ id: 'DescriptionsUpdateDetails.ADHERENCE_VALIDATION' }),
      subtitle: formatMessage({ id: 'DescriptionsUpdateDetails.ADHERENCE_VALIDATION_SUBTITLE' }),
      step: 'ADHERENCE_VALIDATION',
    },
    {
      title: formatMessage({ id: 'DescriptionsUpdateDetails.DATA_DISTRIBUTION' }),
      subtitle: formatMessage({ id: 'DescriptionsUpdateDetails.DATA_DISTRIBUTION_SUBTITLE' }),
      step: 'DATA_DISTRIBUTION',
    },
    {
      title: formatMessage({ id: 'DescriptionsUpdateDetails.CONFIRMATION' }),
      subtitle: formatMessage({ id: 'DescriptionsUpdateDetails.CONFIRMATION_SUBTITLE' }),
      step: 'CONFIRMATION',
    },
  ];

  const getDescriptionForAvailableSteps = () => {
    const descriptionsForAvailableSteps = descriptions.filter((description) =>
      steps.some((step) => step.name === description.step)
    );

    return descriptionsForAvailableSteps;
  };

  const descriptionsForAvailableSteps = getDescriptionForAvailableSteps();

  return (
    <Dialog.Root
      title={<Heading size="H3">{formatMessage({ id: 'MonitoringPage.DESCRIPTIONS' })}</Heading>}
      trigger={
        <Box
          data-testid="segment-box"
          onClick={() => {
            if (!segmentProps) return;
            segmentProps.event(SegmentEvents.ELEMENT_CLICKED, {
              page_name: PageName.BeesSyncMonitoringDataChunkDetails,
              element_location_name: 'Data chunks overview',
              element_location_type: ElementLocationType.Page,
              element_type: ElementType.Button,
              element_label: 'Show descriptions',
              element_name: 'UpdateDetails_DescriptionButton',
              specific_app: SpecificApp.Monitoring,
              status: segmentProps.status,
              version: segmentProps.version,
              action: segmentProps.action,
              entity: segmentProps.entity,
              viewed_step_when_triggered: segmentProps.viewedStepWhenTriggered,
              viewed_step_situation_when_triggered: segmentProps.viewedStepSituationWhenTriggered,
            });
          }}
          className="text-description"
        >
          {formatMessage({ id: 'MonitoringPage.SHOW_DESCRIPTIONS' })}
        </Box>
      }
    >
      <Box className="modal">
        {descriptionsForAvailableSteps.map((description) => (
          <Box key={description.step} className="descriptions">
            <Heading size="H5">{description.title}</Heading>
            <Paragraph size="small">{description.subtitle}</Paragraph>
          </Box>
        ))}
      </Box>
    </Dialog.Root>
  );
}
export default ModalDescriptions;
