import { Card, Paragraph } from '@hexa-ui/components';
import { Copy } from '@hexa-ui/icons';
import { Box } from '@material-ui/core';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { ElementLocationType, ElementType, PageName, SpecificApp } from 'analytics';
import { SegmentEvents } from 'consts/segment';
import { format, parseISO } from 'date-fns';
import { formatMessage } from 'i18n/formatters';
import { SegmentProps } from 'interfaces/SegmentProps';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { colors } from 'themes/palette';

type ChunkUpdateInfoCardProps = {
  traceID?: string;
  parentTraceID?: string;
  requestDate?: string;
  sentBy?: string;
  sourceSystem?: string;
  entity?: string;
  action?: string;
  version?: string;
  requestType?: string;
  step?: string;
  segmentProps?: SegmentProps;
  isDataChunkDetails?: boolean;
};

type copyButtonProps = {
  text: string;
  onCopy: () => void;
};

function CopyButton({ text, onCopy }: Readonly<copyButtonProps>) {
  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <Copy
        size="medium"
        style={{
          color: '#006EDB',
          display: 'flex',
          alignSelf: 'center',
          marginLeft: '10px',
          cursor: 'pointer',
        }}
      />
    </CopyToClipboard>
  );
}

function ChunkUpdateInfoCard({
  traceID,
  sourceSystem,
  entity,
  action,
  version,
  parentTraceID,
  requestDate,
  sentBy,
  requestType,
  step,
  segmentProps,
  isDataChunkDetails,
}: Readonly<ChunkUpdateInfoCardProps>): JSX.Element {
  const toastService = useToast();

  const openCopyToast = () => {
    toastService.notify({
      message: formatMessage({ id: 'MonitoringPage.COPIED' }),
      type: TypeToast.INFO,
    });
  };

  return (
    <Card data-testid="cardInfo" border="medium" elevated="small" style={{ padding: '24px' }}>
      <Box>
        {traceID && (
          <>
            <span className="text-primary">{formatMessage({ id: 'MonitoringPage.TRACE_ID' })}</span>
            <Box className="row">
              <Paragraph
                size="small"
                css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
              >
                {traceID}
              </Paragraph>
              <CopyButton text={traceID ?? ''} onCopy={openCopyToast} />
            </Box>
          </>
        )}
        {parentTraceID && (
          <>
            <span className="text-primary">
              {formatMessage({ id: 'MonitoringPage.PARENT_TRACE_ID' })}
            </span>
            <Box
              className="row"
              data-testid="segment-wrapper"
              onClick={() => {
                if (!segmentProps) return;
                segmentProps.event(SegmentEvents.ELEMENT_CLICKED, {
                  page_name: PageName.BeesSyncMonitoringDataChunkDetails,
                  element_location_name: 'Data chunk details header',
                  element_location_type: ElementLocationType.Null,
                  element_type: ElementType.Button,
                  element_label: 'Copy',
                  element_name: 'ChunkDetailsHeaderCard_CopyButton',
                  content: isDataChunkDetails ? traceID : parentTraceID,
                  specific_app: SpecificApp.Monitoring,
                  status: segmentProps.status,
                  data_chunk_trace_id: traceID,
                  data_chunk_step: step,
                  data_chunk_source_system: sourceSystem,
                  version,
                  entity,
                  action,
                  data_chunk_error_message: isDataChunkDetails ? segmentProps.errorMessage : null,
                  data_chunk_payload_present: isDataChunkDetails ? !!segmentProps.payload : null,
                });
              }}
            >
              <Paragraph
                size="small"
                css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
              >
                {parentTraceID}
              </Paragraph>
              <CopyButton text={parentTraceID ?? ''} onCopy={openCopyToast} />
            </Box>
          </>
        )}
        {sourceSystem && (
          <Box className="row">
            <Box className="row" style={{ gap: '24px' }}>
              <Box className="row" style={{ gap: '8px' }}>
                <span className="text-primary">
                  {`${formatMessage({ id: 'MonitoringPage.SOURCE_SYSTEM' })}:`}
                </span>
                <Paragraph
                  size="small"
                  css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
                >
                  {sourceSystem}
                </Paragraph>
              </Box>
            </Box>
          </Box>
        )}
        <Box className="row">
          <Box className="row" style={{ gap: '24px' }}>
            {requestDate && (
              <Box className="row" style={{ gap: '8px' }}>
                <span className="text-primary">
                  {`${formatMessage({ id: 'MonitoringPage.CREATION_DATE' })}:`}
                </span>
                <Paragraph
                  size="small"
                  css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
                >
                  {requestDate && requestDate !== '-'
                    ? format(parseISO(requestDate), 'MMM dd, yyyy - HH:mm:ss')
                    : requestDate}
                </Paragraph>
              </Box>
            )}

            {requestType && (
              <Box className="row" style={{ gap: '8px' }}>
                <span className="text-primary">
                  {`${formatMessage({ id: 'MonitoringPage.REQUEST_TYPE' })}:`}
                </span>
                <Paragraph
                  size="small"
                  css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
                >
                  {requestType}
                </Paragraph>
              </Box>
            )}

            {sentBy && (
              <Box className="row" style={{ gap: '8px' }}>
                <span className="text-primary">
                  {`${formatMessage({ id: 'MonitoringPage.SEND_BY' })}:`}
                </span>
                <Paragraph
                  size="small"
                  css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
                >
                  {sentBy}
                </Paragraph>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="row">
          <Box className="row" style={{ gap: '24px' }}>
            <Box className="row" style={{ gap: '8px' }}>
              <span className="text-primary">
                {`${formatMessage({ id: 'MonitoringPage.ENTITY' })}:`}
              </span>
              <Paragraph
                size="small"
                css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
              >
                {entity}
              </Paragraph>
            </Box>
            <Box className="row" style={{ gap: '8px' }}>
              <span className="text-primary">
                {`${formatMessage({ id: 'MonitoringPage.ACTION' })}:`}
              </span>
              <Paragraph
                size="small"
                css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
              >
                {action}
              </Paragraph>
            </Box>
            <Box className="row" style={{ gap: '8px' }}>
              <span className="text-primary">
                {`${formatMessage({ id: 'MonitoringPage.VERSION' })}:`}
              </span>
              <Paragraph
                size="small"
                css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
              >
                {version}
              </Paragraph>
            </Box>

            {step && (
              <Box className="row" style={{ gap: '8px' }}>
                <span className="text-primary">
                  {`${formatMessage({ id: 'MonitoringPage.STEP' })}:`}
                </span>
                <Paragraph
                  size="small"
                  css={{ color: colors.graySecondary, opacity: '56%', lineHeight: '20px' }}
                >
                  {step !== '-' ? formatMessage({ id: `DescriptionsUpdateDetails.${step}` }) : '-'}
                </Paragraph>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
export default ChunkUpdateInfoCard;
