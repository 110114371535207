import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type MonitoringSlice = {
  retentionDays: number;
  newTabEnabled: boolean;
};

const initialState: MonitoringSlice = {
  retentionDays: 10,
  newTabEnabled: true,
};

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    setRetentionDays: (state, { payload }: PayloadAction<number>) => {
      state.retentionDays = payload;
    },
    setNewTabEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.newTabEnabled = payload;
    },
  },
});

export default monitoringSlice.reducer;
export const { setRetentionDays, setNewTabEnabled } = monitoringSlice.actions;
