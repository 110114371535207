import { SelectItem } from '../components/Dropdown/Dropdown';

export const version: SelectItem[] = [
  { value: 'V1', label: 'V1' },
  { value: 'V2', label: 'V2' },
  { value: 'V3', label: 'V3' },
  { value: 'V4', label: 'V4' },
];

export const action: SelectItem[] = [
  { value: 'POST', label: 'POST' },
  { value: 'PUT', label: 'PUT' },
  { value: 'PATCH', label: 'PATCH' },
  { value: 'DELETE', label: 'DELETE' },
  { value: 'GET', label: 'GET' },
];

export const status: SelectItem[] = [
  { value: 'CONCLUDED', label: 'Completed' },
  { value: 'PROCESSING', label: 'In Progress' },
  { value: 'FAILED', label: 'Failed' },
  { value: 'CONCLUDED_WITH_FAILURES', label: 'Completed with failures' },
];

export const statusChunkDetails: SelectItem[] = [
  { value: 'CONCLUDED', label: 'Completed' },
  { value: 'PROCESSING', label: 'In Progress' },
  { value: 'FAILED', label: 'Failed' },
];
