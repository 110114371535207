import { Store } from '@Src/shared/utils/storeUtils/class/Store'

export enum PageLabel {
  BeesContentHomepage = 'BEES Content Homepage',
  BeesCustomerContentList = 'BEES Customer Content List',
  PartnerStorefront = 'Partner Storefront',
  BeesPartnerStorefrontContentList = 'BEES Partner Storefront Content List',
  MiNegocioContentList = 'Mi Negocio content list',
  CreateLaunch = 'Create Launch',
  EditLaunch = 'Edit Launch',
  Monitoring = 'Monitoring'
}

export enum PageName {
  BeesContentHomepage = 'BEES Content Homepage',
  AbiContentList = 'ABI Content List',
  PartnerStorefront = 'Partner Storefront',
  PartnerContentList = 'Partner Content List',
  PartnerCreateBanner = 'Partner Create Banner',
  PartnerEditBanner = 'Partner Edit Banner',
  LearningHubContentList = 'Learning Hub Content List',
  AbiCreateBanner = 'ABI Create Banner',
  AbiEditBanner = 'ABI Edit Banner',
  WebViewLaunch = 'Web View Launch',
  EditWebView = 'Edit Web View',
  DeepLinkMonitoring = 'Deep Link Monitoring'
}

export enum ElementLocationName {
  BeesContentHomepage = 'BEES Content Homepage',
  PartnerCreateBanner = 'Partner Create Banner',
  PartnerEditBanner = 'Partner Edit Banner',
  AbiCreateBanner = 'ABI Create Banner',
  AbiEditBanner = 'ABI Edit Banner',
  WebViewLaunch = 'Web View Launch',
  EditWebView = 'Edit Web View'
}

export enum UserPermissionGroup {
  AdminPortalCMSContentManager = 'AdminPortalCMS.Content Manager'
}

export enum StoreType {
  ABI = 'ABI',
  PARTNER = 'PARTNER'
}

export enum ElementType {
  CARD = 'CARD',
  BUTTON = 'BUTTON'
}

export enum ElementLocationType {
  PAGE = 'PAGE'
}

export enum ElementLabel {
  GENERATE_DEEP_LINK = 'GENERATE_DEEP_LINK',
  SUBMIT = 'SUBMIT',
  CREATE_LAUNCH = 'CREATE_LAUNCH',
  UPDATE_LAUNCH = 'UPDATE_LAUNCH'
}

export enum ElementName {
  CARD_DEEP_LINK = 'CARD_DEEP_LINK',
  CREATE_LAUNCH = 'CREATE_LAUNCH',
  UPDATE_LAUNCH = 'UPDATE_LAUNCH'
}

export enum ViewLevel {
  COUNTRY = 'COUNTRY',
  STORE = 'STORE'
}

export type TrackPageViewedProps = {
  isCoreEvent: boolean
  pageLabel: PageLabel
  pageName: PageName
  contentType?: string
  manufacturerCountry?: string
  manufacturerDisplayName?: string
  manufacturerId?: string
  manufacturerName?: string
  manufacturerServiceModel?: string
  manufacturerTierName?: string
  vendorId?: string
  vendorCountry?: string
  vendorDisplayName?: string
  vendorServiceModel?: string
  vendorTierName?: string
  viewLevel: ViewLevel
}

export type TrackElementClickedProps = TrackPageViewedProps & {
  countBannersCarousel?: number
  countSegmentedBanners?: number
  elementLocationType: ElementLocationType
  elementLocationName: ElementLocationName
  elementLabel: ElementLabel
  elementType: ElementType
  elementName: ElementName
}

type SegmentProperties = {
  store: Store | null
  storeId?: string
  userCountry?: string
  referrer: string | null
  userEmail?: string | null
  zone?: string
}

export type BuildSegmentElementClickedProperties = TrackElementClickedProps & SegmentProperties

export type BuildSegmentPageViewedProperties = TrackPageViewedProps & SegmentProperties
