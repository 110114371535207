import { ChevronDown, Info } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import {
  Content,
  Portal,
  Root,
  ScrollDownButton,
  ScrollUpButton,
  Trigger,
  Viewport,
} from '@radix-ui/react-select';

export const StyledPopover = styled(Portal, {
  zIndex: 5000,
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc(min(var(--radix-select-content-available-height, 60vh), 60vh) - 56px)',
  minWidth: 'var(--radix-popper-anchor-width)',
  overflow: 'auto',
  boxShadow: '$4',
  borderRadius: '$4',
  color: 'hsl(204, 10%, 10%)',
  backgroundColor: 'white',
});

export const StyledSelect = styled(Trigger, {
  width: '100%',
  border: '1px solid $interfaceSurfaceSecondary',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '$neutral0',
  fontFamily: '$paragraph',
  fontWeight: '$medium',
  marginTop: '$1',
  color: '$interfaceLabelPrimary',
  padding: '$4',
  '&:focus': {
    outlineWidth: '1px !important',
    outlineStyle: 'solid !important',
  },
  '&:hover': { border: '1px solid $interfaceLabelPrimary' },
  variants: {
    size: {
      large: {
        height: 48,
        fontSize: '$3',
        lineHeight: '$6',
      },
      medium: {
        height: 40,
        fontSize: '$3',
        lineHeight: '$6',
      },
      small: {
        height: 32,
        fontSize: '$2',
        lineHeight: '$6',
      },
    },
    shape: {
      pill: {
        borderRadius: '9999px',
      },
      square: {
        borderRadius: '$2',
      },
    },
    error: {
      true: {
        border: '1px solid $semanticErrorBasis',
        '&:hover': { border: '1px solid $semanticErrorBasis ' },
      },
    },
  },
  '&:disabled': {
    color: '$interfaceLabelDisabled',
    border: '1px solid $neutral30',
    cursor: 'auto',
  },
});

export const StyledWrapper = styled(Root, {
  display: 'flex',
  flexDirection: 'column',
});

export const StyledChevronDown = styled(ChevronDown, {
  marginLeft: '$1',
});

export const StyledLabel = styled('label', {
  fontFamily: '$paragraph',
  fontSize: '$2',
  fontWeight: '$semibold',
  lineHeight: '$3',
  marginBottom: '$1',
  color: '$interfaceLabelPrimary',
  variants: {
    disabled: {
      true: {
        color: '$interfaceLabelDisabled',
      },
    },
    error: {
      true: {
        color: '$semanticErrorText',
      },
    },
  },
});

export const StyledErrorMessage = styled('div', {
  color: '$semanticErrorText',
  fontSize: '$1',
  lineHeight: '$4',
  fontWeight: '$normal',
  fontFamily: '$paragraph',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '$1',
});

export const StyledHint = styled('div', {
  fontFamily: '$paragraph',
  fontSize: '$1',
  fontWeight: '$normal',
  lineHeight: '$4',
  color: '$interfaceLabelSecondary',
  paddingBottom: '$1',
});

export const StyledInfoIcon = styled(Info, {
  marginRight: '$1',
});

const ArrowContainerCss = {
  display: 'flex !important',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#fff',
  width: '100%',
  height: 25,
  '&>svg': {
    fill: '$interfaceLabelPrimary',
  },
};

export const StyledArrowUpContainer = styled(ScrollUpButton, {
  borderRadius: '$4 $4 0 0',
  ...ArrowContainerCss,
});

export const StyledArrowDownContainer = styled(ScrollDownButton, {
  borderRadius: '0 0 $4 $4',
  ...ArrowContainerCss,
});

export const StyledScrollContainer = styled(Viewport, {
  overflowY: 'auto',
  marginTop: '1rem',
  marginBottom: '1rem',
  /* width */
  '&::-webkit-scrollbar': {
    display: 'flex !important',
    width: '8px',
    borderRadius: '$4',
    scrollSnapType: 'none',
  },

  /* Track */
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    borderRadius: '$4',
  },

  /* Handle */
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '8px',
  },

  /* Handle on hover */
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});

export const StyledUl = styled('ul', {
  margin: 0,
  padding: 0,
  listStyle: 'none',
});

export const StyledIconContainer = styled('div', {
  display: 'flex',
  marginRight: '$1',
});

export const StyledValueContainer = styled('span', {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
  textAlign: 'left',
});

export const StyledContent = styled(Content, {
  marginTop: '$1',
});
