import { ItemText } from '@radix-ui/react-select';

export interface ItemLabelProps {
  children: string;
}

const ItemLabel = ({ children, ...restProps }: ItemLabelProps): JSX.Element => (
  <ItemText {...restProps}>{children}</ItemText>
);

export default ItemLabel;
